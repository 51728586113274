<div class="row no-margin top-space">
  <div class="main-content">
      <div class="welcome-section">
        <div class="row">
          <div class="col-sm-8">
            <div class="return">
              <i class='nav-btn fa fa-chevron-left'></i>
              <a href="javascripi:void(0)" [routerLink]="'/dashboard'">Return to dashboard</a>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="text">
              Welcome to CX Review.
              <!-- /*You are viewing date for*/ -->
              <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
              [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
              [owlDateTime]="daterange">
              <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
            </div>
          </div>
          <div class="col-sm-4 right-part">
          </div>
        </div>
      </div>

      <div class="calls-container">
        <div class="row">
          <div class="col-sm-12">
            <div class="top-block">
              <div class="row">
                <div class="col-sm-1">
                  <div class="box good">
                    <!-- <img src="../../../assets/images/Group 299.svg" alt=""> -->
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="number qcsScore" [ngClass]="{'red': (totalScore / calls.length) * 100  < 25, 
                        'orange': (totalScore / calls.length) * 100  > 25 && (totalScore / calls.length) * 100  < 37,'green' : (totalScore / calls.length) * 100  > 37}">
                          <span>{{(totalScore / calls.length) * 100 | number : '1.2-2'}}</span>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <span class="top-text" style="font-size: 8px;">QCS SCORE</span>
                      </div>
                     
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="box bad">
                    <div class="row">
                      <div class="col-sm-12">
                        <span class="top-text">FAILED CALLS</span>
                      </div>
                      <div class="col-sm-4">
                        <div class="number">
                          <span>{{faildCalls}}</span>
                        </div>
                      </div>
                      <div class="col-sm-8 d-none">
                        <div class="right-text">
                          <span>+ 10% above av.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="box average">
                    <div class="row">
                      <div class="col-sm-12">
                        <span class="top-text">WARNING CALLS</span>
                      </div>
                      <div class="col-sm-4">
                        <div class="number second">
                          <span>{{worningCalls}}</span>
                        </div>
                      </div>
                      <div class="col-sm-8 d-none">
                        <div class="right-text">
                          <span>+ 10% above av.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="box no-border">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-8">
                            <span class="top-text">TOTAL NON-COMPLIANT CALLS</span>
                          </div>
                          <div class="col-sm-4">
                            <div class="top-dropdown" style="text-align: right;">
                              <div class="dropdown">
                                <select [(ngModel)]="topChangeFilterModel"  (change)="callSectionTopChange($event.target.value)" class="form-select btn-top" aria-label="Default select example" >
                                  <!-- <option value="" class="dropdown-item" >Opening</option> -->
                                  <option [value]="item.name" class="dropdown-item" *ngFor="let item of callSection">{{item.name}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-sm-1" *ngFor="let item of nonCompliant;let i = index">
                        <div class="block" >
                          <div class="first-no">
                            <span>{{item.calls_failed}}</span>
                          </div>
                          <div class="second-text">
                            <span>{{item.checkname}}</span>
                          </div>
                        </div>  
                      </div>
                      <div class="col-sm-12" *ngIf="nonCompliant.length <= 0" >
                        <div class="block" >
                          <div class="first-no">
                            <span>No Checks Found</span>
                          </div>
                        </div> 
                      </div>
                      <!-- <div class="col-sm-2">
                        <div class="block">
                          <div class="first-no">
                            <span>57</span>
                          </div>
                          <div class="second-text">
                            <span>PI</span>
                          </div>
                        </div>  
                      </div>
                      <div class="col-sm-2">
                        <div class="block">
                          <div class="first-no">
                            <span>66</span>
                          </div>
                          <div class="second-text">
                            <span>MIRANDA</span>
                          </div>
                        </div>  
                      </div>
                      <div class="col-sm-2">
                        <div class="block">
                          <div class="first-no">
                            <span>59</span>
                          </div>
                          <div class="second-text">
                            <span>DISCLOSURE</span>
                          </div>
                        </div>  
                      </div>
                      <div class="col-sm-2">
                        <div class="block">
                          <div class="first-no">
                            <span>104</span>
                          </div>
                          <div class="second-text">
                            <span>Right_No</span>
                          </div>
                        </div>  
                      </div>
                      <div class="col-sm-2">
                        <div class="block">
                          <div class="first-no">
                            <span>150</span>
                          </div>
                          <div class="second-text">
                            <span>RPC</span>
                          </div>
                        </div>  
                      </div> -->
                      <!-- <div class="col-sm-2">
                        <div class="block">
                          <div class="first-no">
                            <span>0</span>
                          </div>
                          <div class="second-text">
                            <span>DISCLOSURE</span>
                          </div>
                        </div>  
                      </div> -->
                    </div>                  
                  </div>
                </div>
                <div class="col-sm-2 d-none">
                  <div class="box no-border">
                    <div class="last-bax">
                      <div class="top-text" *ngIf="total_agents">
                        <span><i class="fa fa-user" aria-hidden="true"></i>{{total_agents.size || '0'}} agents
                        </span>
                      </div>
                      <div class="top-text text-bottom">
                        <span><i class="fa fa-phone" aria-hidden="true"></i>{{calls.length}} calls taken 
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Left side start -->
          <div class="col-sm-12">
              <div>
                  <div class="row">
                      <div class="col-sm-8">
                          <div class="title">
                              ALL CALLS
                          </div>
                      </div>
                      <div class="col-sm-4 text-right">
                          <div class="total-calls" *ngIf="calls">
                              Displaying {{calls.length}} Calls
                          </div>
                      </div>
                  </div>

                  <!-- <div class="date-picker">
                      <div class="icon" (click)="toggleDate()">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18 20">
                              <path id="ic_event_busy_24px"
                                  d="M19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                                  transform="translate(-3 -1)" fill="#909090" />
                          </svg>
                      </div>
                      <div class="label">
                          <input type="text" disabled [(ngModel)]="selectedPeriodDisplay">
                      </div>
                      <div id="dateOptions" class="options">
                          <div (click)="setDate('today','24 Hours')" class="option">
                              24 Hours
                          </div>
                          <div (click)="setDate('last7days','7 Days')" class="option">
                              7 Days
                          </div>
                          <div (click)="setDate('last30days','1 Month')" class="option">
                              1 Month
                          </div>
                          <div (click)="setDate('last3months','3 Month')" class="option">
                              3 Month
                          </div>
                          <div (click)="setDate('last6months','6 Month')" class="option no-border-bottom">
                              6 Month
                          </div>
                      </div>
                  </div> -->

                  <div style="display: none;" *ngIf="cloudText == undefined" class="date-picker">
                      <span style="margin-right: 10px"> Select Date Range</span>
                      <input placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
                          [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
                          [owlDateTime]="daterange">
                      <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
                  </div>
                  <div style="display: none !important;" class="campaign-filter">
                      <!-- [ngStyle]="{'display': cloudText == undefined ? 'inline-block' : 'none'}" -->
                      <div class="icon">
                          <i class="fa fa-filter"></i>
                      </div>
                      <select class="filter" (change)="filterCalls(calls);">
                          <option value="" selected>Filter by Campaign</option>
                          <option value="{{campaign}}" *ngFor="let campaign of campaigns">{{campaign}}</option>
                      </select>

                  </div>
              </div>

              <div class="main-content" *ngIf="isCallLoading">
                  <cxr-loading></cxr-loading>
              </div>

              <div class="table-container" *ngIf="!isCallLoading" >
                <div *ngIf="calls.length > 0" >
                  <table>
                    <tr>
                      <th (click)="sortTable('id')">Call ID <i class="fa fa-sort"></i></th>
                        <!-- <th>LOGS</th> -->
                      <th>Agent Name </th>
                      <th (click)="sortTable('duration')">Duration <i class="fa fa-sort"></i></th>
                      <th (click)="sortTable('quality_passed')">Quality <i class="fa fa-sort"></i></th>
                      <th>Sentiment </th>
                      <!-- <th>verification <i class="fa fa-sort"></i></th> -->
                      <!-- <th>Time</th> -->
                      <!-- <th>Pass/Fail</th> -->
                      <th *ngIf="cloudText!=undefined" >Count</th>
                      <th (click)="sortTable('agent_vi')">Agent V.I <i class="fa fa-sort"></i></th>
                      <th (click)="sortTable('customer_vi')">Customer V.I <i class="fa fa-sort"></i></th>
                      <th>Actions</th>
                    </tr>
                    <tr *ngFor="let call of filterCalls(calls);" class="bg-tr">
                        <td>{{ call.id }}</td>
                        <td>{{ call.first_name }} {{ call.last_name }}</td>
                        <td>{{ commonService.getDuration(call.duration) }}</td>
                        <!-- <td>11023398382334</td> -->
                        <td [ngClass]="{'red': call.quality_score < 0.25,
                            'orange': call.quality_score > 0.25 && call.quality_score < 0.37,'green' : call.quality_score > 0.37}">{{( call.quality_passed / call.total_quality )* 100 | number : '1.2-2'}}%</td>
                        <td>
                            <!-- {{call.sentiment_score  | number:'1.1-1'}} -->
                            <span *ngIf="call.sentiment_score >= -0.2 && call.sentiment_score <= 0.2">Neutral</span>
                            <span *ngIf="call.sentiment_score > 0.2 ">Positive</span>
                            <span *ngIf="call.sentiment_score < -0.2">Negative</span>
                        </td>
                        <!-- <td class="" [ngClass]="{'green' : call.verified, 'red' : !call.verified}" >
                            {{call.verified ? 'Verified': 'Not Verified'}}
                        </td> -->
                        <!-- <td>{{ call.timestamp | date:'medium' }}</td> -->
                        <td *ngIf="cloudText!=undefined">
                            {{ call.count }}
                        </td>
                        <td class="" [ngClass]="{'green': call.agent_vi > 0.37,
                          'orange': call.agent_vi >= 0.25 && call.agent_vi <= 0.37, 
                          'red': call.agent_vi < 0.25}" >
                            {{call.agent_vi * 100 | number : '1.2-2'}}%
                        </td>
                        <td class="" [ngClass]="{'green': call.customer_vi > 0.37,
                          'orange': call.customer_vi >= 0.25 && call.customer_vi <= 0.37, 
                          'red': call.customer_vi < 0.25}">
                            {{call.customer_vi * 100 | number : '1.2-2'}}%
                        </td>
                        <td (click)="redirectInternal(call.id)" class="action-button">
                            View
                            <span>
                                <i class="fa fa-angle-down"></i>
                            </span>

                            <div class="actions">
                                <div class="action-name">
                                    Go To Call
                                </div>
                                <div class="action-name">
                                    Go To Campaign
                                </div>
                                <div>
                                    Go To Agent
                                </div>
                            </div>
                        </td>
                        <!-- <td>
                          <div class="check">
                            <label class="container">
                              <input type="checkbox">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </td> -->
                    </tr>
                  </table>
                </div>

                <div class="text-muted text-center" style="margin-top: 40px;" *ngIf="calls.length == 0">No calls found</div>

                <!-- Pagination start -->
                <nav aria-label="Page navigation example" *ngIf="false" >
                  <ul class="pagination justify-content-center custom-style">
                    <li class="page-item"><a class="page-link active" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                  </ul>
                </nav>
                <!-- Pagination end -->
              </div>
          </div>
          <!-- Left side end -->


          <!-- Right side start -->
          <div class="col-sm-3">
            <!-- <div>
                <div class="title">
                    FILTER
                </div>

                <div class="filters">
                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'good-quality'}" (click)="selectedFilter='good-quality';refreshList();" >
                        <img class="selected" src="/assets/images/Selected.svg">
                        <img class="unselected" src="/assets/images/Unselected.svg">
                        <span>Good Quality score</span>
                    </a>

                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'bad-quality'}" (click)="selectedFilter='bad-quality';refreshList();" >
                        <img class="selected" src="/assets/images/Selected.svg">
                        <img class="unselected" src="/assets/images/Unselected.svg">
                        <span>Bad Quality score</span>
                    </a>

                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'vi-customer'}" (click)="selectedFilter='vi-customer';refreshList();">
                        <img class="selected" src="/assets/images/Selected.svg">
                        <img class="unselected" src="/assets/images/Unselected.svg">
                        <span>High Customer Vulnerability</span>
                    </a>

                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'vi-agent'}" (click)="selectedFilter='vi-agent';refreshList();">
                        <img class="selected" src="/assets/images/Selected.svg">
                        <img class="unselected" src="/assets/images/Unselected.svg">
                        <span>High Agent Vulnerability</span>
                    </a>

                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'negative-sentiment'}" (click)="selectedFilter='negative-sentiment';refreshList();" >
                        <img class="selected" src="/assets/images/Selected.svg">
                        <img class="unselected" src="/assets/images/Unselected.svg">
                        <span>Negative sentiment calls</span>
                    </a>

                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'positive-sentiment'}" (click)="selectedFilter='positive-sentiment';refreshList();" >
                        <img class="selected" src="/assets/images/Selected.svg">
                        <img class="unselected" src="/assets/images/Unselected.svg">
                        <span>Positive sentiment calls</span>
                    </a>

                    <a href="javascript:void(0)" class="filter"  [ngClass]="{'active': selectedFilter == 'unverified'}" (click)="selectedFilter='unverified';refreshList();">
                        <img class="selected" src="/assets/images/Selected.svg">
                        <img class="unselected" src="/assets/images/Unselected.svg">
                        <span>Unverified Calls</span>
                    </a>
                </div>
            </div> -->
            <div class="dropdowns d-none">
              <div class="dropdown">
                <select [(ngModel)]="checksFilterModel"  (change)="onChecksChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
                  <option hidden value="" class="dropdown-item" >Complance status</option>
                  <option value="RPC" class="dropdown-item" >RPC</option>
                  <option value="PI" class="dropdown-item">PI</option>
                  <option value="DISCLOSURE" class="dropdown-item">DISCLOSURE</option>
                  <option value="Right_No" class="dropdown-item">Right_No</option>
                  <option value="MIRANDA" class="dropdown-item">MIRANDA</option>
                </select>
              </div>
              <div class="dropdown" >
                <select [(ngModel)]="projectFilterModel"  (change)="onProjectChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
                  <option value="" class="dropdown-item" >Select</option>
                  <option [value]="item.id" class="dropdown-item" *ngFor="let item of projects;">{{item.name}}</option>
                </select>
              </div>
              <div class="dropdown">
                <select [(ngModel)]="campaignsFilterModel"  (change)="onCampaignChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example" >
                  <option value="" class="dropdown-item" >Select</option>
                  <option [value]="item.id" class="dropdown-item" *ngFor="let item of campaing">{{item.name}}</option>
                </select>
              </div>
              <!-- <div class="dropdown">
                <select class="form-select btn btn-secondary" aria-label="Default select example">
                  <option value="1" class="dropdown-item" >Call importance: All</option>
                  <option value="2" class="dropdown-item">Call importance: All</option>
                  <option value="3" class="dropdown-item">Call importance: All</option>
                  <option value="4" class="dropdown-item">Call importance: All</option>
                </select>
              </div> -->
              <!-- <div class="dropdown">
                <select class="form-select btn btn-secondary" aria-label="Default select example">
                  <option value="1" class="dropdown-item" >Call importance: high, medium</option>
                  <option value="2" class="dropdown-item">Call importance: high, medium</option>
                  <option value="3" class="dropdown-item">Call importance: high, medium</option>
                  <option value="4" class="dropdown-item">Call importance: high, medium</option>
                </select>
              </div> -->
              <!-- <div class="dropdown">
                <select class="form-select btn btn-secondary" aria-label="Default select example">
                  <option value="1" class="dropdown-item" >Call importance: All</option>
                  <option value="2" class="dropdown-item">Call importance: All</option>
                  <option value="3" class="dropdown-item">Call importance: All</option>
                  <option value="4" class="dropdown-item">Call importance: All</option>
                </select>
              </div> -->
              <!-- <div class="dropdown">
                <select class="form-select btn btn-secondary" aria-label="Default select example">
                  <option value="1" class="dropdown-item" >Call importance: All</option>
                  <option value="2" class="dropdown-item">Call importance: All</option>
                  <option value="3" class="dropdown-item">Call importance: All</option>
                  <option value="4" class="dropdown-item">Call importance: All</option>
                </select>
              </div> -->
              <!-- <div class="dropdown">
               <button type="button" class="btn btn1">SEND SELECTED CALLS TO QC</button>
              </div> -->
            </div>
          </div>
          <!-- Right side end -->

        </div>
      </div>
  </div>
</div>


