<div class="row no-margin top-space">
    <!-- <div class="main-content" *ngIf="team == null">
        <cxr-loading></cxr-loading>
    </div> -->
    <div class="main-content">

        <div class="welcome-section">
          <div class="row">
            <div class="col-sm-8">
              <div class="text">
                Welcome to CX Review.
                <!-- You are viewing date for -->
                <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
                [(ngModel)]="dateRange" [selectMode]="'range'"
                [owlDateTime]="daterange">
                <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
              </div>
            </div>
            <div class="col-sm-4 right-part">
                <a class="anchor-btn" [routerLink]="'/onboarding/team/'+teamId" >
                    See team Configuration
                </a>
            </div>
          </div>
        </div>

        <div class="row no-margin">
            <div class="list-container">
                <div class="back-link">
                    <div class="icon" (click)="back()">
                        <i class="fa fa-arrow-left"></i>
                    </div>
                    <div class="message">
                        Back To All Teams
                    </div>
                </div>
                <div class="title" *ngIf="leader && leader.name" >
                    {{leader.name}}
                </div>
                <div class="profile-container clickable" routerLink="/agent/{{leader.id}}" *ngIf="leader != null">
                    <div class="profile row no-margin">
                        <div class="image" [title]="leader.user.first_name + ' ' + leader.user.last_name" [ngClass]="'circle-' + leader.id%4" >
                            <span>
                                {{(leader.user.first_name != undefined
                                && leader.user.first_name != null
                                && leader.user.first_name != '')
                                ? leader.user.first_name.charAt(0) : ''}}

                                {{(leader.user.last_name != undefined
                                && leader.user.last_name != null
                                && leader.user.last_name != '')
                                ? leader.user.last_name.charAt(0) : ''}}
                            </span>
                        </div>
                        <div class="details">
                            <div class="name">
                                {{leader.user.first_name}} {{leader.user.last_name}}
                            </div>
                            <div class="role">
                                Team Leader
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="false">
                    <div class="col-12">
                        <div class="overall-message" >OVERALL AGENT PERFORMANCE</div>
                        <!-- OVER TIME(Quality over Time) -->
                        <div class="performance-graph" *ngIf="performance[0].series != undefined && performance[0].series.length > 0" >
                            <!-- [view]="view" -->
                            <ngx-charts-area-chart-stacked
                            [scheme]="colorScheme"
                            [results]="performance"
                            [gradient]="true"
                            [showGridLines]="false"
                            [xAxis]="true"
                            [yAxis]="true"
                            [legend]="false"
                            [showXAxisLabel]="false"
                            [showYAxisLabel]="false"
                            [curve]="curve"
                            >
                            <!-- (select)="onGraphCallsSelect($event)" -->
                          </ngx-charts-area-chart-stacked>
                        </div>
                    </div>
                </div>

                <div class="list" style="margin-top: 40px" >
                    <div class="list-header">
                        <div class="header-name col-md">
                            Agent Id
                        </div>
                        <div class="header-name col-lg">
                            Agent Name
                        </div>
                        <div class="header-name col-lg">
                            Calls taken
                        </div>
                        <div class="header-name col-lg">
                            Vulnerability
                        </div>
                        <div class="header-name col-md">
                            Score
                        </div>
                        <div class="header-name col-md d-none">
                            NPS
                        </div>
                        <div class="header-name col-md d-none">
                            CES
                        </div>
                        <div class="header-name col-md d-none">
                            CSAT
                        </div>
                        <div class="header-name col-md">
                            Actions
                        </div>
                    </div>
                    <div class="list-row" routerLink="/agent/{{agent.agentid}}" *ngFor="let agent of agents">
                        <div class="attribute col-md">
                            {{agent.agentid}}
                        </div>
                        <div class="attribute col-lg">
                            {{agent.user.first_name}} {{agent.user.last_name}}
                        </div>
                        <div class="attribute col-lg">
                            {{agent.calls_taken}}
                        </div>
                        <div class="attribute col-lg">
                            {{agent.vulnerability | number : '1.2-2'}}
                        </div>
                        <div class="attribute col-md red">
                            {{commonService.getRating((agent.calls_pass/agent.calls_taken) *100)}}
                        </div>
                        <div class="attribute col-md d-none">
                            {{agent.nps}}
                        </div>
                        <div class="attribute col-md d-none">
                            {{agent.ces}}
                        </div>
                        <div class="attribute col-md d-none">
                            {{agent.csat}}
                        </div>
                        <div class="attribute col-md link">
                            View
                        </div>
                    </div>
                </div>

                <nav aria-label="Page navigation example" *ngIf="false" >
                  <ul class="pagination justify-content-center custom-style">
                    <li class="page-item"><a class="page-link active" href="javascript:void(0)">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                  </ul>
                </nav>

            </div>
            <div class="right-container">

                <div class="row">
                    <div class="col-sm-10">
                        <div *ngIf="false" class="graph-container">
                            <div class="title">
                                <div style="margin-bottom:40px">
                                    TEAM PERFORMANCE
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="graph">
                                    <!-- [view]="[400,500]" -->
                                    <ngx-charts-bar-vertical [barPadding]="35" [scheme]="colorScheme"
                                        [results]="checks" [xAxis]="true" [yAxis]="true" [legend]="false"
                                        [showXAxisLabel]="false" [showYAxisLabel]="false">
                                    </ngx-charts-bar-vertical>
                                </div>
                                <div class="metrics">
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.calls}}
                                        </div>
                                        <div class="graph-key">
                                            Calls Taken
                                        </div>
                                    </div>
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.nps.toFixed(1)}}
                                        </div>
                                        <div class="graph-key">
                                            NPS
                                        </div>
                                    </div>
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.csat.toFixed(1)}}
                                        </div>
                                        <div class="graph-key">
                                            CSAT
                                        </div>
                                    </div>
                                    <div class="graph-attribute">
                                        <div class="graph-value">
                                            {{overview.ces.toFixed(1)}}
                                        </div>
                                        <div class="graph-key">
                                            CES
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="date-picker">
                                <div class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18 20">
                                        <path id="ic_event_busy_24px"
                                            d="M19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                                            transform="translate(-3 -1)" fill="#909090" />
                                    </svg>
                                </div>
                                <div class="label">
                                    Select display period
                                </div>
                            </div> -->
                        </div>

                        <div class="agent-performance">
                            <div class="title">
                                QUALITY CHECKS
                            </div>

                            <div class="row">
                              <div class="col-sm-6" *ngFor="let quality of checks">
                                <div class="quality-stamp good">
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="label">
                                        LABEL
                                      </div>
                                      <div class="value">
                                        {{quality.name}}
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="label">
                                        SCORE
                                      </div>
                                      <div class="value">
                                        {{quality.value}}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>

                        <div class="agent-performance d-none">
                            <div class="title">
                                AGENT SOCRES
                            </div>

                            <div class="attribute">
                                <div class="key-value-cont good">
                                    <div class="data">
                                        <div class="key">
                                            L0 - {{this.ratings.L0 | number}} Agents
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="attribute">
                                <div class="key-value-cont good">
                                    <div class="data">
                                        <div class="key">
                                            L1 - {{this.ratings.L1 | number}} Agents
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="attribute">
                                <div class="key-value-cont average">
                                    <div class="data">
                                        <div class="key">
                                            L2 - {{this.ratings.L2 | number}} Agents
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="attribute">
                                <div class="key-value-cont bad">
                                    <div class="data">
                                        <div class="key">
                                            L3 - {{this.ratings.L3 | number}} Agents
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="attribute">
                                <div class="key-value-cont bad">
                                    <div class="data">
                                        <div class="key">
                                            L4 - {{this.ratings.L4 | number}} Agents
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="agent-performance d-none">
                            <div class="title" style="margin-bottom: 0px;" >
                                VERIFICATION OVERVIEW
                            </div>

                            <div class="row" style="align-items: center;" >
                                <div class="col-sm-3">
                                    <!-- <div style="position: relative;" > -->
                                        <!-- <div class="" style="position: absolute;" >75%</div> -->
                                        <ngx-charts-pie-chart
                                            [scheme]="colorSchemeDonut" [results]="verificationAgentSeries"
                                            [margins]="[0,0,0,0]" [doughnut]="true" [legendTitle]="'Hi the'"
                                            [labelFormatting]="pieChartLabel.bind(this, verificationAgentSeries)" [animations]="false">
                                        </ngx-charts-pie-chart>
                                    <!-- </div> -->

                                </div>
                                <div class="col-sm-1"></div>
                                <div class="col-sm-8">
                                    <div class="verification-text">
                                        <div class="key">Good Job!</div>
                                        <!-- <div class="value">
                                            Over {{verifiedAgents.toFixed(1)}}% of your Agents have been verified with their voice prints.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="agent-performance d-none">
                            <div class="title" style="margin-bottom: 0px;" >
                                WORD CLOUD
                            </div>

                            <div class="row" >
                                <div class="col-sm-10">
                                    <cxr-word-cloud *ngIf="wordData.length > 0" [data]="wordData" (onClick)="onClickWordCloud($event)" ></cxr-word-cloud>
                                </div>
                            </div>
                        </div>

                        <div class="associated-campaigns">
                            <div class="title">
                                ASSOCIATED CAMPAIGNS
                            </div>
                            <div class="campaign-card" *ngFor="let campaign of campaigns">
                                <div class="name">
                                    {{campaign.name}}
                                </div>
                                <div class="details">
                                    <div class="sub-line">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                viewBox="0 0 12.251 12.251">
                                                <path id="ic_perm_identity_24px"
                                                    d="M10.125,5.455A1.608,1.608,0,1,1,8.517,7.063a1.608,1.608,0,0,1,1.608-1.608m0,6.891c2.274,0,4.671,1.118,4.671,1.608V14.8H5.455v-.842c0-.49,2.4-1.608,4.671-1.608m0-8.346a3.063,3.063,0,1,0,3.063,3.063A3.062,3.062,0,0,0,10.125,4Zm0,6.891C8.081,10.891,4,11.917,4,13.954v2.3H16.251v-2.3C16.251,11.917,12.17,10.891,10.125,10.891Z"
                                                    transform="translate(-4 -4)" fill="#909090" />
                                            </svg>
                                        </div>
                                        <div class="label">
                                            {{campaign.calls[0].agents}} Agents
                                        </div>
                                    </div>
                                    <div class="sub-line">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.635" height="14.635"
                                                viewBox="0 0 14.635 14.635">
                                                <path id="ic_call_24px"
                                                    d="M5.943,9.334A12.317,12.317,0,0,0,11.3,14.692L13.09,12.9a.808.808,0,0,1,.829-.2,9.275,9.275,0,0,0,2.9.463.815.815,0,0,1,.813.813v2.838a.815.815,0,0,1-.813.813A13.821,13.821,0,0,1,3,3.813.815.815,0,0,1,3.813,3H6.659a.815.815,0,0,1,.813.813,9.237,9.237,0,0,0,.463,2.9.816.816,0,0,1-.2.829Z"
                                                    transform="translate(-3 -3)" fill="#909090" />
                                            </svg>
                                        </div>
                                        <div class="label">
                                            {{campaign.calls[0].calls_taken}} Calls Taken
                                        </div>
                                    </div>
                                </div>
                                <div class="view-details">
                                    <div class="link" routerLink="/campaign/{{campaign.id}}">
                                        View Campaign
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>