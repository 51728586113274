<div class="row no-margin top-space">
    <div class="main-content">

        <div class="welcome-section">
          <div class="row">
            <div class="col-sm-8">
              <div class="text">
                Welcome to CX Review.
                <!-- You are viewing date for -->
                <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
                [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
                [owlDateTime]="daterange">
                <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
              </div>
            </div>
            <div class="col-sm-4 right-part">
            </div>
          </div>
        </div>

        <div class="calls-container">

            <div class="row">

                <!-- Left side start -->
                <div class="col-sm-8">
                    <div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="title">
                                    ALL CALLS
                                </div>
                            </div>
                            <div class="col-sm-4 text-right">
                                <div class="total-calls" >
                                    Displaying {{calls.length}} Calls
                                </div>
                            </div>
                        </div>

                        <!-- <div class="date-picker">
                            <div class="icon" (click)="toggleDate()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18 20">
                                    <path id="ic_event_busy_24px"
                                        d="M19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                                        transform="translate(-3 -1)" fill="#909090" />
                                </svg>
                            </div>
                            <div class="label">
                                <input type="text" disabled [(ngModel)]="selectedPeriodDisplay">
                            </div>
                            <div id="dateOptions" class="options">
                                <div (click)="setDate('today','24 Hours')" class="option">
                                    24 Hours
                                </div>
                                <div (click)="setDate('last7days','7 Days')" class="option">
                                    7 Days
                                </div>
                                <div (click)="setDate('last30days','1 Month')" class="option">
                                    1 Month
                                </div>
                                <div (click)="setDate('last3months','3 Month')" class="option">
                                    3 Month
                                </div>
                                <div (click)="setDate('last6months','6 Month')" class="option no-border-bottom">
                                    6 Month
                                </div>
                            </div>
                        </div> -->

                        <div style="display: none;" *ngIf="cloudText == undefined" class="date-picker">
                            <span style="margin-right: 10px"> Select Date Range</span>
                            <input placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
                                [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
                                [owlDateTime]="daterange">
                            <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
                        </div>
                        <div style="display: none !important;" class="campaign-filter">
                            <!-- [ngStyle]="{'display': cloudText == undefined ? 'inline-block' : 'none'}" -->
                            <div class="icon">
                                <i class="fa fa-filter"></i>
                            </div>
                            <select class="filter" (change)="filterCalls(calls);">
                                <option value="" selected>Filter by Campaign</option>
                                <option value="{{campaign}}" *ngFor="let campaign of campaigns">{{campaign}}</option>
                            </select>

                        </div>
                    </div>

                    <div class="main-content" *ngIf="isCallLoading">
                        <cxr-loading></cxr-loading>
                    </div>

                    <div class="table-container" *ngIf="!isCallLoading" >
                        <div class="table" *ngIf="calls.length > 0" >
                            <table>
                                <tr>
                                    <th>Call ID</th>
                                    <!-- <th>LOGS</th> -->
                                    <th>Agent Name</th>
                                    <th>Duration</th>
                                    <th>Quality</th>
                                    <th>Sentiment</th>
                                    <th>verification</th>
                                    <!-- <th>Time</th> -->
                                    <!-- <th>Pass/Fail</th> -->
                                    <th *ngIf="cloudText!=undefined" >Count</th>
                                    <th>Agent V.I</th>
                                    <th>Customer V.I</th>
                                    <th>Actions</th>
                                </tr>
                                <tr *ngFor="let call of filterCalls(calls);">
                                    <td>{{ call.id }}</td>
                                    <td>{{ call.agent_name }}</td>
                                    <td>{{ commonService.getDuration(call.duration) }}</td>
                                    <!-- <td>11023398382334</td> -->
                                    <td [ngClass]="{'red': call.quality_score < call.quality_threshold,
                                        'green': call.quality_score >= call.quality_threshold}">{{ call.quality_score }}/100</td>
                                    <td>
                                        {{call.sentiment_score | number:'1.1-1'}}/100
                                    </td>
                                    <td class="" [ngClass]="{'green' : call.verified, 'red' : !call.verified}" >
                                        {{call.verified ? 'Verified': 'Not Verified'}}
                                    </td>
                                    <!-- <td>{{ call.timestamp | date:'medium' }}</td> -->
                                    <td *ngIf="cloudText!=undefined">
                                        {{ call.count }}
                                    </td>
                                    <td class="" [ngClass]="{'green': call.agent_vi > 85,
                'orange': call.agent_vi >= 65 && call.agent_vi <= 85, 
                'red': call.agent_vi < 65}" >
                                        {{call.agent_vi}}%
                                    </td>
                                    <td class="" [ngClass]="{'green': call.customer_vi > 85,
                'orange': call.customer_vi >= 65 && call.customer_vi <= 85, 
                'red': call.customer_vi < 65}">
                                        {{call.customer_vi}}%
                                    </td>
                                    <td (click)="redirectInternal(call.id)" class="action-button">
                                        View
                                        <span>
                                            <i class="fa fa-angle-down"></i>
                                        </span>

                                        <div class="actions">
                                            <div class="action-name">
                                                Go To Call
                                            </div>
                                            <div class="action-name">
                                                Go To Campaign
                                            </div>
                                            <div>
                                                Go To Agent
                                            </div>
                                        </div>
                                    </td>
                                </tr>


                            </table>
                        </div>

                        <div class="text-muted text-center" style="margin-top: 40px;" *ngIf="calls.length == 0">No calls found</div>

                        <!-- Pagination start -->
                        <nav aria-label="Page navigation example" *ngIf="false" >
                          <ul class="pagination justify-content-center custom-style">
                            <li class="page-item"><a class="page-link active" href="javascript:void(0)">1</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                          </ul>
                        </nav>
                        <!-- Pagination end -->
                    </div>
                </div>
                <!-- Left side end -->


                <!-- Right side start -->
                <div class="col-sm-4">
                    <div>
                        <div class="title">
                            FILTER
                        </div>

                        <div class="filters">
                            <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'good-quality'}" (click)="selectedFilter='good-quality';refreshList();" >
                                <img class="selected" src="/assets/images/Selected.svg">
                                <img class="unselected" src="/assets/images/Unselected.svg">
                                <span>Good Quality score</span>
                            </a>

                            <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'bad-quality'}" (click)="selectedFilter='bad-quality';refreshList();" >
                                <img class="selected" src="/assets/images/Selected.svg">
                                <img class="unselected" src="/assets/images/Unselected.svg">
                                <span>Bad Quality score</span>
                            </a>

                            <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'vi-customer'}" (click)="selectedFilter='vi-customer';refreshList();">
                                <img class="selected" src="/assets/images/Selected.svg">
                                <img class="unselected" src="/assets/images/Unselected.svg">
                                <span>High Customer Vulnerability</span>
                            </a>

                            <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'vi-agent'}" (click)="selectedFilter='vi-agent';refreshList();">
                                <img class="selected" src="/assets/images/Selected.svg">
                                <img class="unselected" src="/assets/images/Unselected.svg">
                                <span>High Agent Vulnerability</span>
                            </a>

                            <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'negative-sentiment'}" (click)="selectedFilter='negative-sentiment';refreshList();" >
                                <img class="selected" src="/assets/images/Selected.svg">
                                <img class="unselected" src="/assets/images/Unselected.svg">
                                <span>Negative sentiment calls</span>
                            </a>

                            <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'positive-sentiment'}" (click)="selectedFilter='positive-sentiment';refreshList();" >
                                <img class="selected" src="/assets/images/Selected.svg">
                                <img class="unselected" src="/assets/images/Unselected.svg">
                                <span>Positive sentiment calls</span>
                            </a>

                            <a href="javascript:void(0)" class="filter"  [ngClass]="{'active': selectedFilter == 'unverified'}" (click)="selectedFilter='unverified';refreshList();">
                                <img class="selected" src="/assets/images/Selected.svg">
                                <img class="unselected" src="/assets/images/Unselected.svg">
                                <span>Unverified Calls</span>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Right side end -->

            </div>
        </div>
    </div>
</div>