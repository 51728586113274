<div class="row no-margin top-space">
  <div class="main-content">
    <form  autocomplete="off" name="keywordForm" #keywordForm="ngForm" (ngSubmit)="keywordForm.form.valid && saveKeywords()" novalidate>
      <div class="data-mgmt">
        <div class="title">
          DATA MANAGEMENT
        </div>

        <!-- Choose campaign section start -->
        <div class="choose-campaign-section">
          <div class="row">
            <div class="col-sm-4">
              <div>
                <label>CHOOSE CAMPAIGN</label>
                <select (change)="onSelectCampaign($event.target.value)" class="form-field" [(ngModel)]="keywordsData.campaign" #campaign="ngModel" required="" name="campaign" >
                  <option value="" selected>--Select--</option>
                  <option *ngFor="let campaign of campaigns" value="{{campaign.id}}" >
                    {{campaign.name}}
                  </option>
                </select>

                <div *ngIf="keywordForm.submitted && campaign.invalid" class="error">
                  <div *ngIf="campaign.errors.required" >
                    Please choose campaign
                  </div>
                </div>
              </div>
              <!-- <div>
                <button class="stnd-bg-btn">LOAD CAMPAIGN SETTINGS</button>
              </div> -->
            </div>
          </div>
        </div>
        <!-- Choose campaign section end -->

        <!-- Phrases section start -->
        <div class="phrases-section">
          <div class="row">
            <div class="col-sm-8">
              <div class="sub-heading">
                QUALITY CHECKS
              </div>
              <div class="sub-sub-heading">
                Enter the target phrases and their corresponding weightages
              </div>
            </div>

            <div class="col-sm-3 text-right">
              <a href="javascript:void(0)" class="view-all" >View Dictionary</a>
            </div>
          </div>

          <!-- phrases listing start -->
          <div class="phrases-list">
            <div class="row">

              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="sub-heading">
                      QUALITY CHECK VALUE
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="sub-heading">
                      Weight
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="sub-heading">
                      QUALITY LABEL
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12" *ngFor="let phrase of keywordsData.keywords; let index=index">
                <div class="phrase-stamp">
                  <div class="row">

                    <div class="col-sm-4">
                      <textarea [(ngModel)]="phrase.phrase" #keywordm="ngModel" required type="text" [name]="'keyword'+index" placeholder="Enter Phrase here" rows="1" cxrAutoSize ></textarea>
                      <div *ngIf="keywordForm.submitted && keywordm.invalid" class="error">
                        <div *ngIf="keywordm.errors.required" >
                          Please enter phrase
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-1 text-center">
                      <input [(ngModel)]="phrase.score" #count="ngModel" required type="text" (change)="onChangeWeight()" [name]="'count'+index" placeholder="5" class="weight-box" >
                      <div *ngIf="keywordForm.submitted && count.invalid" class="error">
                        <div *ngIf="count.errors.required" >
                          Required
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <input [(ngModel)]="phrase.check" #label="ngModel" required type="text" [name]="'label'+index" placeholder="Greeting" >
                      <div *ngIf="keywordForm.submitted && label.invalid" class="error">
                        <div *ngIf="label.errors.required" >
                          Please enter label
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <a (click)="removeKeyword(index)" href="javascript:void(0)" class="remove" >remove</a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- This section only for demo purpose start -->
              <!-- <div class="col-sm-12">
                <div class="phrase-stamp">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="demo-filed tag-section" contenteditable="true" >
                        Hello
                        <span class="tag">NAME</span>
                      </div>
                    </div>
                    <div class="col-sm-1 text-center">
                      <input type="text" name="" placeholder="5" class="weight-box" >
                    </div>
                    <div class="col-sm-4">
                      <input type="text" name="" placeholder="Greeting" >
                    </div>
                    <div class="col-sm-3">
                      <a href="javascript:void(0)" class="remove" >remove</a>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- This section only for demo purpose end -->

              <!-- <div class="col-sm-12 tag-section">
                <div class="sub-heading">CHOOSE TAG</div>
                <span class="tag">NAME</span>
                <span class="tag">age</span>
                <span class="tag">contact details</span>
              </div> -->

              <!-- Add button start -->
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-8">
                    <button type="button" (click)="openDrawer()" class="bdr-btn">ADD NEW PHRASE</button>
                  </div>
                </div>
              </div>
              <!-- Add button end -->

              <!-- Divider start -->
              <!-- <div class="col-sm-12">
                <div class="devider"></div>
              </div> -->
              <!-- Divider end -->

              <!-- Threshold start -->
              <div class="col-sm-12 threshold">
                <!-- <div class="title">THRESHOLD VALUES</div> -->
                <div class="row">
                  <div class="col-sm-4">
                    <div class="sub-heading">
                      QUALITY THRESHOLD
                    </div>
                  </div>
                  <!-- <div class="col-sm-4">
                    <div class="sub-heading">
                      QUALITY LABEL
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="sub-heading">
                      PASS/FAIL THRESHOLD
                    </div>
                  </div> -->
                </div>

                <div class="stamp">
                  <div class="heading">
                    THRESHOLD VALUE
                  </div>
                  <input [(ngModel)]="keywordsData.quality_threshold" #label="ngModel" type="text" name="quality_threshold" class="from" placeholder="Enter here" required #quality_threshold="ngModel" >
                  <div *ngIf="keywordForm.submitted && quality_threshold.invalid" class="error">
                    <div *ngIf="quality_threshold.errors.required" >
                      Please enter threshold
                    </div>
                  </div>
                </div>
              </div>

              <!-- Divider start -->
              <div class="col-sm-12">
                <div class="devider"></div>
              </div>
              <!-- Divider end -->

              <!-- Progress start -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="sub-heading">TOTAL SCORE</div>
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" aria-valuenow="70"
                      aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': totalWeight+'%'}" >
                        {{totalWeight}}%
                      </div>
                      <span class="min">0</span>
                      <span class="max">100</span>
                    </div>
                  </div>
                  <div class="col-sm-4 text-right">
                    <button type="submit" class="stnd-bg-btn">SAVE</button>
                  </div>
                </div>
              </div>
              <!-- Progress end -->

              <!-- <div class="col-sm-12" *ngFor="let phrase of [0,1,2,4]">
                <div class="phrase-stamp">
                  <div class="row">
                    <div class="col-sm-4">
                      <input type="text" name="" placeholder="Enter Phrase here" >
                    </div>
                    <div class="col-sm-4">
                      <input type="text" name="" placeholder="Greeting" >
                    </div>
                    <div class="col-sm-1 text-center">
                      <input type="text" name="" placeholder="5" class="weight-box threshold-weight" >
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- Threshold end -->
            </div>
          </div>
          <!-- phrases listing end -->
        </div>
        <!-- Phrases section end -->
      </div>
    </form>

    <!-- cxr-vulnerability-drawer start -->
    <!-- <cxr-vulnerability-drawer></cxr-vulnerability-drawer> -->
    <!-- cxr-vulnerability-drawer end -->

  </div>
</div>
