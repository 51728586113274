import { Component, OnInit } from '@angular/core';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { LoadingService } from '../../services/loading.service';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'cxr-campaigndetails',
  templateUrl: './campaigndetails.component.html',
  styleUrls: ['./campaigndetails.component.scss']
})
export class CampaigndetailsComponent implements OnInit {

  countCalls = 0;
  wordData: CloudData[] = [];
  wordDataCount = [];
  qualityThreshold = 0;
  overviewData:any = {
    "calls": 0,
    "ces": 0,
    "csat": 0,
    "nps": 0
  };
  campaignId;
  campaignData = null;
  team:any = {};
  options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
    width: 200,
    // if height is between 0 and 1 it will be set to the height of the upper element multiplied by the value
    height: 250,
    overflow: false,
  };
  colorScheme = {
    domain: ['#54BFA8', '#FDE04C', '#A84748', '#53CCF9', '#59BF30', '#F2923D']
  };
  dateDisplay = false;
  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period"
  data = [
    {
      "name": "Greeting",
      "value": 6
    }
  ];
  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  dateRange = [];

  start_date:any;
  end_date:any;

  totalVI = {
    customer: 0,
    agent: 0
  }

  total_agents : Set<number> = new Set();

  faildCalls = 0;
  worningCalls = 0;
  totalScore = 0;
  projects: any = [];
  teams: any;
  campaing: any;
  callSection: any=[];
  selectedId = [];
  projectId;
  callSectionName;
  selectedFilter;
  qualityCheck: any = [];
  nonCompliant: any = {};


  isProjectLoading = true;
  checksFilterModel = '';
  campaignsFilterModel = '';
  projectFilterModel = '';
  callSectionFilterModel = '';
  topChangeFilterModel = 'Opening';
  openingFilterModel = [];
  bodyFilterModel = [];
  closingFilterModel = [];
  customerFilterModel = [];
  agentFilterModel = [];


  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private loadingService: LoadingService,
    private dashBoardService: DashboardService,

  ) { }

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.paramMap.get('id');
    
    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];
    
    this.setDate('last30days', '1 Month');
    this.toggleDate();
    
  }

  setDate = (dateSelected, dateSelectedDisplay) => {
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    this.toggleDate();
    this.getCampaign();
    this.getAllCampaing();
    this.getCallSection();
    this.getAllTeams();
    this.getNonCompliantCall();
  }

  toggleDate() {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }

  }
  openCall(id) {
    this.router.navigateByUrl('/call/' + id);
  }
  getCampaign() {
    let term = (this.campaignId != undefined ? ('&campId=' + this.campaignId) : '');
    // term = term + (this.campaignId != undefined ? ('&campId=' + this.campaignId) : '');
    term = term + (this.callSectionName != undefined ? ('&qualityCheck=' + this.callSectionName) : '');
    // term = term + ((this.cloudText != undefined && this.cloudText != '') ? ("&cloudText=" + this.cloudText) : "");
    // term = term + (this.status != undefined ? ("&status=" + this.status) : "");
    // term = term + (this.projectId != undefined ? ("&projectId=" + this.projectId) : "");
    // term = term + (this.focusKeyword != undefined ? ("&focusKeyword=" + this.focusKeyword) : "");
    // term = term + (this.selectedFilter != undefined ? ("&filter=" + this.selectedFilter) : "");
    term = term + ((this.checksFilterModel != undefined && this.checksFilterModel != '') ? ("&qualityCheck=" + this.checksFilterModel) : "");

    this.totalScore = 0;
    this.faildCalls = 0;
    this.worningCalls = 0;
    this.loadingService.apiStart();
    this.commonService.getCampaign(term, this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.campaignData = data.calls;
        // console.log("camping",this.campaignData)
        
        // calculet total agent
        this.campaignData.forEach(call => {
          // Total agent
          this.total_agents.add(call.agent_id);
  
          // Total QCS
          this.totalScore = this.totalScore + parseInt(call.quality_score) ;
          if (call.quality_score < 0.25) {
          
            // failed calls
            this.faildCalls = this.faildCalls + 1;
          }else if (call.quality_score > 0.25 && call.quality_score < 0.37) {
          
            // WARNING calls
            this.worningCalls = this.worningCalls + 1;
          }else if (call.quality_score > 0.37) {
          
            // pass calls
            this.totalScore = this.totalScore + 1;
          }
        });
        
        /*Calculate total VI start*/
        this.totalVI['customer'] = 0;
        this.totalVI['agent'] = 0;

        this.campaignData.forEach((item)=>{
          this.totalVI['customer'] = this.totalVI['customer'] + item.customer_vi;
          this.totalVI['agent'] = this.totalVI['agent'] + item.agent_vi;
        });

        this.totalVI['customer'] = this.totalVI['customer']/this.campaignData.length;
        this.totalVI['agent'] = this.totalVI['agent']/this.campaignData.length;
        /*Calculate total VI end*/

        this.overviewData = data.overview;
        this.qualityThreshold = data.overview.quality_threshold || 0;
        this.team = data.team;
        this.data = data.checks.map(element => {
          const currentObj = {
            "name": this.capitalizeFirstLetter(element.checkname),
            "value": (((element.pass)/(data.calls.length))*100).toFixed()
          }
          return currentObj;
        });
        this.data = [...this.data];
        // console.log(this.data);
        let tempCloudData = [];


        data.keywords.sort(function(a, b) {
          return parseFloat(b.count) - parseFloat(a.count);
        });

        data.keywords.forEach(element => {
          tempCloudData.push({
            weight: element.count,
            text: element.keyword
          })
        });

        const changedData$: Observable<CloudData[]> = of(tempCloudData);
        changedData$.subscribe(res => this.wordData = res);
        this.wordDataCount = tempCloudData;

        this.wordDataCount = this.wordDataCount.slice(0, 7);

      })
      .catch((err) => {
        this.loadingService.apiStop();
      });

  }
  getNonCompliantCall() {
    let term = "";
    term = term + (this.topChangeFilterModel != undefined ? ('&callsection=' + this.topChangeFilterModel) : '');
    
    this.isProjectLoading = true;
    this.dashBoardService.getNonCompliantCall(term, this.start_date ,this.end_date)
    .toPromise()
    .then((data: any) => {
      this.nonCompliant = data;
      // console.log('nonCompliant',this.nonCompliant)
      this.isProjectLoading = false;
    })
    .catch((err) => {
      this.isProjectLoading = false;
    });
  }

  onProjectChange(id){
    // this.campaignsFilterModel = '';
    this.projectId = id == '' ? null : id;
    this.getCampaign();
  }

  onCampaignChange(id){
    this.campaignId = id == '' ? null : id;
    // this.projectFilterModel = '';
    this.getCampaign();
  }

  onCallSectionChange(id){
    let array = [];
    array = [ ,...this.openingFilterModel];
    array = array.concat(this.bodyFilterModel);
    array = array.concat(this.closingFilterModel);
    array = array.concat(this.customerFilterModel);
    array = array.concat(this.agentFilterModel);
    // console.log(array);
    
    let name = '';
    array.forEach((ele) => {
      if (name == '' && ele != '0') {
        name = ele;
      }
       else if (name != '' && ele != '0') {
        name = name + "," + ele;
      }
    });

    this.checksFilterModel = name == '' ? null : name;
    this.getCampaign();
  }
  onChecksChange(id){
    // this.projectFilterModel = '';
    this.getCampaign();
  }
  callSectionTopChange(name) {
    this.getNonCompliantCall();
  }
  resetFilter() {
    this.checksFilterModel = '';
    this.campaignsFilterModel = '';
    this.projectFilterModel = '';
    this.selectedFilter = '';
    this.bodyFilterModel = [];
    this.openingFilterModel = [];
    this.closingFilterModel = [];
    this.customerFilterModel = [];
    this.agentFilterModel = [];
    this.callSectionName = undefined
    this.getCampaign();
  }
  getQualityCheck() {

    // this.qualityCheck = [];
    let term = this.selectedFilter;
    // term = term + (this.topChangeFilterModel != undefined ? ('qualitycheck=' + 'Opening') : '');
    
    this.isProjectLoading = true;
    this.dashBoardService.getQualityCheck(term)
    .toPromise()
    .then((data: any) => {
      this.qualityCheck = data.data;
      if (this.openingFilterModel.length <= 0) {
        this.openingFilterModel =Array.apply(null, Array(this.qualityCheck.length -1)).map(String.prototype.valueOf,"0")
      }
      if (this.bodyFilterModel.length <= 0) {
        this.bodyFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      if (this.closingFilterModel.length <= 0) {
        this.closingFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      if (this.customerFilterModel.length <= 0) {
        this.customerFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      if (this.agentFilterModel.length <= 0) {
        this.agentFilterModel =Array.apply(null, Array(this.qualityCheck.length - 1)).map(String.prototype.valueOf,"0")
      }
      console.log('qualityCheck',this.qualityCheck)
      this.isProjectLoading = false;
    })
    .catch((err) => {
      this.isProjectLoading = false;
    });
  }
  getCallSection() {
    this.isProjectLoading = true;
    this.dashBoardService.getcallSection()
    .toPromise()
    .then((data: any) => {
      this.callSection = data.data;
      // console.log(this.callSection)
      this.isProjectLoading = false;
    })
    .catch((err) => {
      this.isProjectLoading = false;
    });

  }

  getAllCampaing = () => {
  this.isProjectLoading = true;
  this.commonService.getCampaigns(this.start_date, this.end_date)
    .toPromise()
    .then((data: any) => {
      this.campaing = data.campaigns[this.campaignId-1].name;
      this.isProjectLoading = false;
    })
    .catch((err) => {
      this.isProjectLoading = false;
    });

  }
  getAllTeams = () => {
    this.isProjectLoading = true;
    this.dashBoardService.getTeams()
      .toPromise()
      .then((data: any) => {
        this.teams = data.teams[this.campaignId-1].name;
        this.isProjectLoading = false;
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });
  
    }

  navigate() {
    this.router.navigate( ['product-manager'], { queryParams: { id: this.selectedId}});
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  getDuration(duration) {
    return this.toHHMMSS(duration);
  }
  toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":")
  }
  back = () => {
    window.history.back();
  }

  onDateChange(dates){
    this.start_date = dates[0];
    this.end_date = dates[1];

    this.getCampaign();
  }


  onClickWordCloud(data){
    if (data != undefined && data.text) {
      this.router.navigate(['/calls'], { queryParams: {campaignId: this.campaignId, word: data.text}});
    }
  }
}
