<div class="row no-margin top-space">
  <div class="main-content">
    <div class="row no-margin">
      <div class="left-container">
        <div class="metrics">
          <div class="attribute">
            <div class="key">
              Call Id
            </div>
            <div class="value">
              {{ callId }}
            </div>
          </div>
          <div class="attribute">
            <div class="key">
              Agent Id
            </div>
            <div class="value" *ngIf="finalData">
              {{ finalData.id }}
            </div>
          </div>
          <div class="attribute">
            <div class="key">
              Agent Name
            </div>
            <div class="value" *ngIf="finalData.user">
              {{ finalData.user.first_name }} {{ finalData.user.last_name }}
            </div>
          </div>
          <div class="attribute">
            <div class="key">
              Time and Date of call
            </div>
            <div class="value" *ngIf="finalData">
              {{ finalData.timestamp | date:'medium' }}
            </div>
          </div>
          <!-- <div class="attribute">
            <div class="key">
              Client Id
            </div>
            <div class="value">
              008125
            </div>
          </div> -->

          <div class="attribute">
            <div class="key">
              Verification
            </div>
            <div class="value" [ngClass]="{'verified' :( finalData && finalData.verified), 'not-verified' : !(finalData && finalData.verified)}" >
              {{finalData && finalData.verified ? 'Verified': 'Not Verified'}}
            </div>
          </div>

          <div class="attribute">
            <div class="key">
              CHANNELS
            </div>
            <div class="value" *ngIf="finalData">
              {{finalData.sentences[0].speaker_label == 'customer' ? 'Customer' + ',' : ''}}{{finalData.sentences[1].speaker_label == 'agent' ? 'Agent' + ',' : ''}}
            </div>
          </div>
        </div>
        <div class="search">
          <span class="search-icon">
            <i class="fa fa-search"></i>
          </span>                                     
          <!-- <input type="text"  (keyup)="addSearch()" id="My-Search" [(ngModel)]="searchterm" placeholder="Search Call Transcript"> -->
          <input type="text" disabled id="My-Search" [(ngModel)]="searchterm" placeholder="Search Call Transcript">
          <!-- <div class="searches">
            <div class="search-keyword blue">
              Greetings
              <div class="cancel-icon">
                x
              </div>
            </div>
            <div class="search-keyword orange">
              Car Hire
              <div class="cancel-icon">
                x
              </div>
            </div>
          </div> -->
        </div>


        <div class="row no-margin audio-graph">
          <div *ngIf="audioFileLoading" class="overlay"></div>
          <cxr-loading *ngIf="audioFileLoading" class="overlay-loader" ></cxr-loading>
          <div class="controls">
            <div class="control_line1">
              <div class="play" (click)="playAudio()">
                <div class="icon" *ngIf="!isPlaying">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14">
                    <path id="ic_pause_24px" d="M8,5V19l11-7Z" transform="translate(-8 -5)"
                      fill="#23bea7" />
                  </svg>

                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14">
                    <path id="ic_play_arrow_24px_grey" data-name="ic_play_arrow_24px grey" d="M8,5V19l11-7Z" transform="translate(-8 -5)" fill="#909090"/>
                    </svg>                                                                          -->
                </div>
                <div class="icon" *ngIf="isPlaying">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"
                    fill="none" stroke="#23bea7" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <rect x="6" y="4" width="4" height="16"></rect>
                    <rect x="14" y="4" width="4" height="16"></rect>
                  </svg>
                </div>
              </div>
              <div class="pause" (click)="resetPlayer()">
                <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <path id="ic_stop_24px" d="M6,6H18V18H6Z" transform="translate(-6 -6)"
                      fill="#23bea7" />
                  </svg>
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <path id="ic_stop_24px_grey" data-name="ic_stop_24px grey" d="M6,6H18V18H6Z" transform="translate(-6 -6)" fill="#909090"/>
                  </svg>                                                                          -->
                </div>
              </div>
            </div>
            <div class="control_line2">
              <div class="timer">
                {{ duration  }}
              </div>
            </div>
            <div>

              <audio id="music" src="{{audioLink}}" preload="metadata" type="audio/mpeg">
                <!-- <source  type="audio/mpeg"> -->
                Your browser does not support the audio format.
              </audio>

              <!-- <audio autoplay id="beepSound" [src]="'/assets/beepsound.mp3'" preload="metadata" type="audio/mpeg"> -->
                <!-- <source  type="audio/mpeg"> -->
                <!-- Your browser does not support the audio format. -->
              <!-- </audio> -->

              <div id="audio_player">
                <progress id='progress-bar' min='0' max='100' value='0' style="max-width:100%">0%
                  played</progress>
                <div class="time start" >0.00</div>
                <div *ngIf="transcriptJson.length > 0" class="time end"> {{toHHMMSS(transcriptJson[transcriptJson.length-1]['time_seconds'])}}</div>
              </div>
            </div>
            <div class="control_line3">
              <div class="icon-container" (click)="reverseAudio()">
                <div class="icon">
                  <i class="fa fa-undo"></i>
                </div>
              </div>
              <div class="icon-container" style="margin-right: 0px" (click)="forwardAudio()">
                <div class="icon">
                  <i class="fa fa-undo fa-flip-horizontal"></i>
                </div>
              </div>
            </div>
          </div>
          <div #GraphContainerRef class="graph" id="callDetailsGraphSection" (window:resize)="resizeChart(GraphContainerRef.offsetWidth) " (window:load)="resizeChart(GraphContainerRef.offsetWidth) ">
            <div [ngStyle]="isGreeting && {'display': 'none'}">
              <div id="waveform">
                <!-- <div class="btn" *ngIf="btnShow">
                  <a href="javascript:void(0)" (click)="changeBtn()" >Load Call  <i class="fa fa-spinner" aria-hidden="true"></i>
                  </a>
                </div> -->
              </div>
              <div class="linegraph" id="linegraph">
                <div class="line" id="line">

                  <!-- <div class="points background_blue">
  
                  </div> -->
                  <!-- <div class="points background_green bottom-1">

                  </div> -->
                  <!-- <div class="points background_orange top-1">

                  </div> -->
                  <!-- <div class="points background_yellow top-2">
  
                  </div> -->
                </div>
              </div>
              <!-- <progress id='progress-bar-warnings' min='0' max='100' value='0' style="width:440px">0%
                played</progress> -->
            </div>
            <div [ngStyle]="!isGreeting && {'display': 'none'}">
              <ngx-charts-area-chart-stacked [view]="viewStacked" [scheme]="colorSchemeStack"
                [results]="resultsStack" [gradient]="true" [showGridLines]="false" [xAxis]="true"
                [yAxis]="true" [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="false"
                [curve]="curve">
              </ngx-charts-area-chart-stacked>
            </div>

          </div>
        </div>
        <div class="infoline row no-margin" style="display: none;" >
          <div class="attribute">
            <div class="key">
              CUSTOMER DETAILS
            </div>
            <div class="value" *ngIf="finalData">
              {{finalData.customer.name || "NA"}}
            </div>
          </div>
          <div class="attribute">
            <div class="key">
              CUSTOMER EMAIL ADDRESS
            </div>
            <div class="value" *ngIf="finalData">
              {{finalData.customer.email || "NA"}}
            </div>
          </div>
          <div class="attribute">
            <div class="key">
              TOTAL EMAILS EXCHANGED
            </div>
            <div class="value" *ngIf="finalData">
              {{finalData.customer.emails || "NA"}}
            </div>
          </div>
        </div>
        <div class="switch-container">
          <div class="switch">
            <div class="can-toggle demo-rebrand-1">
              <input id="d" type="checkbox" checked (change)="changeGreeting()">
              <label for="d">
                <div class="can-toggle__switch" data-checked="Audio" data-unchecked="Sentiment ">
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="massage" *ngIf="transcriptJson.length <= 0">
          <span>transcript not Found</span>
        </div>
        <div class="comments slim-scrollbar" id="sentences">
          <div class="row no-margin comment-line" *ngFor="let comment of transcriptJson;let i = index" style="display: block;" [ngClass]="{'text-right': i%2==0}" >
            <!-- [ngClass]="{'text-right': comment.speaker == 'channel_2'}" -->
            <div *ngIf="comment.speaker_label=='agent'" style="width:80%;display: inline-flex;">
              <div class="left-icon" >
                <!-- *ngIf="comment.speaker == 'channel_1'" -->
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                  <g id="avatar_Green" data-name="avatar Green" transform="translate(0.063)">
                    <circle id="Ellipse_112" data-name="Ellipse 112" cx="17.5" cy="17.5" r="17.5"
                      transform="translate(-0.063)" fill="#23bea7" />
                    <path id="Union_10" data-name="Union 10"
                      d="M0,13.1V11.464c0-1.8,2.948-3.276,6.551-3.276S13.1,9.662,13.1,11.464V13.1ZM3.275,3.276A3.275,3.275,0,1,1,6.551,6.551,3.276,3.276,0,0,1,3.275,3.276Z"
                      transform="translate(10.918 9.826)" fill="#fff" />
                  </g>
                </svg>
                <div class="label">
                  <!-- {{comment.speaker }} -->
                  Agent
                </div>
              </div>
              <div class="message agent-message" id="{{comment.id}}">
                <div>
                  {{comment.text }}
                </div>
                <div class="bottom_infoline">
                  <!-- <div class="attribute">
                    Sentiment Score - <span
                      [ngClass]="{'green': comment.sentiment_score > 85,
                          'orange': comment.sentiment_score >= 65 && comment.sentiment_score <= 85, 
                          'red': comment.sentiment_score < 65}">{{ comment.sentiment_score }}</span> /100
                  </div> -->

                  <!-- <div class="attribute">
                       <div class="icon green">
                        <i class="fa fa-comments"></i>
                       </div>
                       <div class="value">
                        Comments
                       </div>
                     </div> -->
                  <div class="attribute d-none" *ngFor="let check of comment.checks">
                    <div class="{{getClassDots(check)}}">

                    </div>
                    <div class="value">
                      {{ capitalizeFirstLetter(check.checkname) }}
                    </div>
                  </div>
                  <!-- <div class="attribute" *ngIf="toBeDisplayedGreeting(comment.checks,'ending')">
                    <div class="dot background_green">

                    </div>
                    <div class="value">
                      Ending
                    </div>
                  </div> -->

                  <!-- <div class="attribute" style="float: right;cursor: pointer;"
                    (click)="openCommentModal(comment.id)">
                    <div class="icon green">
                      <i class="fa fa-comments"></i>
                    </div>
                    <div class="value">
                      Add Comment
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="right-icon">

              </div>
            </div>
            <div *ngIf="comment.speaker_label=='customer'" style="width:80%;display: inline-flex;">
              <!-- *ngIf="comment.speaker == 'channel_2'" -->
              <div class="left-icon" >
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                  <g id="avatar_Grey" data-name="avatar Grey" transform="translate(-0.305 0.342)">
                    <circle id="Ellipse_114" data-name="Ellipse 114" cx="17.5" cy="17.5" r="17.5"
                      transform="translate(0.305 -0.342)" fill="#33363a" />
                    <path id="Union_11" data-name="Union 11"
                      d="M0,13.1V11.464c0-1.8,2.948-3.276,6.551-3.276S13.1,9.662,13.1,11.464V13.1ZM3.275,3.276A3.275,3.275,0,1,1,6.551,6.551,3.276,3.276,0,0,1,3.275,3.276Z"
                      transform="translate(10.918 9.826)" fill="#fff" />
                  </g>
                </svg>
                <div class="label">
                  <!-- {{comment.speaker }} -->
                  Customer
                </div>
              </div>
              <div class="message customer-message" id="{{comment.id}}">
                <div>
                  {{comment.text }}
                </div>
                <div class="bottom_infoline">
                  <!-- <div class="attribute">
                    Sentiment Score - <span
                      [ngClass]="{'green': comment.sentiment_score > 85,
                        'orange': comment.sentiment_score >= 65 && comment.sentiment_score <= 85, 
                        'red': comment.sentiment_score < 65}">{{ comment.sentiment_score }}</span> /100
                  </div> -->
                  <div class="attribute" *ngFor="let check of comment.checks">
                    <div class="{{getClassDots(check)}}">

                    </div>
                    <div class="value">
                      {{ capitalizeFirstLetter(check.checkname) }}
                    </div>
                  </div>
                  <!-- <div class="attribute" style="float: right;cursor: pointer;"
                    (click)="openCommentModal(comment.id)">
                    <div class="icon green">
                      <i class="fa fa-comments"></i>
                    </div>
                    <div class="value">
                      Add Comment
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="right-icon">

              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="right-container">
        <div class="row">
          <div class="col-sm-11">
            <div class="overall-graph">
              <div class="row" >
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="title" style="margin-bottom: 5px;" >
                        <div style="margin-bottom:0px;white-space: nowrap;">QUALITY CHECKS </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="top-dropdown" *ngIf="finalData && finalData.quality_checks">
                        <div class="dropdown">
                          <select [(ngModel)]="topChangeFilterModel"  (change)="callSectionTopChange($event.target.value)" class="form-select btn-top" aria-label="Default select example" >
                            <option value="Select" class="dropdown-item" >Select Section</option>
                            <option [value]="item" class="dropdown-item" *ngFor="let item of callSection">{{item}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="text" style="margin-bottom: 5px;" >
                        <div style="margin-bottom:0px">overall QCS</div>
                        <div class="text-value" [ngClass]="{'bad' : (totalScore / allCallCheck.length) * 100 < 25,'good' : (totalScore / allCallCheck.length) * 100 > 37, 'average' : (totalScore / allCallCheck.length) * 100 >= 25 && (totalScore / allCallCheck.length) * 100 <= 37 }">{{(totalScore / allCallCheck.length) * 100 | number : '1.2-2'}}%</div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="text" style="margin-bottom: 5px;" >
                        <div  style="margin-bottom:0px">overall status </div>
                        <div class="text-value" [ngClass]="{'good': ((totalScore /allCallCheck.length) * 100) > 75,'bad': ((totalScore /allCallCheck.length) * 100) < 75}">{{((totalScore / allCallCheck.length) * 100) > 75 ? 'Pass' : 'fail'}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="finalData && finalData.quality_checks">
                  <!-- <div class="col-sm-4" *ngFor="let quality of dataGraphTop;let i = index"> -->
                    <a class="col-sm-4" *ngFor="let quality of allCallCheck;let i = index"  (click)="selectedOrder=quality; searchterm = quality.checkname;showMenu()" style="cursor: pointer;">
                      <div class="quality-stamp " [ngClass]="{'bad' : quality.score < 0.25,'good' : quality.score > 0.37, 'average' : quality.score >= 0.25 && quality.score <= 0.37 }">
                        <div class="row">
                          <div class="col-6"> 
                            <div class="label">
                              LABEL
                            </div>
                            <div class="value">
                              {{quality.checkname}}
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="label">
                              QCS
                            </div>
                            <div class="value">
                              <!-- {{quality.score * 100 | number : '1.2-2'}}% -->
                              {{quality.score < 0.45 ? 'Fail' : '' || quality.score > 0.45 && quality.score < 0.75 ? 'Warning' : ''  || quality.score > 0.75 ? 'Pass' : ''}}
                            </div>
                          </div>
                          <div class="col-3 test d-none">
                            <label class="container" id="tool" >
                              <input type="checkbox" checked >
                                <span class="checkmark" [ngClass]="{'color' : i%2 != 0,'color2' : i/4 == 0}">
                                  <span *ngIf="i%2 != 0"><i class="fa fa-times-circle-o" aria-hidden="true">
                                  </i></span>
                                <span *ngIf="i/4 == 0"><i class="fa fa-exclamation-triangle">
                                </i></span>
                              </span>
                              <!-- <span *ngIf="i%2 != 0 || i/4 == 0" class="massage">{{quality.checkname}}</span> -->
                            </label>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div class="col-sm-11 " *ngIf="allCallCheck.length <= 0">
                      <div class="label" style="color: #909090;">
                        <span>No Checks Found</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="title" style="margin-bottom: 5px;" >
                        <div style="margin-bottom:0px;white-space: nowrap;">RISK CHECKS </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="top-dropdown d-none" *ngIf="finalData && finalData.quality_checks">
                        <div class="dropdown">
                          <select [(ngModel)]="topChangeFilterModelMca"  (change)="callSectionTopChangeMca($event.target.value)" class="form-select btn-top" aria-label="Default select example" >
                            <option value="Select" class="dropdown-item" >Select Section</option>
                            <option [value]="item" class="dropdown-item" *ngFor="let item of callSectionMca">{{item}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="text" style="margin-bottom: 5px;" >
                        <div style="margin-bottom:0px">overall MCA</div>
                        <div class="text-value" [ngClass]="{'bad' : (totalScoreMca / allCallCheckMca.length) * 100 < 25,'good' : (totalScoreMca / allCallCheckMca.length) * 100 > 37, 'average' : (totalScoreMca / allCallCheckMca.length) * 100 >= 25 && (totalScoreMca / allCallCheckMca.length) * 100 <= 37 }">{{(totalScoreMca / allCallCheckMca.length) * 100 | number : '1.2-2'}}%</div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="text" style="margin-bottom: 5px;" >
                        <div  style="margin-bottom:0px">overall status </div>
                        <div class="text-value" [ngClass]="{'good': ((totalScoreMca /allCallCheckMca.length) * 100) > 75,'bad': ((totalScoreMca /allCallCheckMca.length) * 100) < 75}">{{((totalScoreMca / allCallCheckMca.length) * 100) > 75 ? 'Pass' : 'fail'}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="finalData && finalData.fraud">
                  <!-- <div class="col-sm-4" *ngFor="let quality of dataGraphTop;let i = index"> -->
                    <a class="col-sm-4" *ngFor="let quality of allCallCheckMca;let i = index"  (click)="selectedOrder=quality; searchterm = quality.checkname;showMenu()" style="cursor: pointer;">
                      <div class="quality-stamp " [ngClass]="{'bad' : quality.score < 0.25,'good' : quality.score > 0.37, 'average' : quality.score >= 0.25 && quality.score <= 0.37 }">
                        <div class="row">
                          <div class="col-6"> 
                            <div class="label">
                              LABEL
                            </div>
                            <div class="value">
                              {{quality.checkname}}
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="label">
                              QCS
                            </div>
                            <div class="value">
                              <!-- {{quality.score * 100 | number : '1.2-2'}}% -->
                              {{quality.score < 0.45 ? 'Fail' : '' || quality.score > 0.45 && quality.score < 0.75 ? 'Warning' : ''  || quality.score > 0.75 ? 'Pass' : ''}}
                            </div>
                          </div>
                          <div class="col-3 test d-none">
                            <label class="container" id="tool" >
                              <input type="checkbox" checked >
                                <span class="checkmark" [ngClass]="{'color' : i%2 != 0,'color2' : i/4 == 0}">
                                  <span *ngIf="i%2 != 0"><i class="fa fa-times-circle-o" aria-hidden="true">
                                  </i></span>
                                <span *ngIf="i/4 == 0"><i class="fa fa-exclamation-triangle">
                                </i></span>
                              </span>
                              <!-- <span *ngIf="i%2 != 0 || i/4 == 0" class="massage">{{quality.checkname}}</span> -->
                            </label>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div class="col-sm-11 " *ngIf="allCallCheckMca.length <= 0">
                      <div class="label" style="color: #909090;">
                        <span>No Checks Found</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 d-none">
                  <div class="title" style="margin-bottom: 5px;" >
                    <div style="margin-bottom:0px">SERVICE VI </div>
                  </div>

                  <div class="row" *ngIf="finalData && finalData.quality_checks">
                  <!-- <div class="col-sm-4" *ngFor="let quality of dataGraphTop;let i = index"> -->
                    <div class="col-sm-4" *ngFor="let quality of agentViCheck;let i = index">
                      <div class="quality-stamp " [ngClass]="{'bad' : quality.score < 0.25,'good' : quality.score > 0.37, 'average' : quality.score >= 0.25 && quality.score <= 0.37 }">
                        <div class="row">
                          <div class="col-5">
                            <div class="label">
                              LABEL
                            </div>
                            <div class="value">
                              {{quality.checkname}}
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="label">
                              AI Confidence
                            </div>
                            <div class="value">
                              <!-- {{quality.score * 100 | number : '1.2-2'}}% -->
                              {{quality.score < 0.45 ? 'Fail' : '' || quality.score > 0.45 && quality.score < 0.75 ? 'Warning' : ''  || quality.score > 0.75 ? 'Pass' : ''}}
                            </div>
                          </div>
                          <div class="col-3 test d-none">
                            <label class="container" id="tool" >
                              <input type="checkbox" checked >
                                <span class="checkmark" [ngClass]="{'color' : i%2 != 0,'color2' : i/4 == 0}">
                                  <span *ngIf="i%2 != 0"><i class="fa fa-times-circle-o" aria-hidden="true">
                                  </i></span>
                                <span *ngIf="i/4 == 0"><i class="fa fa-exclamation-triangle">
                                </i></span>
                              </span>
                              <!-- <span *ngIf="i%2 != 0 || i/4 == 0" class="massage">{{quality.checkname}}</span> -->
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 d-none">
                  <div class="title" style="margin-bottom: 5px;" >
                    <div style="margin-bottom:0px">CUSTOMER VULNERABILITY </div>
                  </div>

                  <div class="row" *ngIf="finalData && finalData.quality_checks">
                  <!-- <div class="col-sm-4" *ngFor="let quality of dataGraphTop;let i = index"> -->
                    <div class="col-sm-4" *ngFor="let quality of customerViCheck;let i = index">
                      <div class="quality-stamp " [ngClass]="{'bad' : quality.score < 0.25,'good' : quality.score > 0.37, 'average' : quality.score >= 0.25 && quality.score <= 0.37 }">
                        <div class="row">
                          <div class="col-5">
                            <div class="label">
                              LABEL
                            </div>
                            <div class="value">
                              {{quality.checkname}}
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="label">
                              AI Confidence
                            </div>
                            <div class="value">
                              <!-- {{quality.score * 100 | number : '1.2-2'}}% -->
                              {{quality.score < 0.45 ? 'Fail' : '' || quality.score > 0.45 && quality.score < 0.75 ? 'Warning' : ''  || quality.score > 0.75 ? 'Pass' : ''}}
                            </div>
                          </div>
                          <div class="col-3 test d-none">
                            <label class="container" id="tool" >
                              <input type="checkbox" checked >
                                <span class="checkmark" [ngClass]="{'color' : i%2 != 0,'color2' : i/4 == 0}">
                                  <span *ngIf="i%2 != 0"><i class="fa fa-times-circle-o" aria-hidden="true">
                                  </i></span>
                                <span *ngIf="i/4 == 0"><i class="fa fa-exclamation-triangle">
                                </i></span>
                              </span>
                              <!-- <span *ngIf="i%2 != 0 || i/4 == 0" class="massage">{{quality.checkname}}</span> -->
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 d-none">
                  <div class="title">
                    <div style="margin-bottom:20px">TALK TIME</div>
                    <div>
                      <ngx-charts-pie-chart  [view]="[215,230]" [margins]="[0,0,0,0]"
                        [scheme]="colorSchemeOverallPie" [results]="overallSingleData" [gradient]="true"
                        [legend]="true" [legendTitle]="''" [labels]="false" [legendPosition]="'below'"
                        [tooltipText]="convertMinutes">
                      </ngx-charts-pie-chart>
                    </div>

                    <div class="talking-sumary">
                      <div class="title">AGENT VS CUSTOMER TALKING</div>

                      <ul>
                        <li class="client" >Agent - <span> 32 Minutes </span> </li>
                        <li class="customer" >Customer - <span> 34 Minutes </span> </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="title">
                    <div class="row">
                      <div class="col-sm-6" style="margin-bottom:20px">SENTIMENT</div>
                      <div class="col-sm-6" style="text-align: right;" *ngIf="finalData">
                        <span *ngIf="finalData.sentiment_score >= -0.2 && finalData.sentiment_score <= 0.2">Neutral</span>
                        <span *ngIf="finalData.sentiment_score > 0.2 ">Positive</span>
                        <span *ngIf="finalData.sentiment_score < -0.2">Negative</span>
                      </div>
                    </div>
                    <div class="round-graph" >
                      <!-- <div class="graph">
                        <ngx-charts-pie-chart [view]="[130, 130]" [scheme]="colorSchemeDonut" [results]="series"
                          [margins]="[0,0,0,0]" [doughnut]="true" [legendTitle]="'Hi the'"
                          [labelFormatting]="pieChartLabel.bind(this, series)" [animations]="false">
                        </ngx-charts-pie-chart>
                        <div class="label">
                          <div class="value" style="margin-left:5px" *ngIf="finalData && finalData.sentiment_score">
                            {{ finalData.sentiment_score.toFixed(1) }}<span style="font-size: 12px;margin-left:3px">/ 100</span>
                          </div>
                          <div class="key">
                            Sentiment
                          </div>
                        </div>
                      </div> -->
                      

                      <div class="sentiment-summery" *ngIf="finalData">
                        <ul>
                          <li >
                            <div class="row">
                              <div class="col-sm-6">
                                Anger
                              </div>
                              <div class="col-sm-6 text-right"  *ngIf="finalData.anger != null">
                                {{finalData.anger * 100 | number : '1.2-2'}}
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.anger == null">
                                Not Found
                              </div>
                            </div>
                          </li>
                          
                          <li >
                            <div class="row">
                              <div class="col-sm-6">
                                Fear
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.fear != null">
                                {{finalData.fear * 100 | number : '1.2-2'}}
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.fear == null">
                                Not Found
                              </div>
                            </div>
                          </li>

                          <li class="" >
                            <div class="row">
                              <div class="col-sm-6">
                                Joy
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.joy != null">
                                {{finalData.joy * 100 | number : '1.2-2'}}
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.joy == null">
                                Not Found
                              </div>
                            </div>
                          </li>

                          <li >
                            <div class="row">
                              <div class="col-sm-6">
                                Sadness
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.sadness != null">
                                {{finalData.sadness * 100 | number : '1.2-2'}}
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.sadness == null">
                                Not Found
                              </div>
                            </div>
                          </li>

                          <li >
                            <div class="row">
                              <div class="col-sm-6">
                                Polite
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.polite != null">
                                {{finalData.polite * 100 | number : '1.2-2'}}
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.polite == null">
                                Not Found
                              </div>
                            </div>
                          </li>

                          <li >
                            <div class="row">
                              <div class="col-sm-6">
                                Satisfied
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.satisfied != null">
                                {{finalData.satisfied * 100 | number : '1.2-2'}}
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.satisfied == null">
                                Not Found
                              </div>
                            </div>
                          </li>

                          <li >
                            <div class="row">
                              <div class="col-sm-6">
                                Sympathetic
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.sympathetic != null">
                                {{finalData.sympathetic * 100 | number : '1.2-2'}}
                              </div>
                              <div class="col-sm-6 text-right" *ngIf="finalData.sympathetic == null">
                                Not Found
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              

              <div class="row" style="margin-top: 20px;" >
                <!-- <div class="col-sm-6">
                  <div class="title">
                    <div style="margin-bottom:20px;">WORD CLOUD</div>
                    <div>
                      <cxr-word-cloud *ngIf="wordData.length > 0" [data]="wordData" (onClick)="markWord($event)" ></cxr-word-cloud>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-sm-1"></div> -->
                <div class="col-sm-12 d-none">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="vi-stamp">
                        <div class="title">
                          AGENT V.I
                        </div>
                        <div class="score">
                          <span class="gained" *ngIf="finalData" [ngClass]="{'green': finalData.agent_vi  > 0.37,
                          'orange': finalData.agent_vi >= 0.25 && finalData.agent_vi <= 0.37, 
                          'red': finalData.agent_vi < 0.25}" >
                            {{finalData.agent_vi * 100 || 0 | number : '1.2-2'}}
                          </span>
                          <span class="total ">/100</span>
                        </div>
                        <!-- <div class="note">
                          Needs Review
                        </div> -->
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="vi-stamp">
                        <div class="title">
                          CUSTOMER V.I
                        </div>
                        <div class="score">
                          <span class="gained" *ngIf="finalData" [ngClass]="{'green': finalData.customer_vi > 0.37,
                          'orange': finalData.customer_vi >= 0.25 && finalData.customer_vi <= 0.37, 
                          'red': finalData.customer_vi < 0.25}" >
                            {{finalData.customer_vi * 100 || 0 | number : '1.2-2'}}
                          </span>
                          <span class="total">/100</span>
                        </div>
                        <!-- <div class="note">
                          Not Vulnerable
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="date-picker">
                <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18 20">
                    <path id="ic_event_busy_24px"
                      d="M19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z"
                      transform="translate(-3 -1)" fill="#909090" />
                  </svg>
                </div>
                <div class="label">
                  Select display period
                </div>
              </div> -->
              <!-- <div class="graph row no-margin">
                <div class="left-graph">

                </div>
                <div class="right-graph">

                </div>
              </div> -->
            </div>
            <div class="round-graph" *ngIf="false" >
              <div class="row no-margin">
                <div class="metric">
                  <div class="top">
                    VULNERABILITY INDEX
                  </div>
                  <div class="mid">
                    <span [ngClass]="{'red': vulnerabilityIndex > 85,
                    'orange': vulnerabilityIndex >= 65 && vulnerabilityIndex <= 85, 
                    'green': vulnerabilityIndex < 65}">{{ vulnerabilityIndex }}/100</span>
                  </div>
                  <div class="bottom">
                    Need reviews
                  </div>
                </div>
                <div class="metric" style="display: none;" >
                  <div class="top">
                    Warning
                  </div>
                  <div class="mid">
                    LO
                  </div>
                  <div class="bottom">
                    Base level warning
                  </div>
                </div>
              </div>
            </div>
            <div class="word-container row no-margin">
              <div class="word-count" style="width: 100%;" *ngIf="false" >
                <div class="title">
                  Word Count
                </div>

                <div class="metric-row" *ngFor="let data of wordDataCount | slice:0:9">
                  <div class="key">
                    {{ data.text }}
                  </div>
                  <div class="value">
                    {{ data.weight }}
                  </div>
                </div>

              </div>
            </div>

            <div class="comments" >
              <!-- <div class="title">
                COMMENTS
              </div>
              <div class="list" *ngIf="comments.length>0 ">
                <div class="comment my-4" *ngFor="let comment of comments">
                  <div class="row m-0" style="align-items: center;margin-bottom: 5px !important" >
                    <div *ngIf="comment.user">
                      <div  (click)="scrollTo(comment._id)" class="image mr-2" [title]="comment.user.first_name + ' ' + comment.user.last_name" [ngClass]="'circle-' + comment.user.id%4" >
                        <span >
                          {{( comment.user.first_name != undefined
                          && comment.user.first_name != null
                          && comment.user.first_name != '')
                          ? comment.user.first_name.charAt(0) : ''}}
  
                          {{(comment.user.last_name != undefined
                          && comment.user.last_name != null
                          && comment.user.last_name != '')
                          ? comment.user.last_name.charAt(0) : ''}}
                        </span>
                      </div>
                    </div>
                    
                    <div (click)="scrollTo(comment._id)" class="name" *ngIf="comment.user">
                      {{ comment.user.first_name}} {{ comment.user.last_name}}
                    </div>
                    <div class="likes">
                      <div class="dropup" *ngIf="comment.likes.length>0" >
                        <a class="" href="javascript:void(0)">
                          <span class="count">{{comment.likes.length}}</span>
                        </a>
                        <div class="dropup-content">
                          <a *ngFor="let user of comment.likes" href="javascript:void(0)">{{user.username}}</a>
                        </div>
                      </div>
                      <a href="javascript:void(0)" (click)="onClickLikeComment(comment)" >
                        <i [ngClass]="{'liked': comment.isLike}" class="fa fa-check" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div (click)="scrollTo(comment._id)" class="sentence m-0">
                    {{ comment.comment }}
                  </div>
                </div>

              </div> -->
              <div>
                <div class="title">
                  <div>
                    COMMENTS
                  </div>
                  <a href="script:void(0)" data-toggle="modal" data-target="#commentModal">add new comment +</a>
                </div>
              </div>
              
              <div class="list" *ngIf="comments.length>0 ">
                <div class="new-comment my-4" *ngFor="let comment of comments">
                  <div class="comment-content">
                    <div class="top" (click)="scrollTo(comment._id)">
                      <span>{{comment.comment}}</span>
                    </div>
                    <div class="bottom">
                      <div class="left-side">
                        <span>Comment by {{comment?.user?.first_name}} {{comment?.user?.last_name}}</span>
                      </div>
                      <div class="right-side">
                        <span>{{comment.created | date : 'h:mm'}} {{comment.created | date : 'dd/MM/yyyy'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list text-muted text-center" *ngIf="comments.length==0">
                No Comments.
              </div>

               <!-- activity log -->
               <div>
                <div class="title">
                  <div>
                    ACTIVITY LOG
                  </div>
                </div>
              </div>
              <div class="list" *ngIf="finalData && finalData.qualityscoreups.length>0 ">
                <div class="activity my-2" *ngFor="let comment of finalData.qualityscoreups">
                  <div class="activity-content">
                    <div class="bottom">
                      <div class="left-side">
                        <span>Old Score : {{comment.oldquality_score}}, Updated Score : {{comment.newquality_score}}</span>
                      </div>
                      <div class="right-side">
                        <span>{{comment.created | date : 'h:mm'}} {{comment.created | date : 'dd/MM/yyyy'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list text-muted text-center" *ngIf="finalData && finalData.qualityscoreups.length==0">
                No Activity Logged.
              </div>
            </div>

            </div>
        </div>
      </div>
    </div>
    <div class="modal" id="addComment" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ADD NEW COMMENT</h5>
          </div>
          <div class="modal-body">
            <!-- <div class="attribute">
              <div class="key">
                Choose Timestamp
              </div>
              <div class="value">
                <input class='form-field' type='text' placeholder="Timestamp" name="username" value="12:05:25">
              </div>
            </div> -->

            <div class="attribute">
              <div class="key">
                Enter Comment
              </div>
              <div class="value">
                <textarea class="form-field customtextarea" rows="3"
                  placeholder="Please write your comment here" [(ngModel)]="commentText"></textarea>
              </div>
            </div>
            <div class="disclaimer">
              A Notification will be sent to Team Leader and the Agent
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn closebtn" data-dismiss="modal">Close</button>
            <button type="button" class="btn save" (click)="postComment()">Post Comment</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#callModal">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="callModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body model-2">
        <div class="buttons">
          <span><i class="fa fa-caret-left" aria-hidden="true"></i>
          </span>
          <span class="text">Privious Call</span>
        </div>
        <div class="calls">
          <span (click)="closeModal();callId=1;getCallDetails()">nigative calls</span>
        </div>
        <div class="buttons">
          <span class="text">Next Call</span>
          <span><i class="fa fa-caret-right" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>

<!-- ChangeQuqlityScore Start-->
<!-- <div class="modal fade ChangeQuqlityScore" id="ChangeQuqlityScore">
 
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-body">
        <div id="overLay" class="overLay">
          <div class="data">
            <div class="text">
              <span>Are you sure you want to change the Quality Score?</span>
            </div>
            <div class="buttons">
              <button class="btn" data-dismiss="modal" (click)="UpdateQuality();overlayOff()">YES</button>
              <button class="btn" (click)="overlayOff()">NO</button>
            </div>
          </div>
        </div>
        <div class="section-2">
          <div class="row">
            <form name="userForm" #scorForm="ngForm"  novalidate>
              <div class="col-sm-12">
                <div class="form-group">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  </button>
                </div>
               
                <div class="row">
                  <div class="col-sm-12">
                    <div class="key-pair " >
                      <p>Change {{selectedOrder.checkname}} Quality Score</p>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <input type="text" disabled class="form-control" name="newscore" [(ngModel)]="selectedOrder.checkname" #newscore="ngModel" autocomplete="off"
                       required placeholder="0.00">
                    </div>
                  </div>
                  <div class="col-sm-4 text-center">
                    <div class="form-group">
                        <input type="text" disabled class="form-control" name="oldscore" [(ngModel)]="UpdateQualityScore.oldquality_score" #oldscore="ngModel" autocomplete="off"
                       required placeholder="{{selectedOrder.score < 0.45 ? 'Fail' : '' || selectedOrder.score > 0.45 && selectedOrder.score < 0.75 ? 'Warning' : ''  || selectedOrder.score > 0.75 ? 'Pass' : ''}}">
                      <label class="lable">OLD SCORE</label>
                    </div>
                  </div>
                  <div class="col-sm-4 text-center">
                    <div class="form-group">
                       
                       <ng-select type="text" bindLabel="name" bindValue="name"  class="form-control ng-select " [(ngModel)]="UpdateQualityScore.newquality_score" name="check" #check="ngModel" placeholder="select check" [items]="['Pass', 'Warning', 'Fail']"  > 
                      </ng-select>
                      <label class="lable">NEW SCORE</label>
                    </div>
                  </div>
                  <div class="col-sm-12 text-center">
                    <div class="form-group">
                        <input type="text" class="form-control reason" name="reason" [(ngModel)]="UpdateQualityScore.reason" #reason="ngModel" autocomplete="off"
                       required placeholder="Enter reason for change">
                    </div>
                  </div>
                  <div class="col-sm-12 text-right">
                    <a class="Cancel" data-dismiss="modal" href="javascript:void(0)">Cancel</a>
                    <button (click)="overlayOn()" class="btn-o btn-pink"  type="submit">SUBMIT CHANGE</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- ChangeQuqlityScore End-->



<div class="right-side-menu" [class.show]="menuVisible">
  <div class=" ChangeQuqlityScore " id="ChangeQuqlityScore">
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-body">
          <div id="overLay" class="overLay">
            <div class="data">
              <div class="text">
                <span>Are you sure you want to change the Quality Score?</span>
              </div>
              <div class="buttons">
                <button class="btn" data-dismiss="modal" (click)="UpdateQuality();overlayOff()">YES</button>
                <button class="btn" (click)="overlayOff()">NO</button>
              </div>
            </div>
          </div>
          <div class="section-2">
            <div class="row">
              <form name="userForm" #scorForm="ngForm"  novalidate>
                <div class="col-sm-12">
                  <div class="form-group">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    </button>
                  </div>
                 
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="key-pair " >
                        <p>Change {{selectedOrder.checkname}} Quality Score</p>
                      </div>
                    </div>
                    <div class="col-sm-4 d-none">
                      <div class="form-group">
                        <input type="text" disabled class="form-control" name="newscore" [(ngModel)]="selectedOrder.checkname" #newscore="ngModel" autocomplete="off"
                         required placeholder="0.00">
                      </div>
                    </div>
                    <div class="col-sm-6 text-center">
                      <div class="form-group">
                          <input  type="text" disabled class="form-control" name="oldscore" [(ngModel)]="UpdateQualityScore.oldquality_score" #oldscore="ngModel" autocomplete="off"
                         required placeholder="{{selectedOrder.score < 0.45 ? 'Fail' : '' || selectedOrder.score > 0.45 && selectedOrder.score < 0.75 ? 'Warning' : ''  || selectedOrder.score > 0.75 ? 'Pass' : ''}}">
                        <label class="lable">OLD SCORE</label>
                      </div>
                    </div>
                    <div class="col-sm-6 text-center">
                      <div class="form-group">
                         <ng-select type="text" bindLabel="name" bindValue="name"  class="form-control ng-select " [(ngModel)]="UpdateQualityScore.newquality_score" name="check" #check="ngModel" placeholder="select check" [items]="['Pass', 'Warning', 'Fail']"  > 
                        </ng-select>
                        <label class="lable">NEW SCORE</label>
                      </div>
                    </div>
                    <div class="col-sm-12 text-center">
                      <div class="form-group">
                        <!-- <input type="text" class="form-control reason" name="reason" [(ngModel)]="UpdateQualityScore.reason" #reason="ngModel" autocomplete="off"
                        required placeholder="Enter reason for change"> -->
                        <textarea [(ngModel)]="UpdateQualityScore.reason" type="text" class="form-control reason" name="reason" #reason="ngModel" autocomplete="off" autocomplete="off" placeholder="Enter reason for change" rows="2" cxrAutoSize ></textarea>
                      </div>
                    </div>
                    <div class="col-sm-12 text-right">
                      <a class="Cancel" data-dismiss="modal" href="javascript:void(0)" (click)="overlayOff()">Cancel</a>
                      <button (click)="overlayOn()" class="btn-o btn-pink"  type="submit">SUBMIT CHANGE</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="commentesmodal">
  <!-- Modal -->
<div class="modal fade" id="commentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="comment-modal">
          <div class="title">
            <span>ADD NEW COMMENT</span>
          </div>
          <div class="text-center">
            <div class="form-group">
              <textarea [(ngModel)]="commentText" type="text" class="form-control reason" name="reason" #reason="ngModel" autocomplete="off" autocomplete="off" placeholder="Enter The Comments" rows="3"  ></textarea>
            </div>
          </div>
          <div class="buttons ">
            <button class="btn-o btn-pink" data-dismiss="modal" type="submit" (click)="postComment()">SUBMIT COMMENT</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>