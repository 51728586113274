import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const BASE_URL = environment.endpoint;
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getToken(): string {
    return localStorage.getItem('jwt_token');
  }
  public login(email , password) {
    const loginObject = {username : email , password : password};
    return this.httpClient.post(BASE_URL+'login',loginObject,{withCredentials:true});
  }

  public signup(payload) {
    return this.httpClient.post(BASE_URL + 'signup' , payload);
  }

  public PostSetPassword(payload) {
    return this.httpClient.post(BASE_URL + 'reset' , payload);
  }

  public profile(payload) {
    return this.httpClient.post(BASE_URL + 'profile' , payload);
  }

  public postManageauser(payload) {
    return this.httpClient.post(BASE_URL + 'manageuser' , payload);
  }

  public uploadImg(payload) {
    return this.httpClient.post(BASE_URL + 'imguploads' , payload);
  }

  public postOrganization(payload) {
    return this.httpClient.post(BASE_URL + 'organization' , payload);
  }
}
