import { Component, OnInit } from '@angular/core';
 import { EventService } from '../../services/event.service';
import { Constants } from '../../constants/constants';
import { LoadingService } from '../../services/loading.service';
import { UserDataService } from '../../services/user-data.service';

@Component({
  selector: 'cxr-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

  constructor(private event: EventService,
    private loadingService: LoadingService, public userService: UserDataService) { }

  currentUser:any = {};
  constants = Constants;

  ngOnInit(): void {
    this.currentUser = this.userService.getUserData();
    // console.log(this.currentUser)
  }

  onClickSetting(){
    this.loadingService.apiStart();
    this.event.sendMessage({
      type: Constants.SENT_APP_THEME
    });
    this.loadingService.apiStop();
  }

  logout(){
    this.loadingService.apiStart();
    this.loadingService.apiStop();
    this.userService.logout();
  }
}
