import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';

import { UserDataService } from '../services/user-data.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
      private authService : AuthenticationService,
      private router: Router,
      private userDataService: UserDataService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const currentUser = this.userDataService.getUserData();

    if(currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentUser.token}`
        }
      });
    }
    

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.router.navigate(['/']);
          this.userDataService.logout();
        }
        // this.router.navigate(['login']);
      }
    }));
  }
}