<div class="row no-margin top-space">
  <div class="main-content">

    <div class="welcome-section">
      <div class="row">
        <div class="col-sm-8">
          <div class="text">
            Welcome to CX Review. 
            <!-- You are viewing date for -->
            <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
            [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
            [owlDateTime]="daterange">
            <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
          </div>
        </div>
        <div class="col-sm-4 right-part d-none">
          <button [routerLink]="'/onboarding/createteam'" class="stnd-bg-btn">ADD NEW TEAM</button>
        </div>
      </div>
    </div>

    <div class="team-overview" *ngIf="false" >
      <div class="title">
        TEAM OVERVIEW
      </div>
      <!-- <div class="date-picker">
        <span style="margin-right: 10px"> Select Date Range</span>
        <input placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
          [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
          [owlDateTime]="daterange">
        <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
      </div> -->
      <div *ngIf="isOverviewLoading" class="main-content">
        <cxr-loading></cxr-loading>
      </div>

      <div *ngIf="!isOverviewLoading" >
        <cxr-overview [data]="cxrOverviewData"></cxr-overview>
      </div>
    </div>

    <div class="teams">
      <div class="title">
        ALL TEAMS
      </div>
      <div *ngIf="isTeamListLoading" class="main-content">
        <cxr-loading></cxr-loading>
      </div>
      <div *ngIf="!isTeamListLoading" class="">
        <div class="row">
          <div class="col-sm-3" [ngClass]="{' col-sm-4': manageTeam}" *ngFor="let team of teams" >
            <!-- <cxr-team-card [data]="team" (click)="openTeam(team.id)"></cxr-team-card> -->
            <cxr-team-card [data]="team" (getTeamList)="getTeamList()"></cxr-team-card>
          </div>
        </div>
        <div class="text-muted text-center" *ngIf="teams.length == 0">No teams found</div>
      </div>
    </div>

    <div class="team-overview" *ngIf="false" >
      <div class="metrics">
        <div class="metric-card" *ngFor="let overview of overviewData">
          <div class="top">
            <div class="metric-title">
              {{ overview.checkname | titlecase }}
            </div>
            <div class="icon green">
              <i class="fa fa-arrow-up"></i>
            </div>
          </div>

          <div class="bottom">
            <div class="attribute">
              <div class="value">
                {{ overview.pass }}
              </div>
              <div class="key">
                Pass
              </div>
            </div>
            <div class="attribute">
              <div class="value">
                {{ overview.fail }}
              </div>
              <div class="key">
                Fail
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>