import { Component, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
// import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'cxr-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnChanges {

  // Top Graph data
  multi = [];
  view: any[] = [1100, 240];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Calls';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = true;

  colorScheme = {
    // domain: ['#FDE04C', '#C0792F', '#58C16B', '#1D4DC0']
    domain: ['#c01c1c', '#c0791c', '#1cc069', '#1D4DC0']
  };

  @Input()
  data: any;
  projectId;

  constructor(
    private router: Router,
    // private dashBoardService: DashboardService,
    public commonService: CommonService,
    private route: ActivatedRoute,
  ) { }

  ngOnChanges(): void {

    this.projectId = this.route.snapshot.paramMap.get('id');
    let data = this.data;
    if(!('calls_trend' in data)) {
      data['calls_trend'] = [];
    }
    this.multi = [];
    // const callsOverview = data.calls_overview;
    // this.callStatsData.totalcallstoday = callsOverview.calls_taken;
    // this.callStatsData.totalpasscallstoday = callsOverview.calls_passed;
    // this.callStatsData.totalfailedcallstoday = callsOverview.calls_failed;
    // this.callStatsData.averagecallqualityscoretoday = callsOverview.quality_score;
    data.calls_trend.forEach(t => {

      const tempObject = {
        name: this.commonService.getFormatedDateDDMMYYYY(t.timestamp),
        series: [
          {
            name: 'Calls Failed',
            value: t.calls_failed,
            status: -1
          },
          {
            name: 'Calls Ok',
            value: t.calls_ok,
            status: 0
          }, {
            name: 'Calls Passed',
            value: t.calls_passed,
            status: 1
          }
        ]
      }
      this.multi.push(tempObject);
    });
    this.multi = [...this.multi];
  }

  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',

      // intersect: false
    },
    legend: {
      display: false
    },
    hover: { mode: null },
    cornerRadius: 0.4,
    barRoundness: 0.3,

    label: 'HET THERE'
  };
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData = [
    {
      data: [800, 300, 1340, 960, 750, 988, 1340, 834, 780, 877, 1480,
        1235, 777, 1475, 1209, 1306, 790, 1000, 872, 900, 1300
      ], label: 'Approved', type: 'line',
      fill: false,
      borderColor: "rgb(83,191,168)",
      backgroundColor: "rgb(83,191,168)"
    },
    {
      data: [600, 700, 1500, 860, 950, 1288, 1200, 654, 890, 777, 1300,
        700, 1088, 1000, 754, 590, 977, 1080, 1420, 789, 765
      ],
      label: 'Calls Abandoned vs Total Calls', stack: 'a',
      backgroundColor: "rgba(28,28,28,1)",
      //  cornerRadius: 20,


    },

  ];
  formatXaxis(v) {
    return v;
  }
  public barChartLabels: string[] = ['09:00 AM', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '06:00 PM'
  ];
  public barChartDataProject = [
    {
      data: [800, 300, 1340, 960, 750, 988, 1340, 834, 780, 877, 1480,
        1235, 777, 1475, 1209, 1306, 790, 1000, 872, 900, 1300
      ], label: 'Approved', type: 'line',
      fill: false,
      borderColor: "rgb(83,191,168)",
      backgroundColor: "rgb(83,191,168)"
    },
    {
      data: [777, 290, 1280, 980, 650, 898, 1240, 734, 840, 847, 1510,
        1345, 812, 1375, 1309, 1206, 760, 960, 792, 870, 1270
      ], label: 'Approved', type: 'line',
      fill: false,
      borderColor: "rgb(226,200,67)",
      backgroundColor: "rgb(226,200,67)"
    },

    {
      data: [620, 190, 1080, 780, 450, 698, 1040, 534, 640, 647, 1310,
        1145, 612, 1175, 1109, 1006, 560, 760, 592, 670, 1070
      ], label: 'Approved', type: 'line',
      fill: false,
      borderColor: "rgb(114,32,35)",
      backgroundColor: "rgb(114,32,35)"
    },
    {
      data: [600, 700, 1500, 860, 950, 1288, 1200, 654, 890, 777, 1300,
        700, 1088, 1000, 754, 590, 977, 1080, 1420, 789, 765
      ],
      label: 'Calls Abandoned vs Total Calls', stack: 'a',
      backgroundColor: "rgba(28,28,28,1)",
      //  cornerRadius: 20,


    },

  ];

  onGraphCallsSelect(event) {
    let params:any = {
      date: event.series,
      status: event.status
    };

    if (this.projectId != null && this.projectId != undefined && this.projectId != '') {
      params['projectId'] = this.projectId;
    }

    this.router.navigate(['/calls'], { queryParams: params});
  }

}
