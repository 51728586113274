<div class="row no-margin top-space">
  <div class="main-content">

    <div class="welcome-section">
      <div class="row">
        <div class="col-sm-8">
          <div class="text">
            Welcome to CX Review.
             <!-- You are viewing date for -->
            <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
            [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
            [owlDateTime]="daterange">
            <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
          </div>
        </div>
        <div class="col-sm-4 right-part">
          <a class="anchor-btn" [routerLink]="'/onboarding/campaigndetails/'+campaignId" >
            See Campaign Configuration
          </a>
        </div>
      </div>
    </div>

    <div class="row no-margin">

      <!-- Left side start -->
      <div class="list-container slim-scrollbar">

        <div class="row align-center">
          <div class="col-8">
            <div class="back-link" (click)="back();">
              <div class="icon">
                <i class="fa fa-arrow-left"></i>
              </div>
              <div class="message">
                Back to all campaigns
              </div>
            </div>
            <div class="title" *ngIf="campaing && campaignId">
              <!-- {{overviewData.name}} -->
              {{campaing}}
            </div>
          </div>
          <div class="col-4 text-right">
            <div class="team-name-stamp">
              <div class="team-name-label">
                TEAM NAME
              </div>
              <div class="team-name-value" *ngIf="teams && campaignId">
                <!-- {{team.name}} -->
                {{teams}}
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="top-block">
            <div class="row">
              <div class="col-sm-1">
                <div class="box good">
                  <!-- <img src="../../../assets/images/Group 299.svg" alt=""> -->
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="number qcsScore" [ngClass]="{'red': (totalScore / campaignData.length) * 100 < 25, 
                      'orange': (totalScore / campaignData.length) * 100 > 25 && (totalScore / campaignData.length) * 100 < 37,'green' : (totalScore / campaignData.length) * 100 > 37}">
                        <span>{{(totalScore /campaignData.length) * 100 | number : '1.2-2'}}</span>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <span class="top-text" style="font-size: 8px;">QCS SCORE</span>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box bad">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="top-text">FAILED CALLS</span>
                    </div>
                    <div class="col-sm-4">
                      <div class="number">
                        <span>{{faildCalls}}</span>
                      </div>
                    </div>
                    <div class="col-sm-8 d-none">
                      <div class="right-text">
                        <span>+ 10% above av.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box average">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="top-text">WARNING CALLS</span>
                    </div>
                    <div class="col-sm-4">
                      <div class="number second">
                        <span>{{worningCalls}}</span>
                      </div>
                    </div>
                    <div class="col-sm-8 d-none">
                      <div class="right-text">
                        <span>+ 10% above av.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5 d-none">
                <div class="box no-border">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-8">
                          <span class="top-text">TOTAL NON-COMPLIANT CALLS</span>
                        </div>
                        <div class="col-sm-4">
                          <div class="top-dropdown">
                            <div class="dropdown">
                              <select [(ngModel)]="topChangeFilterModel"  (change)="callSectionTopChange($event.target.value)" class="form-select btn-top" aria-label="Default select example" >
                                <!-- <option value="" class="dropdown-item" >Opening</option> -->
                                <option [value]="item.name" class="dropdown-item" *ngFor="let item of callSection">{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2" *ngFor="let item of nonCompliant;let i = index">
                      <div class="block" *ngIf="i < 6">
                        <div class="first-no">
                          <span>{{item.calls_failed}}</span>
                        </div>
                        <div class="second-text">
                          <span>{{item.checkname}}</span>
                        </div>
                      </div>  
                    </div>
                    <div class="col-sm-12" *ngIf="nonCompliant.length <= 0" >
                      <div class="block" >
                        <div class="first-no">
                          <span>No Checks Found</span>
                        </div>
                      </div> 
                    </div>
                    <!-- <div class="col-sm-2">
                      <div class="block">
                        <div class="first-no">
                          <span>57</span>
                        </div>
                        <div class="second-text">
                          <span>PI</span>
                        </div>
                      </div>  
                    </div>
                    <div class="col-sm-2">
                      <div class="block">
                        <div class="first-no">
                          <span>66</span>
                        </div>
                        <div class="second-text">
                          <span>MIRANDA</span>
                        </div>
                      </div>  
                    </div>
                    <div class="col-sm-2">
                      <div class="block">
                        <div class="first-no">
                          <span>59</span>
                        </div>
                        <div class="second-text">
                          <span>DISCLOSURE</span>
                        </div>
                      </div>  
                    </div>
                    <div class="col-sm-2">
                      <div class="block">
                        <div class="first-no">
                          <span>104</span>
                        </div>
                        <div class="second-text">
                          <span>Right_No</span>
                        </div>
                      </div>  
                    </div>
                    <div class="col-sm-2">
                      <div class="block">
                        <div class="first-no">
                          <span>150</span>
                        </div>
                        <div class="second-text">
                          <span>RPC</span>
                        </div>
                      </div>  
                    </div>
                    <div class="col-sm-2">
                      <div class="block">
                        <div class="first-no">
                          <span>0</span>
                        </div>
                        <div class="second-text">
                          <span>{{topChangeFilterModel}}</span>
                        </div>
                      </div>  
                    </div> -->
                  </div>                  
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box no-border">
                  <div class="last-bax">
                    <div class="top-text">
                      <span><i class="fa fa-user" aria-hidden="true"></i>{{total_agents.size || '0'}} agents
                      </span>
                    </div>
                    <div class="top-text text-bottom" *ngIf="campaignData">
                      <span><i class="fa fa-phone" aria-hidden="true"></i>{{campaignData.length}} calls taken 
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row">
            <div class="list col-sm-9">
              <div class="list-header">
                <div class="header-name col-md" >
                  Call Id
                </div>
                <div class="header-name col-lg" >
                  Agent Name
                </div>
                <div class="header-name col-lg" >
                  Call Duration
                </div>
                <div class="header-name col-md" >
                  Quality
                </div>
                <!-- <div class="header-name col-lg">
                  Vulnerability
                </div> -->
                <div class="header-name col-lg">
                  Sentiment
                </div>
                <!-- <div class="header-name col-lg">
                  Verification
                </div> -->
                <!-- <div class="header-name col-lg">
                  Agent V.I
                </div> -->
                <!-- <div class="header-name col-lg">
                  Customer V.I
                </div> -->
                <div class="header-name col-md">
                </div>
              </div>
              <div class="list-row" routerLink="/call/{{data.id}}" *ngFor="let data of campaignData">
                <div class="attribute col-md">
                  {{data.id}}
                </div>
                <div class="attribute col-lg">
                  {{data.first_name}} {{data.last_name}}
                </div>
                <div class="attribute col-lg">
                  {{ getDuration(data.duration) }}
                </div>
                <div class="attribute col-md" [ngClass]="{'red': (data.quality_passed / data.total_quality) * 100  < 45, 
                'orange': (data.quality_passed / data.total_quality) * 100 > 45 && (data.quality_passed / data.total_quality) * 100 < 75,'green' : (data.quality_passed / data.total_quality) * 100 > 75}">
                  {{ (data.quality_passed / data.total_quality) * 100 | number : '1.2-2' }}%
                </div>
                <!-- <div class="attribute col-lg" [ngClass]="{'red': data.vulnerability_index > 85,
                    'orange': data.vulnerability_index >= 65 && data.vulnerability_index <= 85, 
                    'green': data.vulnerability_index < 65}">
                  {{data.vulnerability_index}}
                </div> -->
                <!-- <div class="attribute col-md" [ngClass]="{'green': data.sentiment_score > 85,
                    'orange': data.sentiment_score >= 65 && data.sentiment_score <= 85, 
                    'red': data.sentiment_score < 65}" > -->
                    <div class="attribute col-md">
                  <!-- {{data.sentiment_score | number : '1.1-1'}}/ <span style="color: #909090;" >100</span> -->
                  <span *ngIf="data.sentiment_score >= -0.2 && data.sentiment_score <= 0.2">Neutral</span>
                  <span *ngIf="data.sentiment_score > 0.2 ">Positive</span>
                  <span *ngIf="data.sentiment_score < -0.2">Negative</span>
                </div>
                <!-- <div class="attribute col-md" [ngClass]="{'green' : data.verified, 'red' : !data.verified}" >
                  {{data.verified ? 'Verified': 'Not Verified'}}
                </div> -->
                <!-- <div class="attribute col-md" [ngClass]="{'green': data.agent_vi > 0.37,
                    'orange': data.agent_vi >= 0.25 && data.agent_vi <= 0.37, 
                    'red': data.agent_vi < 0.25}" >
                  {{data.agent_vi * 100 | number : '1.2-2'}}%
                </div> -->
                <!-- <div class="attribute col-md" [ngClass]="{'green': data.customer_vi > 0.37,
                    'orange': data.customer_vi >= 0.25 && data.customer_vi <= 0.37, 
                    'red': data.customer_vi < 0.25}">
                  {{data.customer_vi * 100 | number : '1.2-2'}}%
                </div> -->
                <div class="attribute col-md link" (click)="openCall(data.id)">
                  View
                </div>
              </div>
            </div>
    
    
            <!-- Right side start -->
            <div class="col-sm-3">
              <div style="margin-top: 43px;">
                <div class="row">
                  <div class="title col-sm-9">
                    FILTER
                  </div>
                  <a class="title col-sm-3" style="text-align: right; cursor: pointer; color: #c01c1c; font-size: 11px;white-space: nowrap;" (click)="resetFilter()">
                    RESET <i class="fa fa-refresh"></i>
                  </a>
                </div>
    
                  <div class="filters">
                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'Opening'}" (click)="selectedFilter='Opening';getQualityCheck()">
                      <img class="selected" src="/assets/images/Selected.svg">
                      <img class="unselected" src="/assets/images/Unselected.svg">
                      <span>Opening</span>
                      <span class="faselected"><i class=" fa fa-angle-down"></i></span>
                      <span class="faunselected"><i class="fa fa-angle-up"></i></span>
                      <ul *ngFor="let item of qualityCheck;let i = index;">
                        <li [ngClass]="{'list-active': openingFilterModel[i] == item.checkname}" (click)="openingFilterModel.splice(i,1,item.checkname);onCallSectionChange(item.checkname);">
                          <img class="liselected" src="/assets/images/Selected.svg">
                          <img class="liunselected" src="/assets/images/Unselected.svg">
                          <span>{{item.checkname}}</span>
                        </li>
                      </ul>
                    </a>


                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'Body'}" (click)="selectedFilter='Body';getQualityCheck()">
                      <img class="selected" src="/assets/images/Selected.svg">
                      <img class="unselected" src="/assets/images/Unselected.svg">
                      <span>Body</span>
                      <span class="faselected"><i class=" fa fa-angle-down"></i></span>
                      <span class="faunselected"><i class="fa fa-angle-up"></i></span>
                      <ul *ngFor="let item of qualityCheck;let i = index;">
                        <li [ngClass]="{'list-active': bodyFilterModel[i] == item.checkname}" (click)="bodyFilterModel.splice(i,1,item.checkname);onCallSectionChange(item.checkname);">
                          <img class="liselected" src="/assets/images/Selected.svg">
                          <img class="liunselected" src="/assets/images/Unselected.svg">
                          <span>{{item.checkname}}</span>
                        </li>
                      </ul>
                    </a>

                    <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'Closing'}" (click)="selectedFilter='Closing';getQualityCheck()">
                      <!-- <img class="selected" src="/assets/images/Selected.svg"> -->
                      <img class="selected" src="/assets/images/Unselected.svg">
                      <img class="unselected" src="/assets/images/Unselected.svg">
                      <span>Closing</span>
                      <span class="faselected"><i class=" fa fa-angle-down"></i></span>
                      <span class="faunselected"><i class="fa fa-angle-up"></i></span>
                      <ul *ngFor="let item of qualityCheck;let i = index;">
                        <li [ngClass]="{'list-active': closingFilterModel[i] == item.checkname}" (click)="closingFilterModel.splice(i,1,item.checkname);onCallSectionChange(item.checkname);">
                          <img class="liselected" src="/assets/images/Selected.svg">
                          <img class="liunselected" src="/assets/images/Unselected.svg">
                          <span>{{item.checkname}}</span>
                        </li>
                      </ul>
                    </a>

                    <!-- <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'Entire Call'}" (click)="selectedFilter='Entire Call';getQualityCheck()">
                      <img class="selected" src="/assets/images/Unselected.svg">
                      <img class="unselected" src="/assets/images/Unselected.svg">
                      <span>Entire Call</span>
                      <span class="faselected"><i class=" fa fa-angle-down"></i></span>
                      <span class="faunselected"><i class="fa fa-angle-up"></i></span>
                      <ul *ngFor="let item of qualityCheck;">
                        <li [ngClass]="{'list-active': projectFilterModel == item.checkname}" (click)="projectFilterModel=item.checkname;onCallSectionChange(item.checkname);">
                          <img class="liselected" src="/assets/images/Selected.svg">
                          <img class="liunselected" src="/assets/images/Unselected.svg">
                          <span>{{item.checkname}}</span>
                        </li>
                      </ul>
                    </a> -->

                    <a href="javascript:void(0)" class="filter d-none" [ngClass]="{'active': selectedFilter == 'Vulnerability'}" (click)="selectedFilter='Vulnerability';getQualityCheck()">
                      <!-- <img class="selected" src="/assets/images/Selected.svg"> -->
                      <img class="selected" src="/assets/images/Unselected.svg">
                      <img class="unselected" src="/assets/images/Unselected.svg">
                      <span>Customer VI</span>
                      <span class="faselected"><i class=" fa fa-angle-down"></i></span>
                      <span class="faunselected"><i class="fa fa-angle-up"></i></span>
                      <ul *ngFor="let item of qualityCheck;let i = index;">
                        <li [ngClass]="{'list-active': customerFilterModel[i] == item.checkname}" (click)="customerFilterModel.splice(i,1,item.checkname);onCallSectionChange(item.checkname);">
                          <img class="liselected" src="/assets/images/Selected.svg">
                          <img class="liunselected" src="/assets/images/Unselected.svg">
                          <span>{{item.checkname}}</span>
                        </li>
                      </ul>
                    </a>

                    <a href="javascript:void(0)" class="filter d-none" [ngClass]="{'active': selectedFilter == 'Vulnerability Support'}" (click)="selectedFilter='Vulnerability Support';getQualityCheck()">
                      <!-- <img class="selected" src="/assets/images/Selected.svg"> -->
                      <img class="selected" src="/assets/images/Unselected.svg">
                      <img class="unselected" src="/assets/images/Unselected.svg">
                      <span>System VI</span>
                      <span class="faselected"><i class=" fa fa-angle-down"></i></span>
                      <span class="faunselected"><i class="fa fa-angle-up"></i></span>
                      <ul *ngFor="let item of qualityCheck;let i = index;">
                        <li [ngClass]="{'list-active': agentFilterModel[i] == item.checkname}" (click)="agentFilterModel.splice(i,1,item.checkname);onCallSectionChange(item.checkname);">
                          <img class="liselected" src="/assets/images/Selected.svg">
                          <img class="liunselected" src="/assets/images/Unselected.svg">
                          <span>{{item.checkname}}</span>
                        </li>
                      </ul>
                    </a>
                      <!-- <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'good-quality'}" (click)="selectedFilter='good-quality';refreshList();" >
                          <img class="selected" src="/assets/images/Selected.svg">
                          <img class="unselected" src="/assets/images/Unselected.svg">
                          <span>Good Quality score</span>
                      </a>
    
                      <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'bad-quality'}" (click)="selectedFilter='bad-quality';refreshList();" >
                          <img class="selected" src="/assets/images/Selected.svg">
                          <img class="unselected" src="/assets/images/Unselected.svg">
                          <span>Bad Quality score</span>
                      </a>
    
                      <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'vi-customer'}" (click)="selectedFilter='vi-customer';refreshList();">
                          <img class="selected" src="/assets/images/Selected.svg">
                          <img class="unselected" src="/assets/images/Unselected.svg">
                          <span>High Customer Vulnerability</span>
                      </a>
    
                      <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'vi-agent'}" (click)="selectedFilter='vi-agent';refreshList();">
                          <img class="selected" src="/assets/images/Selected.svg">
                          <img class="unselected" src="/assets/images/Unselected.svg">
                          <span>High Agent Vulnerability</span>
                      </a>
    
                      <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'negative-sentiment'}" (click)="selectedFilter='negative-sentiment';refreshList();" >
                          <img class="selected" src="/assets/images/Selected.svg">
                          <img class="unselected" src="/assets/images/Unselected.svg">
                          <span>Negative sentiment calls</span>
                      </a>
    
                      <a href="javascript:void(0)" class="filter" [ngClass]="{'active': selectedFilter == 'positive-sentiment'}" (click)="selectedFilter='positive-sentiment';refreshList();" >
                          <img class="selected" src="/assets/images/Selected.svg">
                          <img class="unselected" src="/assets/images/Unselected.svg">
                          <span>Positive sentiment calls</span>
                      </a>
    
                      <a href="javascript:void(0)" class="filter"  [ngClass]="{'active': selectedFilter == 'unverified'}" (click)="selectedFilter='unverified';refreshList();">
                          <img class="selected" src="/assets/images/Selected.svg">
                          <img class="unselected" src="/assets/images/Unselected.svg">
                          <span>Unverified Calls</span>
                      </a>
                  </div> -->
              </div> 
              <div class="dropdowns d-none">
                <div class="dropdown">
                  <select [(ngModel)]="checksFilterModel"  (change)="onChecksChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
                    <option hidden value="" class="dropdown-item" >Complance status</option>
                    <option value="RPC" class="dropdown-item" >RPC</option>
                    <option value="PI" class="dropdown-item">PI</option>
                    <option value="DISCLOSURE" class="dropdown-item">DISCLOSURE</option>
                    <option value="Right_No" class="dropdown-item">Right_No</option>
                    <option value="MIRANDA" class="dropdown-item">MIRANDA</option>
                    <option value="MIRANDA" class="dropdown-item">SECOND_VERIFICATION</option>
                    <option value="MIRANDA" class="dropdown-item">CA_DISCLOSURE</option>
                    <option value="MIRANDA" class="dropdown-item">CREDIT_DISCLOSURE</option>
                    <option value="MIRANDA" class="dropdown-item">BALANCE</option>
                    <option value="MIRANDA" class="dropdown-item">YES_PAID</option>
                    <option value="MIRANDA" class="dropdown-item">SPECIAL_HANDLING</option>
                    <option value="MIRANDA" class="dropdown-item">PERMISSION</option>
                    <option value="MIRANDA" class="dropdown-item">FINANCIAL</option>
                    <option value="MIRANDA" class="dropdown-item">LIFE_EVENT</option>
                    <option value="MIRANDA" class="dropdown-item">INCLINATION</option>
                    <option value="MIRANDA" class="dropdown-item">HEALTH</option>
                    <option value="MIRANDA" class="dropdown-item">CAPACITY</option>
                    <option value="MIRANDA" class="dropdown-item">SYSTEM</option>
                    <option value="MIRANDA" class="dropdown-item">PRACTICES</option>
                    <option value="MIRANDA" class="dropdown-item">PRODUCT</option>
                    <option value="MIRANDA" class="dropdown-item">SERVICE</option>
                    <option value="MIRANDA" class="dropdown-item">ACCEPTABLE_LAN</option>
                  </select>
                </div>
                <!-- <div class="dropdown" >
                  <select [(ngModel)]="projectFilterModel"  (change)="onProjectChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example">
                    <option value="" class="dropdown-item" >Select</option>
                    <option [value]="item.id" class="dropdown-item" *ngFor="let item of projects;">{{item.name}}</option>
                  </select>
                </div>
                <div class="dropdown">
                  <select [(ngModel)]="campaignsFilterModel"  (change)="onCampaignChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example" >
                    <option value="" class="dropdown-item" >Select</option>
                    <option [value]="item.id" class="dropdown-item" *ngFor="let item of campaing">{{item.name}}</option>
                  </select>
                </div> -->
                <div class="dropdown">
                  <select [(ngModel)]="callSectionFilterModel"  (change)="onCallSectionChange($event.target.value)" class="form-select btn btn-secondary" aria-label="Default select example" >
                    <option value="" class="dropdown-item" >Select</option>
                    <option [value]="item.name" class="dropdown-item" *ngFor="let item of callSection">{{item.name}}</option>
                  </select>
                </div>
                <!-- <div class="dropdown">
                  <select class="form-select btn btn-secondary" aria-label="Default select example">
                    <option value="1" class="dropdown-item" >Call importance: All</option>
                    <option value="2" class="dropdown-item">Call importance: All</option>
                    <option value="3" class="dropdown-item">Call importance: All</option>
                    <option value="4" class="dropdown-item">Call importance: All</option>
                  </select>
                </div> -->
                <!-- <div class="dropdown">
                  <select class="form-select btn btn-secondary" aria-label="Default select example">
                    <option value="1" class="dropdown-item" >Call importance: high, medium</option>
                    <option value="2" class="dropdown-item">Call importance: high, medium</option>
                    <option value="3" class="dropdown-item">Call importance: high, medium</option>
                    <option value="4" class="dropdown-item">Call importance: high, medium</option>
                  </select>
                </div> -->
                <!-- <div class="dropdown">
                  <select class="form-select btn btn-secondary" aria-label="Default select example">
                    <option value="1" class="dropdown-item" >Call importance: All</option>
                    <option value="2" class="dropdown-item">Call importance: All</option>
                    <option value="3" class="dropdown-item">Call importance: All</option>
                    <option value="4" class="dropdown-item">Call importance: All</option>
                  </select>
                </div> -->
                <!-- <div class="dropdown">
                  <select class="form-select btn btn-secondary" aria-label="Default select example">
                    <option value="1" class="dropdown-item" >Call importance: All</option>
                    <option value="2" class="dropdown-item">Call importance: All</option>
                    <option value="3" class="dropdown-item">Call importance: All</option>
                    <option value="4" class="dropdown-item">Call importance: All</option>
                  </select>
                </div> -->
                <!-- <div class="dropdown">
                  <button type="button" (click)="navigate()" class="btn btn1">SEND SELECTED CALLS TO QC</button>
                </div> -->
              </div>
            </div>
            <!-- Right side end -->
          </div>
        </div>
        

        <nav aria-label="Page navigation example" *ngIf="false" >
          <ul class="pagination justify-content-center custom-style">
            <li class="page-item"><a class="page-link active" href="javascript:void(0)">1</a></li>
            <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
            <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
          </ul>
        </nav>
      </div>
      <!-- Left side end -->

      <!-- Right side start -->
      <div class="right-container slim-scrollbar" style="display: none;">
        <div class="row">
          <div class="col-sm-9">

            <div class="graph-container" *ngIf="data.length > 0" >
              <div class="title">
                <div style="margin-bottom:5px">
                  QUALITY CHECKS
                </div>

                <div class="row">
                  <div class="col-sm-6" *ngFor="let quality of data">
                    <div class="quality-stamp good">
                      <div class="row">
                        <div class="col-6">
                          <div class="label">
                            LABEL
                          </div>
                          <div class="value">
                            {{quality.name}}
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="label">
                            SCORE
                          </div>
                          <div class="value">
                            {{quality.value}}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row no-margin">
                  <div class="metrics">
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.calls}}
                      </div>
                      <div class="graph-key">
                        Calls Taken
                      </div>
                    </div>
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.nps.toFixed(1)}}
                      </div>
                      <div class="graph-key">
                        NPS
                      </div>
                    </div>
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.csat.toFixed(1)}}
                      </div>
                      <div class="graph-key">
                        CSAT
                      </div>
                    </div>
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.ces.toFixed(1)}}
                      </div>
                      <div class="graph-key">
                        CES
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="date-picker" style="display: none;" >
                <input  placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate" [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)" [owlDateTime]="daterange">
                <owl-date-time  [pickerType]="'calendar'" #daterange></owl-date-time>
              </div>
            </div>

            <div *ngIf="false" class="graph-container">
              <div class="title">
                <div style="margin-bottom:5px">
                  CAMPAIGN PERFORMANCE
                </div>
                <div class="row no-margin">
                  <div class="graph">
                    <div *ngIf="qualityThreshold > 0" class="threshold-check" [ngStyle]="{'bottom': qualityThreshold + '%'}" >
                      <span class="value" >Threshold - {{qualityThreshold}}</span>
                      <span class="line" ></span>
                    </div>
                    <ngx-charts-bar-vertical [barPadding]="35" [scheme]="colorScheme"
                      [results]="data" [xAxis]="true" [yAxis]="true" [legend]="false"
                      [showXAxisLabel]="false" [showYAxisLabel]="false">
                    </ngx-charts-bar-vertical>
                  </div>
                </div>
                <div class="row no-margin">
                  <div class="metrics">
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.calls}}
                      </div>
                      <div class="graph-key">
                        Calls Taken
                      </div>
                    </div>
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.nps.toFixed(1)}}
                      </div>
                      <div class="graph-key">
                        NPS
                      </div>
                    </div>
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.csat.toFixed(1)}}
                      </div>
                      <div class="graph-key">
                        CSAT
                      </div>
                    </div>
                    <div class="graph-attribute">
                      <div class="graph-value">
                        {{overviewData.ces.toFixed(1)}}
                      </div>
                      <div class="graph-key">
                        CES
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="date-picker" style="display: none;" >
                <input  placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate" [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)" [owlDateTime]="daterange">
                <owl-date-time  [pickerType]="'calendar'" #daterange></owl-date-time>
              </div>
            </div>

            <div class="campaign-team">
              <div class="word-container row no-margin">
                <div class="word-cloud" style="width: 100%" >
                  <div class="title">
                    WORD CLOUD
                    <cxr-word-cloud *ngIf="wordData.length > 0" [data]="wordData" (onClick)="onClickWordCloud($event)" ></cxr-word-cloud>
                  </div>
                </div>

                <div *ngIf="false" class="word-count" style="width: 100%">
                  <div class="title">
                    WORD COUNT
                  </div>

                  <div class="metric-row" *ngFor="let data of wordDataCount">
                    <div class="key">
                      {{ data.text }}
                    </div>
                    <div class="value">
                      {{ data.weight }}
                    </div>
                  </div>
                </div>
                <div class="row" style="width: 100%" >
                  <div class="col-sm-6">
                    <div class="vi-stamp">
                      <div class="title">
                        AGENT V.I
                      </div>
                      <div class="score">
                        <span class="gained" [ngClass]="{'green': totalVI['agent'] > 85,
                'orange': totalVI['agent'] >= 65 && totalVI['agent'] <= 85, 
                'red': totalVI['agent'] < 65}" >{{(totalVI['agent'] | number) || 0}}</span>
                        <span class="total">/100</span>
                      </div>
                      <div class="note">
                        Needs Review
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="vi-stamp">
                      <div class="title">
                        CUSTOMER V.I
                      </div>
                      <div class="score">
                        <span class="gained" [ngClass]="{'green': totalVI['customer'] > 85,
                'orange': totalVI['customer'] >= 65 && totalVI['customer'] <= 85, 
                'red': totalVI['customer'] < 65}" >{{(totalVI['customer']  | number) || 0}}</span>
                        <span class="total">/100</span>
                      </div>
                      <div class="note">
                        Not Vulnerable
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <!-- Right side end -->

    </div>

  </div>
</div>