import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'cxr-loading',
  templateUrl: './loading.component.html',
  styleUrls: []
})
export class LoadingComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}
