import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Constants } from '../../constants/constants'
import { UserDataService } from '../../services/user-data.service'
declare var $: any;

@Component({
  selector: 'cxr-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss']
})
export class SideComponent implements OnInit {

  constructor(private router: Router,
    private commonService: CommonService,
    private userService: UserDataService
    ) {
  }

  currentUser:any = {};
  theme = 'white';
  constants = Constants;

  ngOnInit(): void {

    this.currentUser = this.userService.getUserData();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

        $('.navigations').removeClass('active-nav');

        this.setActiveClass(event.url);
      }
    });
    this.setActiveClass(this.router.url);
  }

  setActiveClass(path){
    if(path.includes('/dashboard')){
      $('#dashboard').addClass('active-nav');
    } else if(path.includes('/call')){
      $('#calls').addClass('active-nav');
    }else if(path.includes('/project')){
      $('#projects').addClass('active-nav');
    }else if(path.includes('/team')){
      $('#teams').addClass('active-nav');
    }else if(path.includes('/agent')){
      $('#agent').addClass('active-nav');
    }
    else if(path.includes('/admin')){
      $('#organisation').addClass('active-nav');
    }
    else if(path.includes('/campaign')){
      $('#campaigns').addClass('active-nav');
    }
    else if(path.includes('/vulnerabilityindex')){
      $('#vulnerabilityindex').addClass('active-nav');
    }
    else if(path.includes('/product-manager')){
      $('#productManager').addClass('active-nav');
    }
    else if(path.includes('/keywords')){
      $('#keywords').addClass('active-nav');
    }
  }
}
