import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/loading.service';
import { EventService } from '../../services/event.service';
import { Constants } from '../../constants/constants';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var $: any;
import * as shape from 'd3-shape';

import Sortable from 'sortablejs';
import { BackendService } from '../../services/backendservice.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'cxr-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
  

export class DashboardComponent implements OnInit {

  // Top Graph data
  multi = [];
  view: any[] = [900, 240];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Calls';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = true;

  colorScheme = {
    // domain: ['#FDE04C', '#C0792F', '#58C16B', '#1D4DC0']
    domain: ['#c01c1c', '#c0791c', '#1cc069', '#1D4DC0']
  };
  // Top Graph ends here

  sentimentDistributionColorScheme = {
    domain: ['#53BFA8']
  };

  isCallStatsLoading = true;
  isCallStatsLoadingMca = true;
  isProjectLoading = true;
  isCampaignLoading = true;

  curve = shape.curveCardinal;

  start_date:any;
  end_date:any;

  agentsFilter:any;
  campaignsFilter:any;

  constructor(
    private dashboardService: DashboardService,
    public commonService: CommonService,
    private router: Router,
    private loadingService: LoadingService,
    public event: EventService,
    private backendService: BackendService

  ) { }

  callStatsData = {
    "totalcallstoday": 0,
    "totalpasscallstoday": 0,
    "totalfailedcallstoday": 0,
    "totalworningcallstoday":0,
    "averagecallqualityscoretoday": null
  };
  scores = [];
  projects = [];
  campaigns = [];
  dateRange = [];
  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

  dateDisplay = false;
  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period"
  samplename = "NH Customer service";
  samplename1 = "CAL Customer service";
  samplename2 = "NH sales";
  samplename3 = "CAL sales";
  // Chart options start here

  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',

      // intersect: false
    },
    legend: {
      display: false
    },
    hover: { mode: null },
    cornerRadius: 0.4,
    barRoundness: 0.3,

    label: 'HET THERE'
  };
  public barChartType = 'bar';
  public barChartLegend = true;

  formatXaxis(v) {
    return v;
  }
  public barChartLabels: string[] = [];
  public barChartDataProject = [
    {
      data: [], label: 'NPS', type: 'line',
      fill: false,
      borderColor: "rgb(83,191,168)",
      backgroundColor: "rgb(83,191,168)"
    },
    {
      data: [], label: 'CSAT', type: 'line',
      fill: false,
      borderColor: "rgb(226,200,67)",
      backgroundColor: "rgb(226,200,67)"
    },

    {
      data: [], label: 'CES', type: 'line',
      fill: false,
      borderColor: "rgb(114,32,35)",
      backgroundColor: "rgb(114,32,35)"
    }
  ];

  defaultDashBoardWidgets = [{
    type: Constants.PERFORMANCE_WIDGET,
    name: 'Quality score widget',
    image: '/assets/images/graph-widget.jpg',
  },{
    type: Constants.PERFORMANCE_WIDGET_MCA,
    name: 'Rick score widget',
    image: '/assets/images/graph-widget.jpg',
  },
    {
    type: Constants.PROJECT_WIDGET,
    name: 'Project overview card',
    image: '/assets/images/project-widget.jpg'
  },{
    type: Constants.CAMPAIGN_WIDGET,
    name: 'Campaign overview card',
    image: '/assets/images/campaign widget.jpg'
  },{
    type: Constants.WORD_CLOUDE_WIDGET,
    name: 'Word cloud',
    image: '/assets/images/word-cloud-widget.png'
    }
  ];

  dashBoardWidgets:any = [];

  sortable;
  isDashboardEditable = false;
  constants = Constants;

  wordData: any = [];

  cxrTeamOverviewData:any = {};
  teams:any = [];
  isOverviewLoading = true;
  isTeamListLoading = true;

  agents:any = [];
  agentsLoading = true;

  currentUser:any = {};
  qualityCheck: any = [];
  filterArray = ['Opening', 'Body', 'Closing','QC'];
  campaignsFilterModel;
  campaignsFilterModelMca;
  projectFilterModel;
  projectFilterModelMca;
  closingFilterModel = '';
  openingFilterModel = '';
  mcaQcFilterModel = '';
  bodyFilterModel = '';
  selectedFilter;
  qualityCheckOpening = [];
  qualityCheckBody = [];
  qualityCheckCloseing = [];
  qualityCheckQC = [];
  allFilterCheck = '';
  allFilterCheckMca = '';
  emptyScreen = false;
  sentimentDistributionResults:any = [
    {
      "name": "Sentiment Analysis",
      "series": []
    }
  ];

  lineUpCalls:any = [];
  notifications:any = [];

  openCampaign(id) {
    this.router.navigateByUrl('/project/' + id);

  }
  // Chart options end here
  ngOnInit(): void {
    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];

    this.event.getMessage().subscribe(data => {
      if (data.type == Constants.SENT_DASHBOARD_EVENT) {
        this.dashBoardWidgets = data.data;
        this.loadingService.apiStart();
        this.commonService
        .updateDashboard({
          user_dashboard: JSON.stringify(this.dashBoardWidgets)
        })
        .toPromise()
        .then((data: any) => {
          this.loadingService.apiStop();
          this.loadData();
        })
        .catch((err) => {
          this.loadingService.apiStop();
        });
      }
    });
    this.getLoggedInUser();
  }

  getLoggedInUser() {
    
    // this.dashBoardWidgets = JSON.parse(JSON.stringify(this.defaultDashBoardWidgets));
    // this.loadData();

    this.commonService.getLoggedInUser()
      .toPromise()
      .then((data: any) => {
        this.currentUser = data.user;
        if (this.currentUser.user_dashboard != undefined && this.currentUser.user_dashboard != null && this.currentUser.user_dashboard != '') {
          this.dashBoardWidgets = JSON.parse(this.currentUser.user_dashboard);
        }else{
          this.dashBoardWidgets = JSON.parse(JSON.stringify(this.defaultDashBoardWidgets));
        }
        this.loadData();
      })
      .catch((err) => {
        console.error(err);
      })
  }

  loadData(){
    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];

    this.setDate('today', '24 Hours');
    this.toggleDate();

    this.getTeamList();
    this.getAgents();
    this.getWordCloud();
    this.getQualityScore();
    this.getQualityScoreMca();
    this.getSentimentDistribution();
    this.getSentimentScore();
    this.getActivityTracker();
    this.getLineUpCalls();
  }

  getOverview = () => {
    this.isOverviewLoading = true;
    this.commonService.getCallStats(this.start_date, this.end_date).toPromise().then((data: any) => {
      this.cxrTeamOverviewData = data;
      // console.log(this.cxrTeamOverviewData);
      this.isOverviewLoading = false;
    })
  }

  getTeamList() {

    this.isTeamListLoading = true;
    this.commonService.getTeamList(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {

        let widget:any = this.dashBoardWidgets.filter((w)=>{
          return w.type == Constants.TEAM_WIDGET
        })[0];

        if (widget != undefined && widget.teams != undefined && widget.teams.length > 0) {
          data['teams'] = data['teams'].filter((c)=>{
            return widget.teams.includes(c.id);
          });
          this.teams = data['teams'];
        }else{
          this.teams = data['teams'];
        }

        this.isTeamListLoading = false;
      })
      .catch((err) => {
        this.isTeamListLoading = false;
      });
  }

  getAgents() {
    this.agentsLoading = true;
    this.dashboardService.getAgents()
    .toPromise()
    .then((data : any) => {
        this.agentsLoading = false;
        
        let widget:any = this.dashBoardWidgets.filter((w)=>{
          return w.type == Constants.AGENT_WIDGET
        })[0];

        if (widget != undefined && widget.agents != undefined && widget.agents.length > 0) {
          data['agents'] = data['agents'].filter((c)=>{
            return widget.agents.includes(c.id);
          });
          this.agents = data['agents'];
        }else{
          this.agents = data['agents'];
        }

    })
    .catch((err)=> {
      this.agentsLoading = false;
    });
  }

  getWidgetSizeClass(widget){
    switch (widget.type) {
      case Constants.PROJECT_WIDGET:
        return ('col-sm-' + ((this.projects.length || 1) * 3));
      case Constants.CAMPAIGN_WIDGET:
        return ('col-sm-' + ((this.campaigns.length || 1) * 3));
      case Constants.TEAM_WIDGET:
        return ('col-sm-6');
      case Constants.AGENT_WIDGET:
        return ('col-sm-6');
      case Constants.WORD_CLOUDE_WIDGET:
        return 'col-sm-3';
      case Constants.SENTIMENT_SCORE_CALLS:
      case Constants.LINE_UP_OF_CALL_WIDGET:
      case Constants.ACTIVITY_TRACKER_WIDGET:
        return ('col-sm-6');
      default:
        return 'col-sm-12';
    }
  }

  getCampaignsCardSizeClass(type){
    switch (type) {
      case Constants.PROJECT_WIDGET:
        return ('col-sm-' + (12 / this.projects.length));
      case Constants.CAMPAIGN_WIDGET:
        return ('col-sm-' + (12 / this.campaigns.length));
      // case Constants.TEAM_WIDGET:
      //   return ('col-sm-' + (12 / this.teams.length));
      // case Constants.AGENT_WIDGET:
      //   return ('col-sm-' + (12 / this.agents.length));
      default:
        return 'col-sm-12';
    }
  }

  onClickEdit(){

    if (!this.sortable) {
      this.sortable = Sortable.create(document.getElementById('items'), {
        animation: 350,
        onEnd:  (/**Event*/evt)=> {
          this.dashBoardWidgets = this.widgetMove(this.dashBoardWidgets, evt.oldIndex, evt.newIndex);
        },
      });
      this.isDashboardEditable = !this.isDashboardEditable;
      return;
    }

    /*Saving dashboard if saving*/
    if (this.isDashboardEditable) {
      this.loadingService.apiStart();
      this.commonService
      .updateDashboard({
        user_dashboard: JSON.stringify(this.dashBoardWidgets)
      })
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
      })
      .catch((err) => {
        this.loadingService.apiStop();
      });
    }

    this.isDashboardEditable = this.sortable.option("disabled");
    this.sortable.option("disabled", !this.isDashboardEditable);
  }

  widgetMove(widgets, old_index, new_index) {
    if (new_index >= widgets.length) {
      var k = new_index - widgets.length + 1;
      while (k--) {
          widgets.push(undefined);
      }
    }
    widgets.splice(new_index, 0, widgets.splice(old_index, 1)[0]);
    return widgets;
  };

  onRemoveWidget(widget, i){
    this.event.sendMessage({
      type: Constants.OPEN_CONFIRMATION_OVERLAY,
      data: {
        heading: 'Remove widget?',
        inline: true,
        callback: ()=>{
          // this.dashBoardWidgets = this.dashBoardWidgets.filter((item)=>{
          //   return item.type != widget.type;
          // });
          this.dashBoardWidgets.splice(i, 1);
        }
      }
    });
  }

  toggleDate = () => {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }
  }

  getProjects = () => {
    this.isProjectLoading = true;
    this.dashboardService
      .getProjects(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.isProjectLoading = false;
        let widget:any = this.dashBoardWidgets.filter((w)=>{
          return w.type == Constants.PROJECT_WIDGET
        })[0];

        if (widget != undefined && widget.projects != undefined && widget.projects.length > 0) {
          data['projects'] = data['projects'].filter((p)=>{
            return widget.projects.includes(p.id);
          });
          this.projects = data['projects'];
          if (this.projects.length <= 0) {
            this.emptyScreen = true;
          }
        }else{
          this.projects = data['projects'];
          if (this.projects.length <= 0) {
            this.emptyScreen = true;
          }
        }

        this.projects = this.projects.splice(0, 4);
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });
  }

  getCampaigns = () => {
    this.isCampaignLoading = true;
    this.commonService
      .getCampaigns(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.isCampaignLoading = false;

        let widget:any = this.dashBoardWidgets.filter((w)=>{
          return w.type == Constants.CAMPAIGN_WIDGET
        })[0];

        if (widget != undefined && widget.campaigns != undefined && widget.campaigns.length > 0) {
          data['campaigns'] = data['campaigns'].filter((c)=>{
            return widget.campaigns.includes(c.id);
          });
          this.campaigns = data['campaigns'];
        }else{
          this.campaigns = data['campaigns'];
        }
        this.campaigns = this.campaigns.splice(0, 4);
        
      })
      .catch((err) => {
        this.isCampaignLoading = false;
      });
  }

  setDate = (dateSelected, dateSelectedDisplay) => {
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    this.toggleDate();
    this.getProjects();
    this.getCampaigns();
    this.getQualityCheck();
    this.getPastPerformance();
    // this.getAgents();
    // this.getTeams();
    // Refresh Data

    // this.isCallStatsLoading = true;
    // this.dashboardService
    //   .getCallStats("")
    //   .toPromise()
    //   .then((data: any) => {
    //     this.isCallStatsLoading = false;
    //     const callsOverview = data.calls_overview;
    //     this.callStatsData.totalcallstoday = callsOverview.calls_taken;
    //     this.callStatsData.totalpasscallstoday = callsOverview.calls_passed;
    //     this.callStatsData.totalfailedcallstoday = callsOverview.calls_failed;
    //     this.callStatsData.averagecallqualityscoretoday = callsOverview.quality_score;
    //     data.calls_trend.forEach(t => {
    //       const tempObject = {
    //         name: this.commonService.getFormatedDateDDMMYYYY(t.timestamp),
    //         series: [
    //           {
    //             name: 'Calls Failed',
    //             value: t.calls_failed,
    //             status: -1
    //           },
    //           {
    //             name: 'Calls Ok',
    //             value: t.calls_ok,
    //             status: 0
    //           }, {
    //             name: 'Calls Passed',
    //             value: t.calls_passed,
    //             status: 1
    //           }
    //         ]
    //       }
    //       this.multi.push(tempObject);
    //     });
    //     this.multi = [...this.multi];
    //   }).catch((err) => {
    //     this.isCallStatsLoading = false;
    //   });

    // this.updateScoresGraph();

  }

  updateScoresGraph = () => {
    this.commonService.getScoreStats().toPromise().then((data: any) => {
      this.scores = data.performance;
      this.barChartLabels = this.scores.map(score => this.commonService.getFormatedDateDDMMYYYY(score.timestamp));
      this.barChartDataProject[0].data = this.scores.map(score => score.nps.toFixed(1));
      this.barChartDataProject[1].data = this.scores.map(score => score.csat.toFixed(1));
      this.barChartDataProject[2].data = this.scores.map(score => score.ces.toFixed(1));
    });
  }

  calculateTotalCalls(agent) {
    const total_calls = agent.good_calls + agent.bad_calls + agent.average_calls;
    return total_calls;
  }

  onDateChange(dates, widget) {
    this.start_date = dates[0];
    this.end_date = dates[1];
    // this.updateScoresGraph();
    if (widget.type == Constants.PERFORMANCE_WIDGET) {
      this.getQualityScore();
    }
    if (widget.type == Constants.PERFORMANCE_WIDGET_MCA) {
      this.getQualityScoreMca();
    }
  }

  // This function converts a string into number of days
  // ie. 6 months becomes 180
  toDaysConvertor(strToConvert) {
    switch (strToConvert) {
      case 'today': return 1;
      case 'last7days': return 7;
      case 'last30days': return 30;
      case 'last3months': return 90;
      case 'last6months': return 180;
    }
  }

  onGraphCallsSelect(event, widget) {

    let params:any = {
      date: event.series,
      status: event.status
    };
    // widget.campaign != undefined && widget.campaign != null && widget.campaign != '' ? params['campaignId'] = widget.campaign : '';
   
    // widget.project != undefined && widget.project != null && widget.project != '' ? params['projectId'] = widget.project : '';
    this.projectFilterModel != undefined && this.projectFilterModel != null && this.projectFilterModel != '' ? params['projectId'] = this.projectFilterModel : '';

    this.campaignsFilterModel != undefined && this.campaignsFilterModel != null && this.campaignsFilterModel != '' ? params['campId'] = this.campaignsFilterModel : '';

    this.allFilterCheck != undefined && this.allFilterCheck != null && this.allFilterCheck != '' ? params['qualityCheck'] = this.allFilterCheck : '';

    this.router.navigate(['/calls'], { queryParams: params});
  }

  // mca

  onGraphCallsSelectMca(event, widget) {

    let params:any = {
      date: event.series,
      status: event.status
    };
    this.projectFilterModelMca != undefined && this.projectFilterModelMca != null && this.projectFilterModelMca != '' ? params['projectId'] = this.projectFilterModelMca : '';

    this.campaignsFilterModelMca != undefined && this.campaignsFilterModelMca != null && this.campaignsFilterModelMca != '' ? params['campId'] = this.campaignsFilterModelMca : '';

    this.allFilterCheckMca != undefined && this.allFilterCheckMca != null && this.allFilterCheckMca != '' ? params['qualityCheck'] = this.allFilterCheckMca : '';

    this.router.navigate(['/calls'], { queryParams: params});
  }

  sendCall(item) {
    let params:any = {
      start_date: item.start_date,
      end_date: item.end_date,
    };
    this.router.navigate(['/calls'], { queryParams: params});
    console.log(item)
  }

  onSentimentGraphCallsSelect(event, widget){

    let params:any = {
      date: event.name
    };

    widget.campaign != undefined && widget.campaign != null && widget.campaign != '' ? params['campaignId'] = widget.campaign : '';

    widget.project != undefined && widget.project != null && widget.project != '' ? params['projectId'] = widget.project : '';

    this.router.navigate(['/calls'], { queryParams: params});
  }

  openEditDashboardOverlay(id){

    this.event.sendMessage({
      type: Constants.SENT_DASHBOARD_EVENT_TO_EDIT,
      data: this.dashBoardWidgets
    });

    document.getElementById(id).style.width = "100%";
    $('body').addClass('overlay-open');
  }

  getWordCloud() {
    let wordCloudeWidgets = this.dashBoardWidgets.filter((w)=>{
      return w.type == Constants.WORD_CLOUDE_WIDGET
    });

    wordCloudeWidgets.forEach((widget:any)=>{
      let term = '';
      /*Word cloud for call*/
      if (widget.call) {
        term = "?call=" + widget.call;
      /*Word cloud for campaign*/
      }else if(widget.campaign){
        term = "?campaign=" + widget.campaign;
      /*Word cloud for project*/
      }else if(widget.project){
        term = "?project=" + widget.project;
      }

      this.loadingService.apiStart();
        this.commonService.getWordCloud(term)
        .toPromise()
        .then((data: any) => {
          let finalData = [];
          data.result.forEach(element => {
            finalData.push({
              weight: element.count,
              text: element.keyword
            });
          });
          widget.wordData = finalData;
          this.loadingService.apiStop();
        })
        .catch((err) => {
          this.loadingService.apiStop();
        });
    });
  }

  getQualityScore(isCam?, isProj?, id?) {
    let qualityScoreWidgets = this.dashBoardWidgets.filter((w)=>{
      return w.type == Constants.PERFORMANCE_WIDGET
    });

    qualityScoreWidgets.forEach((widget: any) => {
      let term = '';
      if (widget.campaign) {
        term = "&campId=" + widget.campaign;
      } else if (widget.project) {
        term = "&projectId=" + widget.project;
      }
      else {
        // console.log('qualityCheck');
         // term = "&qualityCheck=" + id;
        // term = "&qualityCheck=" + id;
      }
     
      /*TODO this has been added for temp*/
      if (id) {
        if (isCam) {
          term = "&campId=" + id;
          
          term = term + (this.allFilterCheck != undefined && this.allFilterCheck != null && this.allFilterCheck != '' ? ('&qualityCheck=' + this.allFilterCheck) : '');
          
          term = term + (this.projectFilterModel != undefined && this.projectFilterModel != null && this.projectFilterModel != '' ? ('&projectId=' + this.projectFilterModel) : '');

        } else if (isProj) {
          term = "&projectId=" + id;

          term = term + (this.allFilterCheck != undefined && this.allFilterCheck != null && this.allFilterCheck != '' ? ('&qualityCheck=' + this.allFilterCheck) : '');
          
          term = term + (this.campaignsFilterModel != undefined && this.campaignsFilterModel != null && this.campaignsFilterModel != ''  ? ('&campId=' + this.campaignsFilterModel) : '');
        }
        else {
          term = "&qualityCheck=" + id;

          term = term + (this.projectFilterModel != undefined && this.projectFilterModel != null && this.projectFilterModel != '' ? ('&projectId=' + this.projectFilterModel) : '');

          term = term + (this.campaignsFilterModel != undefined && this.campaignsFilterModel != null && this.campaignsFilterModel != ''  ? ('&campId=' + this.campaignsFilterModel) : '');

        }
      }
     
      this.isCallStatsLoading = true;

      this.loadingService.apiStart();

      this.dashboardService.getCallStats(term, this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {

        this.isCallStatsLoading = false;

        let widgetData:any = {
          multi: []
        };
        this.loadingService.apiStop();
        let callsOverview = data.calls.calls_overview;

        widgetData.totalcallstoday = callsOverview.calls_taken;
        widgetData.totalpasscallstoday = callsOverview.calls_passed;
        widgetData.totalfailedcallstoday = callsOverview.calls_failed;
        widgetData.totalworningcallstoday = callsOverview.calls_ok;
        widgetData.averagecallqualityscoretoday = (callsOverview.calls_passed / callsOverview.calls_taken);
        // console.log(widgetData.averagecallqualityscoretoday)
        data.calls.calls_trend.forEach(t => {
          let tempObject = {
            name: this.commonService.getFormatedDateDDMMYYYY(t.timestamp),
            series: [
              {
                name: 'Calls Failed',
                value: t.calls_failed,
                status: -1
              },
              {
                name: 'Calls Ok',
                value: t.calls_ok,
                status: 0
              }, {
                name: 'Calls Passed',
                value: t.calls_passed,
                status: 1
              }
            ]
          }
          widgetData.multi.push(tempObject);
        });
        widgetData.multi = [...widgetData.multi];
        widget.data = widgetData;
        // console.log( "data",widget.data);

        })
      .catch((err) => {
        this.loadingService.apiStop();
        this.isCallStatsLoading = false;
      });
    });
  }

  // get Mca Analysis for graph
  widgetMca: any = {
  }
  getQualityScoreMca(isCam?, isProj?, id?) {
    let term = '';
    /*TODO this has been added for temp*/
    if (id) {
      if (isCam) {
        term = "&campId=" + id;

        term = term + (this.allFilterCheckMca != undefined && this.allFilterCheckMca != null && this.allFilterCheckMca != '' ? ('&qualityCheck=' + this.allFilterCheckMca) : '');
          
          
        term = term + (this.projectFilterModelMca != undefined && this.projectFilterModelMca != null && this.projectFilterModelMca != '' ? ('&projectId=' + this.projectFilterModelMca) : '');

      } else if (isProj) {
        term = "&projectId=" + id;

        term = term + (this.allFilterCheckMca != undefined && this.allFilterCheckMca != null && this.allFilterCheckMca != '' ? ('&qualityCheck=' + this.allFilterCheckMca) : '');
          
        term = term + (this.campaignsFilterModelMca != undefined && this.campaignsFilterModelMca != null && this.campaignsFilterModelMca != '' ? ('&campId=' + this.campaignsFilterModelMca) : '');
      }
      else {
        term = "&qualityCheck=" + id;

        term = term + (this.projectFilterModelMca != undefined && this.projectFilterModelMca != null && this.projectFilterModelMca != '' ? ('&projectId=' + this.projectFilterModelMca) : '');

        term = term + (this.campaignsFilterModelMca != undefined && this.campaignsFilterModelMca != null && this.campaignsFilterModelMca != '' ? ('&campId=' + this.campaignsFilterModelMca) : '');

      }
    }

    this.isCallStatsLoadingMca = true;
    this.loadingService.apiStart();

    this.dashboardService.getCallStatsMca(term, this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {

        this.isCallStatsLoadingMca = false;
        this.loadingService.apiStop();

        let widgetDataMca: any = {
          multi: []
        };
        let callsOverview = data.calls.calls_overview;

        widgetDataMca.totalcallstoday = callsOverview.calls_taken;
        widgetDataMca.totalpasscallstoday = callsOverview.calls_passed;
        widgetDataMca.totalfailedcallstoday = callsOverview.calls_failed;
        widgetDataMca.totalworningcallstoday = callsOverview.calls_ok;
        widgetDataMca.averagecallqualityscoretoday = (callsOverview.calls_passed / callsOverview.calls_taken);
        // console.log(widgetDataMca.averagecallqualityscoretoday)
        data.calls.calls_trend.forEach(t => {
          let tempObject = {
            name: this.commonService.getFormatedDateDDMMYYYY(t.timestamp),
            series: [
              {
                name: 'Calls Failed',
                value: t.calls_failed,
                status: -1
              },
              {
                name: 'Calls Ok',
                value: t.calls_ok,
                status: 0
              }, {
                name: 'Calls Passed',
                value: t.calls_passed,
                status: 1
              }
            ]
          }
          widgetDataMca.multi.push(tempObject);
        });
        widgetDataMca.multi = [...widgetDataMca.multi];
        this.widgetMca = widgetDataMca;
      })
      .catch((err) => {
        this.loadingService.apiStop();
        this.isCallStatsLoadingMca = false;
      });
  }
  
  getCampaignsByProjectId(id) {
    this.isCampaignLoading = true;
    this.commonService
      .getProject(id)
      .toPromise()
      .then((data: any) => {
        this.isCampaignLoading = false;

        let widget:any = this.dashBoardWidgets.filter((w)=>{
          return w.type == Constants.CAMPAIGN_WIDGET
        })[0];

        if (widget != undefined && widget.campaigns != undefined && widget.campaigns.length > 0) {
          data['campaigns'] = data['campaigns'].filter((c)=>{
            return widget.campaigns.includes(c.id);
          });
          this.campaigns = data['campaigns'];
        }else{
          this.campaigns = data['campaigns'];
        }
        this.campaigns = this.campaigns.splice(0, 4);
        
      })
      .catch((err) => {
        this.isCampaignLoading = false;
      });
    
  }

 
  //MCA FILTER 
  arrayMca : Set<string> = new Set();

  mcaQCChange(id) {
    // this.mcaQcFilterModel = id;
    // this.getQualityScoreMca(false, false, this.mcaQcFilterModel);
    for (let j = 0; j <= this.mcaQcFilterModel.length; j++){
      for (let k = 0; k < this.qualityCheckQC.length; k++){
        if (this.mcaQcFilterModel[j] != this.qualityCheckQC[k].checkname) {
          this.array.delete(this.qualityCheckQC[k].checkname)
        }
      }
    }
    for (let i = 0; i < this.mcaQcFilterModel.length; i++){
      this.arrayMca.add(this.mcaQcFilterModel[i]);
    }
    this.allFilterCheckMca = '';
    this.allFilterCheckMca = [...this.arrayMca].join(',');
    this.getQualityScoreMca(false, false, this.allFilterCheckMca);
  }

  onProjectChangeMca(event){
    if (event.id) {
      this.projectFilterModelMca = event.id;
      this.getCampaignsByProjectId(event.id);
    }
    else {
      this.getCampaigns();
    }
    this.getQualityScoreMca(false, true, event.id);
  }

  onCampaignChangeMca(event) {
    this.campaignsFilterModelMca = event.id;
    this.getQualityScoreMca(true, false, event.id);
  }

  // qcs sore
  onProjectChange(event){
    if (event.id) {
      this.projectFilterModel = event.id;
      this.getCampaignsByProjectId(event.id);
    }
    else {
      this.getCampaigns();
    }
    this.getQualityScore(false, true, event.id);
  }

  onCampaignChange(event) {
    this.campaignsFilterModel = event.id;
    this.getQualityScore(true, false, event.id);
  }

  array : Set<string> = new Set();

  onOpeningChange(id){
    // this.openingFilterModel = '';
    for (let j = 0; j <= this.openingFilterModel.length; j++){
      for (let k = 0; k < this.qualityCheckOpening.length; k++){
        if (this.openingFilterModel[j] != this.qualityCheckOpening[k].checkname) {
          this.array.delete(this.qualityCheckOpening[k].checkname)
        }
      }
    }
    for (let i = 0; i < this.openingFilterModel.length; i++){
      this.array.add(this.openingFilterModel[i]);
    }
    this.allFilterCheck = '';
    this.allFilterCheck = [...this.array].join(',');
    this.getQualityScore(false, false, this.allFilterCheck);
  }
  onBodyChange(id){
    // this.bodyFilterModel = '';
      for (let j = 0; j <= this.bodyFilterModel.length; j++){
        for (let k = 0; k < this.qualityCheckBody.length; k++){
          if (this.bodyFilterModel[j] != this.qualityCheckBody[k].checkname) {
            this.array.delete(this.qualityCheckBody[k].checkname)
          }
        }
      }
      for (let i = 0; i < this.bodyFilterModel.length; i++){
        this.array.add(this.bodyFilterModel[i]);
      }
      this.allFilterCheck = '';
      this.allFilterCheck = [...this.array].join(',');
    this.getQualityScore(false, false, this.allFilterCheck);
  }
  onClosingChange(id){
    // this.closingFilterModel = '';
    for (let j = 0; j <= this.closingFilterModel.length; j++){
      for (let k = 0; k < this.qualityCheckCloseing.length; k++){
        if (this.closingFilterModel[j] != this.qualityCheckCloseing[k].checkname) {
          this.array.delete(this.qualityCheckCloseing[k].checkname)
        }
      }
    }
    for (let i = 0; i < this.closingFilterModel.length; i++){
      this.array.add(this.closingFilterModel[i]);
    }
    this.allFilterCheck = '';
    this.allFilterCheck = [...this.array].join(',');
    this.getQualityScore(false, false, this.allFilterCheck);
  }

  getQualityCheck() {
    // this.qualityCheck = [];
    // term = term + (this.topChangeFilterModel != undefined ? ('qualitycheck=' + 'Opening') : '');
    
    this.isProjectLoading = true;
    this.filterArray.forEach((element) => {
      this.selectedFilter = element;
      let term = this.selectedFilter;
      this.dashboardService.getQualityCheck(term)
      .toPromise()
        .then((data: any) => {
        if (element == 'Opening') {
          this.qualityCheckOpening = data.data;
          this.isProjectLoading = false;
        }
        else if (element == 'Body') {
          this.qualityCheckBody = data.data;
        }
        else if (element == 'Closing') {
          this.qualityCheckCloseing = data.data;
        }
        else if (element == 'QC') {
          this.qualityCheckQC = data.data;
        }
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });
    })
  }

  getSentimentDistribution() {
    let sentimentScoreWidgets = this.dashBoardWidgets.filter((w)=>{
      return w.type == Constants.SENTIMENT_SCORE_DISTRIBUTION
    });

    sentimentScoreWidgets.forEach((widget:any)=>{
      let term = '';
      if(widget.campaign){
        term = "?campaign=" + widget.campaign;
      }else if(widget.project){
        term = "?project=" + widget.project;
      }

      this.loadingService.apiStart();
      this.commonService.getSentimentScore(term)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        let series = [];
        for(let i = 0; i < data.sentiment_score.length; i++){
          let item = data.sentiment_score[i];
          let obj = {
            "name": this.commonService.getFormatedDateDDMMYYYY(item.timestamp),
            "value": item.sentiment_score
          }
          series.push(obj);
        }
        widget.results = JSON.parse(JSON.stringify(this.sentimentDistributionResults));
        widget.results[0].series = series;
      })
      .catch((err) => {
        this.loadingService.apiStop();
        console.log(err);
      });
    });
  }

  getSentimentScore(){
    let sentimentScoreWidgets = this.dashBoardWidgets.filter((w)=>{
      return w.type == Constants.SENTIMENT_SCORE_CALLS
    });

    sentimentScoreWidgets.forEach((widget:any)=>{
      let term = '';
      if(widget.campaign){
        term = "?campaign=" + widget.campaign;
      }else if(widget.project){
        term = "?project=" + widget.project;
      }

      this.loadingService.apiStart();
      this.commonService.getSentimentScoreCalls(term)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        widget.data = data.calls
      })
      .catch((err) => {
        this.loadingService.apiStop();
        console.log(err);
      });
    });
  }

  onClickWordCloud(data, widget){

    if (data != undefined && data.text) {
      let params:any = {
        word: data.text
      };

      widget.campaign != undefined && widget.campaign != null && widget.campaign != '' ? params['campaignId'] = widget.campaign : '';

      widget.project != undefined && widget.project != null && widget.project != '' ? params['projectId'] = widget.project : '';

      this.router.navigate(['/calls'], { queryParams: params});
    }
  }

  getActivityTracker(){
    let activityWidgets = this.dashBoardWidgets.filter((w)=>{
      return w.type == Constants.ACTIVITY_TRACKER_WIDGET
    })[0];
    if (activityWidgets == undefined || !activityWidgets.selected) {
      return;
    }

    setTimeout(()=>{
      this.commonService
      .getActivityTracker()
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.notifications = data.notifications;
      })
      .catch((err) => {
        this.loadingService.apiStop();
      });
    },4000);

    this.commonService
    .getActivityTracker()
    .toPromise()
    .then((data: any) => {
      this.loadingService.apiStop();
      this.notifications = data.notifications;
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }

  getLineUpCalls(){
    let lineUpCallWidgets = this.dashBoardWidgets.filter((w)=>{
      return w.type == Constants.LINE_UP_OF_CALL_WIDGET
    })[0];
    // if (lineUpCallWidgets.agents.length == 0) {
    //   return;
    // }

    // let term = '?agents=' + lineUpCallWidgets.agents.toString();
    let term = ''
    this.commonService
    .getLineUpCalls(term)
    .toPromise()
    .then((data: any) => {
      this.loadingService.apiStop();
      this.lineUpCalls = data.calls;
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }

  redirectInternal = (id) => {
    this.router.navigateByUrl('/call/' + id);
  }

  carouselOptions:any = {
    dots: false,
    nav: true,
    autoplay: true,
    loop: true,
    items: 3.5,
    autoplayTimeout: 5000,
    autoplaySpeed: 1500,
    responsiveClass: true,
    navText: ["<i class='nav-btn fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    responsive:{
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 3.5
      }
    }
  };
  sliderData: any = [];

  // sliderData : any = [
  // //     {
  // //     date: '1 Nov - 7 Nov',
  // //     qcsNumber: 80,
  // //     rpcNumber: [{
  // //       name: 'RPC',
  // //       score: 87
  // //     },
  // //     {
  // //     name: 'PI',
  // //     score: 79
  // //     },
  // //     {
  // //       name: 'Miranda',
  // //       score: 85
  // //     }
  // //     ],
  // //     totalAgent: '4 agents',
  // //     calls: '88 calls taken'
  // //   },
  // //   {
  // //     date: ' 8 Nov - 14 Nov',
  // //     qcsNumber: 75,
  // //     rpcNumber: [{
  // //       name: 'RPC',
  // //       score: 92
  // //     },
  // //     {
  // //     name: 'PI',
  // //     score: 84
  // //     },
  // //     {
  // //       name: 'Miranda',
  // //       score: 92
  // //     }
  // //     ],
  // //     totalAgent: '4 agents',
  // //     calls: '88 calls taken'
  // //   },
  // //   {
  // //     date: ' 15 Nov - 20 Nov',
  // //     qcsNumber: 85,
  // //     rpcNumber: [{
  // //       name: 'RPC',
  // //       score: 95
  // //     },
  // //     {
  // //     name: 'PI',
  // //     score: 87
  // //     },
  // //     {
  // //       name: 'Miranda',
  // //       score: 87
  // //     }
  // //     ],
  // //     totalAgent: '4 agents',
  // //     calls: '90 calls taken'
  // //   }
  // {
	// 	calls: [{
	// 		QCS: 0.6087196507936503,
	// 		agents: 3
	// 	}],
	// 	qualityCheck: [{
	// 		PI: 0.8271779257829498,
	// 		RPC: 0.7613006001021586,
	// 		MIRANDA: 0.8192251969356926
	// 	}],
	// 	call_taken: 190,
	// 	start_date: "2022-11-07",
	// 	end_date: "2022-11-14"
	// }, {
	// 	calls: [{
	// 		QCS: 0.5804418148148147,
	// 		agents: 4
	// 	}],
	// 	qualityCheck: [{
	// 		PI: 0.8276744989057382,
	// 		RPC: 0.7268555985142787,
	// 		MIRANDA: 0.8568105022112529
	// 	}],
	// 	call_taken: 30,
	// 	start_date: "2022-10-31",
	// 	end_date: "2022-11-06"
	// }, {
	// 	calls: [{
	// 		QCS: null,
	// 		agents: 0
	// 	}],
	// 	qualityCheck: [{
	// 		PI: null,
	// 		RPC: null,
	// 		MIRANDA: null
	// 	}],
	// 	start_date: "2022-10-24",
	// 	end_date: "2022-10-30"
	// },
  // ];

  getPastPerformance() {
    this.dashboardService.getPastPerformance().toPromise()
    .then((data: any) => {
      this.loadingService.apiStop();
      // this.sliderData = data.data;
      // console.log('data', data.data);

      data.data.forEach((element) => {
        if (element.calls[0].call_total != 0) {
          this.sliderData.push(element);
       }
     })
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }
  
}
