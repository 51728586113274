<div class="row no-margin top-space">
    <div class="">
        <div class="info_line row no-margin">
            <div class="organisation">
                <div class="title">
                    ORGANISATION INFORMATION
                </div>
                <div class=" row no-margin">
                    <div class="logo">
                        <img src="../../../assets/images/BG-05.png" alt="">
                        <div class="logo-button">
                            Update Logo
                        </div>
                    </div>
                    <div class="details">
                        <div class="label">
                            ORGANIZATION NAME
                        </div>
                        <div class="name">
                            <input class='form-field' type='text' placeholder="Organisation Name"> 
                            <span class="icon green">
                                <i class="fa fa-check"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="person-card">
                <div class="title">
                    PRODUCT MANAGER
                    <div class="icon" (click)="openEditModal()">
                         <i class="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                </div>

                <div class="details">
                    <div class="row no-margin">
                        <div class="attribute mid">
                            <div class="key">
                                ID
                            </div>
                            <div class="value">
                                11054
                            </div>
                        </div>
                        <div class="attribute mid">
                            <div class="key">
                                Name
                            </div>
                            <div class="value">
                                Mukesh Agicha
                            </div>
                        </div>
                    </div>
                    <div class="attribute" style="margin-left: 10px;margin-top: 20px">
                        <div class="key">
                            Email
                        </div>
                        <div class="value">
                            agichamukesh@gmail.com
                        </div>
                    </div>
                </div>
            </div>
            <div class="person-card">
                <div class="title">
                    QUALITY MANAGER
                    <div class="icon">
                         <i class="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                </div>

                <div class="details">
                    <div class="row no-margin">
                        <div class="attribute mid">
                            <div class="key">
                                ID
                            </div>
                            <div class="value">
                                11054
                            </div>
                        </div>
                        <div class="attribute mid">
                            <div class="key">
                                Name
                            </div>
                            <div class="value">
                                Mukesh Agicha
                            </div>
                        </div>
                    </div>
                    <div class="attribute" style="margin-left: 10px;margin-top: 20px">
                        <div class="key">
                            Email
                        </div>
                        <div class="value">
                            agichamukesh@gmail.com
                        </div>
                    </div>
                </div>
            </div>

            <div class="person-card">
                <div class="title">
                    CALL CENTER MANAGER
                    <div class="icon">
                         <i class="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                </div>

                <div class="details">
                    <div class="row no-margin">
                        <div class="attribute mid">
                            <div class="key">
                                ID
                            </div>
                            <div class="value">
                                11054
                            </div>
                        </div>
                        <div class="attribute mid">
                            <div class="key">
                                Name
                            </div>
                            <div class="value">
                                Mukesh Agicha
                            </div>
                        </div>
                    </div>
                    <div class="attribute" style="margin-left: 10px;margin-top: 20px">
                        <div class="key">
                            Email
                        </div>
                        <div class="value">
                            agichamukesh@gmail.com
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main row no-margin">
            <div class="list">
                <div class="title">
                    Personal Information
                </div>
                <div class="actions">
                    <div class="add-member" (click)="openAddModal()">
                        Add Member +
                    </div>
                    <div class="upload-excel">
                        Upload Excel
                    </div>
                    <div class="icon">
                        <i class="fa fa-upload"></i>
                    </div>
                </div>
                <div class="list-table">
                    <div class="list-header">
                        <div class="header-name col-md">
                            Id
                        </div>
                        <div class="header-name col-md">
                            Name
                        </div>
                        <div class="header-name col-md">
                            Role
                        </div>
                        <div class="header-name col-lg">
                            Email address
                        </div>
                        <div class="header-name col-md">
                            Team Name
                        </div>
                        <div class="header-name col-lg">
                            Team Leader Name
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="attribute col-md">
                            111053
                        </div>
                        <div class="attribute col-md">
                            Mukesh Agicha
                        </div>
                        <div class="attribute col-md">
                            Team Manager <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                            agichamukesh@gmail.com
                        </div>
                        <div class="attribute col-md">
                            Refund Team <span class="icon"> <i class="fa fa-angle-down"></i></span>
                        </div>
                        <div class="attribute col-lg">
                                Mukesh Agicha
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="team">
                <div class="title">
                    Team
                </div>
                <div class="actions">
                    <div class="add-member" (click)="openAddModal()">
                            Add Member
                    </div>
                </div>
                <div class="team-list">
                    <div class="team-card">
                        <div class="title">
                            Refund Team
                        </div>
                        <div class="icon">
                             <i class="fa fa-pencil" aria-hidden="true"></i>
                        </div>
                        <div class="profile row no-margin">
                            <div class="image">
                                <img width="32" height="32" src="../../../assets/images/profile.png" alt="">
                            </div>
                            <div class="details">
                                <div class="name">
                                    Jane Doe
                                </div>
                                <div class="role">
                                    Team Leader
                                </div>
                            </div>

                            <div class="agent-count">
                                32 Agents
                            </div>
                        </div>
                    </div>
                    <div class="team-card">
                        <div class="title">
                            Grievance Team 
                        </div>
                        <div class="icon">
                             <i class="fa fa-pencil" aria-hidden="true"></i>
                        </div>
                        <div class="profile row no-margin">
                            <div class="image">
                                <img width="32" height="32" src="../../../assets/images/profile.png" alt="">
                            </div>
                            <div class="details">
                                <div class="name">
                                    Jane Doe
                                </div>
                                <div class="role">
                                    Team Leader
                                </div>
                            </div>

                            <div class="agent-count">
                                32 Agents
                            </div>
                        </div>
                    </div>
                    <div class="team-card">
                        <div class="title">
                            Support Team Team
                        </div>
                        <div class="icon">
                             <i class="fa fa-pencil" aria-hidden="true"></i>
                        </div>
                        <div class="profile row no-margin">
                            <div class="image">
                                <img width="32" height="32" src="../../../assets/images/profile.png" alt="">
                            </div>
                            <div class="details">
                                <div class="name">
                                    Jane Doe
                                </div>
                                <div class="role">
                                    Team Leader
                                </div>
                            </div>

                            <div class="agent-count">
                                32 Agents
                            </div>
                        </div>
                    </div>
                    <div class="team-card">
                        <div class="title">
                            Support Team Team
                        </div>
                        <div class="icon">
                             <i class="fa fa-pencil" aria-hidden="true"></i>
                        </div>
                        <div class="profile row no-margin">
                            <div class="image">
                                <img width="32" height="32" src="../../../assets/images/profile.png" alt="">
                            </div>
                            <div class="details">
                                <div class="name">
                                    Jane Doe
                                </div>
                                <div class="role">
                                    Team Leader
                                </div>
                            </div>

                            <div class="agent-count">
                                32 Agents
                            </div>
                        </div>
                    </div>
                    <div class="team-card">
                        <div class="title">
                            Support Team Team
                        </div>
                        <div class="icon">
                             <i class="fa fa-pencil" aria-hidden="true"></i>
                        </div>
                        <div class="profile row no-margin">
                            <div class="image">
                                <img width="32" height="32" src="../../../assets/images/profile.png" alt="">
                            </div>
                            <div class="details">
                                <div class="name">
                                    Jane Doe
                                </div>
                                <div class="role">
                                    Team Leader
                                </div>
                            </div>

                            <div class="agent-count">
                                32 Agents
                            </div>
                        </div>
                    </div>
                    <div class="team-card edit">
                        <div class="attribute">
                            <div class="key lg-text">
                                Team Name
                            </div>
                            <div class="value lg-text">
                                <input class='form-field lg-field' type='text' placeholder="team name" name="team name" value="Grievance Team">
                            </div>
                            <div class="done-icon selected">
                                <i class="fa fa-check"></i>
                            </div>
                            <div class="attribute">
                        </div>
                            <div class="key">
                                Role
                            </div>
                            <div class="value">
                                <!-- <input class='form-field' type='text' placeholder="Name" name="username" value="Team Leader"> -->
                                <select class="form-field">
                                    <option selected>Select Leader</option>
                                    <option>Mukesh</option>
                                    <option>Aditya </option>
                                    <option>Krishna</option>
                                    <option>Mayank</option>
                                    </select>
                            </div>
                            <div class="add-agent" (click)="addAgentModal()">
                                Add Agents
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="editmodal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">EDIT MEMBER</h5>
                        <div class="delete">
                            DELETE AGENT
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="attribute">
                            <div class="key">
                                ID
                            </div>
                            <div class="value">
                                <input class='form-field' type='text' placeholder="Username" name="username" value="112303">
                            </div>
                        </div>
                        <div class="attribute">
                            <div class="key">
                                Name
                            </div>
                            <div class="value">
                                <input class='form-field' type='text' placeholder="Name" name="username" value="Mukesh Agicha">
                            </div>
                        </div>

                        <div style="margin :20px 0px">
                            <div class="attribute">
                                <div class="key">
                                    Email
                                </div>
                                <div class="value">
                                    <input style="width: 200px;" class='form-field' type='text' placeholder="Name" name="username" value="agichamukesh@gmail.com">
                                </div>
                            </div>
                            <div class="attribute">
                                <div class="key">
                                    Role
                                </div>
                                <div class="value">
                                    <!-- <input class='form-field' type='text' placeholder="Name" name="username" value="Team Leader"> -->
                                    <select class="form-field">
                                        <option selected>Team Leader</option>
                                        <option>Developer</option>
                                        <option>Developer Pro</option>
                                        <option>Developer Pro Max</option>
                                        <option>Manager</option>
                                        </select>
                                </div>
                            </div>
                            <div class="attribute">
                                    <div class="key">
                                    Chose Team
                                </div>
                                <div class="value">
                                    <input class='form-field' type='text' placeholder="Name" name="username" value="Refund Team">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button  type="button" class="btn closebtn" data-dismiss="modal">Close</button>
                        <button type="button"  class="btn save">Save Details</button>
                    </div>
                </div>
            </div>
         </div>  
         <div class="modal" id="addmodal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">ADD NEW MEMBER</h5>
                    </div>
                    <div class="modal-body">
                        <div class="attribute">
                            <div class="key">
                                ID
                            </div>
                            <div class="value">
                                <input class='form-field' type='text' placeholder="Username" name="username" value="112303">
                            </div>
                        </div>
                        <div class="attribute">
                            <div class="key">
                                Name
                            </div>
                            <div class="value">
                                <input class='form-field' type='text' placeholder="Name" name="username" value="Mukesh Agicha">
                            </div>
                        </div>

                        <div style="margin :20px 0px">
                            <div class="attribute">
                                <div class="key">
                                    Email
                                </div>
                                <div class="value">
                                    <input style="width: 200px;" class='form-field' type='text' placeholder="Name" name="username" value="agichamukesh@gmail.com">
                                </div>
                            </div>
                            <div class="attribute">
                                <div class="key">
                                    Role
                                </div>
                                <div class="value">
                                    <!-- <input class='form-field' type='text' placeholder="Name" name="username" value="Team Leader"> -->
                                    <select class="form-field">
                                        <option selected>Team Leader</option>
                                        <option>Developer</option>
                                        <option>Developer Pro</option>
                                        <option>Developer Pro Max</option>
                                        <option>Manager</option>
                                        </select>
                                </div>
                            </div>
                            <div class="attribute">
                                    <div class="key">
                                    Chose Team
                                </div>
                                <div class="value">
                                    <input class='form-field' type='text' placeholder="Name" name="username" value="Refund Team">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button  type="button" class="btn closebtn" data-dismiss="modal">Close</button>
                        <button type="button"  class="btn save">ADD</button>
                    </div>
                </div>
            </div>
        </div>  
        <div class="modal" id="addagent" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-xl" style="width: 700px;min-width: 700px;" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">ADD Agents To Teams</h5>
                    </div>
                    <div class="modal-body">
                        <div class="list">
                            <div class="agent-row no-margin">
                                <div class="icon selected">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="details">
                                    <div class="id">
                                        110345
                                    </div>
                                    <div class="name">
                                        Mukesh Agicha
                                    </div>
                                    <div class="email">
                                        agichamukesh@gmail.com
                                    </div>
                                </div>
                            </div>
                            <div class="agent-row no-margin">
                                <div class="icon">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="details">
                                    <div class="id">
                                        110345
                                    </div>
                                    <div class="name">
                                        Mukesh Agicha
                                    </div>
                                    <div class="email">
                                        agichamukesh@gmail.com
                                    </div>
                                </div>
                            </div>
                            <div class="agent-row no-margin">
                                <div class="icon selected">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="details">
                                    <div class="id">
                                        110345
                                    </div>
                                    <div class="name">
                                        Mukesh Agicha
                                    </div>
                                    <div class="email">
                                        agichamukesh@gmail.com
                                    </div>
                                </div>
                            </div>
                            <div class="agent-row no-margin">
                                <div class="icon">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="details">
                                    <div class="id">
                                        110345
                                    </div>
                                    <div class="name">
                                        Mukesh Agicha
                                    </div>
                                    <div class="email">
                                        agichamukesh@gmail.com
                                    </div>
                                </div>
                            </div>
                            <div class="agent-row no-margin">
                                <div class="icon selected">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="details">
                                    <div class="id">
                                        110345
                                    </div>
                                    <div class="name">
                                        Aditya Singh
                                    </div>
                                    <div class="email">
                                        micheal.scott@britishgas.com
                                    </div>
                                </div>
                            </div>
                            <div class="agent-row no-margin">
                                <div class="icon">
                                    <i class="fa fa-check"></i>
                                </div>
                                <div class="details">
                                    <div class="id">
                                        110345
                                    </div>
                                    <div class="name">
                                        Mukesh Agicha
                                    </div>
                                    <div class="email">
                                        agichamukesh@gmail.com
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button  type="button" class="btn closebtn" data-dismiss="modal">Close</button>
                        <button type="button"  class="btn save">ADD Agents</button>
                    </div>
                </div>
            </div>
        </div> 
</div>