import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { DashboardService } from '../../services/dashboard.service';

import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import * as moment from 'moment';
@Component({
  selector: 'cxr-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

  start_date:any;
  end_date:any;

  campaigns = [];
  projectId;
  overviewData = null;
  project:any = {};
  manageCampaing: any = false;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('id');
    
    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];

    this.setDate('today', '24 Hours');
    this.toggleDate();

    // this.getProjectOverview();
    // this.getProject();
    this.route.queryParams.subscribe(
      params => {
        if (params['edit'] == 'manageCampaing') {
          this.manageCampaing = true;
        }
        else {
          // this.router.navigateByUrl('/project/' + id);
        }
      }
    )
  }
  dateRange = [];
  cxrOverviewData = {};
  isCxrOverviewDataLoading = true;
  isCampaignsLoading = true;
  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  samplename = "NH Customer service";
  samplename1 = "CAL Customer service";
  samplename2 = "NH sales";
  samplename3 = "CAL sales";
  dateDisplay = false;
  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period"

  setDate = (dateSelected, dateSelectedDisplay) => {
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    this.toggleDate();
    // this.getProjectOverview();
    // this.getProject();
    this.getProjectOverview();
    this.getOverview();
    if (this.projectId == null) {
      // this.getCampaigns(30);
      this.getCampaigns();
    } else {
      this.getProject();
    }
  }

  toggleDate() {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }

  }

  getProjectOverview() {
    this.commonService.getProjectOverview(this.projectId, this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {

        this.overviewData = data.checks;
        // checks.forEach(c => {
        //   if (c.checkname == 'confirmation') {
        //     this.overviewData.confirmationcheckfail = c.fail;
        //     this.overviewData.confirmationcheckpass = c.pass;
        //   } else if (c.checkname == 'ending') {
        //     this.overviewData.endingcheckfail = c.fail;
        //     this.overviewData.endingcheckpass = c.pass;
        //   } else if (c.checkname == 'greeting') {
        //     this.overviewData.greetingcheckfail = c.fail;
        //     this.overviewData.greetingcheckpass = c.pass;
        //   } else if (c.checkname == 'nametaken') {
        //     this.overviewData.nametakencheckfail = c.fail;
        //     this.overviewData.nametakencheckpass = c.pass;
        //   } else if (c.checkname == 'offerdirectconnection') {
        //     this.overviewData.offerdirectconnectioncheckfail = c.fail;
        //     this.overviewData.offerdirectconnectioncheckpass = c.pass;
        //   } else if (c.checkname == 'verification') {
        //     this.overviewData.verficationcheckfail = c.fail;
        //     this.overviewData.verificationcheckpass = c.pass;
        //   }
        // });
        // this.overviewData = data[0];
      })
  }
  projectName;
  getProject() {
    this.isCampaignsLoading = true;
    this.commonService
      .getProject(this.projectId)
      .toPromise()
      .then((data: any) => {
        this.campaigns = data.campaigns;
        this.project = data.campaigns;
        this.projectName = this.project[0].projectname
        this.isCampaignsLoading = false;
      })
      .catch((err) => {
        this.isCampaignsLoading = false;
      });
  }

  getCampaigns() {
    this.isCampaignsLoading = true;
    this.commonService.getCampaigns(this.start_date, this.end_date)
    .toPromise()
      .then((data: any) => {
      this.isCampaignsLoading = false;
      this.campaigns = data.campaigns;
    }).catch((err) => {
      this.isCampaignsLoading = false;
    });
  }

  getOverview = () => {
    let term = (this.projectId != undefined && this.projectId != null) ? ('&project=' + this.projectId) : '';

    this.isCxrOverviewDataLoading = true;
    this.dashboardService.getCallStats(term, this.start_date, this.end_date).toPromise().then((data: any) => {
      this.cxrOverviewData = data;
      this.isCxrOverviewDataLoading = false;
    }).catch((err) => {
      this.isCxrOverviewDataLoading = false;
    });
  }

  openCampaignView(campaignId) {
    // this.router.navigateByUrl('/campaign/' + campaignId);
    if (this.manageCampaing == true) {
      this.router.navigateByUrl('/admin/project/createcampaign/' + campaignId);
    }
    else {
      // this.router.navigateByUrl('/campaign/' + campaignId);
      this.router.navigateByUrl('/teams/' + campaignId);
    }
  }
  back = () => {
    window.history.back();
  }

  onDateChange(dates){
    
    this.start_date = dates[0];
    this.end_date = dates[1];

    this.getProjectOverview();
    this.getOverview();
    if (this.projectId == null) {
      this.getCampaigns();
    } else {
      this.getProject();
    }
  }
}
