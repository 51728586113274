import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../services/loading.service';
import { UserDataService } from '../../services/user-data.service';
import { BackendService } from '../../services/backendservice.service';

@Component({
  selector: 'cxr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username : string;
  password : string;
  email : string;

  constructor(
    private authenticationService : AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private userService: UserDataService,
    private backendService: BackendService
  ) { }

  ngOnInit() {
   
  }
  forgotPass = false;
  signup = false;

  submitPass() {
    // signup new user
    if (this.signup == true) {
      if (!this.username || !this.password || !this.email) {
        return;
      }
      let payload = {
        email: this.email,
        username: this.username,
        password : this.password
      }
      this.loadingService.apiStart();
      this.authenticationService.signup(payload)
      .toPromise()
      .then((data:any) => {
        if (data.status == 'success') {
          this.router.navigate(['/dashboard']);
        }
        this.loadingService.apiStop();
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error(err.error.error);
        this.loadingService.apiStop();
      })
    }
    // forgot password
    else {
      if (!this.email) {
        return;
      }
      let payload = {
        email: this.email,
      }
      this.loadingService.apiStart();
      this.authenticationService.PostSetPassword(payload)
      .toPromise()
      .then((data:any) => {
        if (data.status == 'success') {
          this.router.navigate(['/login']);
        }
        this.loadingService.apiStop();
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error(err.error.error);
        this.loadingService.apiStop();
      })
    }
    
  }

  public performLogin() {
    if (!this.username || !this.password) {
      return;
    }
    this.loadingService.apiStart();
    this.authenticationService.login(this.username,this.password)
    .toPromise()
    .then((data:any) => {
      // const tokenResponse = token[0].token;
      // localStorage.setItem('jwt_token',tokenResponse);
      // Navigate to dashboard
      if (data.status == 'success') {
        this.userService.setUserData(data.user);
        this.router.navigate(['/dashboard']);
      }
      this.loadingService.apiStop();
    })
    .catch((err) => {
      console.error(err);
      this.toastr.error(err.error.error);
      this.loadingService.apiStop();
    })

    // const loginObject = {username : this.username , password : this.password};

    // this.backendService.login(loginObject).subscribe((result)=>{
    //   if (result.code == 400) {
    //     this.toastr.error(result.data);
    //     this.loadingService.apiStop();
    //     return;
    //   }else if(result.code == 200){
    //     this.userService.setUserData(result.data);
    //     this.loadingService.apiStop();
    //     this.router.navigate(["/dashboard'"]);
    //     this.toastr.success("Login Successfully");
    //   }
    // }, (err)=>{
    //   console.error(err);
    //   this.loadingService.apiStop();
    // });

    
  }

}
