import { Component, OnInit } from '@angular/core';
declare var $: any;
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/loading.service';

import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';



@Component({
  selector: 'cxr-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.scss']
})
export class CallsComponent implements OnInit {

  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period";
  dateDisplay = false;
  calls = [];
  campaigns = [];
  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  dateRange = [];

  isCallLoading = true;

  campaignId;
  cloudText;
  status;
  projectId;
  focusKeyword;

  start_date:any;
  end_date:any;

  selectedFilter;

  toggleDate = () => {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }
  }
  constructor(
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.date != undefined && params.date != '') {
        let splittedDate = params.date.split('/');
        let formattedDate = splittedDate[1] + '/' + splittedDate[0]+ '/' + splittedDate[2];
        let nextDate = new Date(formattedDate);
        nextDate.setDate(nextDate.getDate()+1);
        this.start_date = formattedDate;
        this.end_date = nextDate;

        this.dateRange.push((moment(this.start_date)).format('YYYY-MM-DD'));
        this.dateRange.push(moment(this.end_date).format('YYYY-MM-DD'));
      }
      this.campaignId = params.campaignId;
      this.cloudText = params.word;
      this.status = params.status;
      this.projectId = params.projectId;
      this.focusKeyword = params.focusKeyword;
    });

    this.toggleDate();

    if (this.dateRange.length == 0) {
      this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
      this.dateRange.push(moment().format('YYYY-MM-DD'));

      this.start_date = this.dateRange[0];
      this.end_date = this.dateRange[1];
    }

    this.setDate('today', '24 Hours');
  }

  display = false;
  setDate = (dateSelected, dateSelectedDisplay) => {
    this.toggleDate();
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    // this.refreshList(this.commonService.toDaysConvertor(this.selectedPeriod));
    this.refreshList();

  }

  redirectInternal = (id) => {
    this.router.navigateByUrl('/call/' + id);

  }

  toggleDisplay = () => {
    if (!this.display) {
      $('.actions').css('display', 'unset');
      this.display = true;
    } else {
      $('.actions').css('display', 'none');
      this.display = false;
    }
  }

  refreshList() {
    let term = "";
    term = term + (this.campaignId != undefined ? ('&campId=' + this.campaignId) : '');
    term = term + ((this.cloudText != undefined && this.cloudText != '') ? ("&cloudText=" + this.cloudText) : "");
    term = term + (this.status != undefined ? ("&status=" + this.status) : "");
    term = term + (this.projectId != undefined ? ("&projectId=" + this.projectId) : "");
    term = term + (this.focusKeyword != undefined ? ("&focusKeyword=" + this.focusKeyword) : "");
    term = term + (this.selectedFilter != undefined ? ("&filter=" + this.selectedFilter) : "");

    this.isCallLoading = true;
    this.loadingService.apiStart();
    this.commonService.getCalls(term, this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.calls = data.calls;
        this.calls.forEach(call => {
          if (this.campaigns.indexOf(call.campaign_name) < 0) {
            this.campaigns.push(call.campaign_name);
          }
        });
        this.isCallLoading = false;
      })
      .catch((err) => {
        this.isCallLoading = false;
        this.loadingService.apiStop();
      })
  }

  filterCalls = (calls) => {
    let filter = document.getElementsByClassName('filter')[0]['value'];
    if(filter != "") {
      return this.calls.filter(x => x.campaign_name == filter);
    }
    return this.calls.filter(x => x);
  }

  onDateChange(dates) {
    this.start_date = dates[0];
    this.end_date = dates[1];
    this.refreshList();
  }
}
