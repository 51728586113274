import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { CommonService } from '../../services/common.service';
import { EventService } from '../../services/event.service';
import { LoadingService } from '../../services/loading.service';
import { Constants } from '../../constants/constants';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import { from } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var $:any;

@Component({
  selector: 'cxr-dashboard-edit-overlay',
  templateUrl: './dashboard-edit-overlay.component.html',
  styleUrls: ['./dashboard-edit-overlay.component.scss']
})
export class DashboardEditOverlayComponent implements OnInit {

  constants = Constants;

  constructor(
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private router: Router,
    private loadingService: LoadingService,
    public event: EventService) { }

  selectedWidgets:any = [];

  allWidgets:any = [];

  clickedWidget:any;
  allProjects:any = [];
  allCampaigns:any = [];
  allCalls:any = [];
  allTeams:any = [];
  allAgents:any = [];

  ngOnInit(): void {

    this.allWidgets = JSON.parse(JSON.stringify(this.commonService.getAllWidgets()));

    this.event.getMessage().subscribe(data => {
      if (data.type == Constants.SENT_DASHBOARD_EVENT_TO_EDIT) {
        this.setWidgetData(data);
      }
    });

    this.getProjects();
    this.getCampaigns();
    this.getCalls();
    this.getTeams();
    this.getAgents();
  }

  setWidgetData(data){
    this.selectedWidgets = data.data || [];
    this.allWidgets = JSON.parse(JSON.stringify(this.commonService.getAllWidgets()));

    for(let w = 0; w < this.selectedWidgets.length; w++){
      for(let w1 = 0; w1 < this.allWidgets.length; w1++){
        if (this.selectedWidgets[w].type == this.allWidgets[w1].type) {

          /*For set word cloud widget start*/
          if (this.selectedWidgets[w].type == Constants.WORD_CLOUDE_WIDGET) {
            if (this.allWidgets[w1].projects == undefined) {
              this.allWidgets[w1].projects = [];
            }
            if (this.allWidgets[w1].campaigns == undefined) {
              this.allWidgets[w1].campaigns = [];
            }
            if (this.allWidgets[w1].calls == undefined) {
              this.allWidgets[w1].calls = [];
            }

            if (this.selectedWidgets[w].project != undefined) {
              this.allWidgets[w1].projects.push(this.selectedWidgets[w].project);
            }

            if (this.selectedWidgets[w].campaign != undefined) {
              this.allWidgets[w1].campaigns.push(this.selectedWidgets[w].campaign);
            }

            if (this.selectedWidgets[w].call != undefined) {
              this.allWidgets[w1].calls.push(this.selectedWidgets[w].call);
            }
            break;
          }
          /*For set word cloud widget end*/

          /*For set quality score widget start*/
          if (this.selectedWidgets[w].type == Constants.PERFORMANCE_WIDGET) {
            if (this.allWidgets[w1].projects == undefined) {
              this.allWidgets[w1].projects = [];
            }
            if (this.allWidgets[w1].campaigns == undefined) {
              this.allWidgets[w1].campaigns = [];
            }

            if (this.selectedWidgets[w].project != undefined) {
              this.allWidgets[w1].projects.push(this.selectedWidgets[w].project);
            }

            if (this.selectedWidgets[w].campaign != undefined) {
              this.allWidgets[w1].campaigns.push(this.selectedWidgets[w].campaign);
            }
            break;
          }
          /*For set quality score widget end*/


          /*For set sentiment score distribution widget start*/
          if (this.selectedWidgets[w].type == Constants.SENTIMENT_SCORE_DISTRIBUTION) {
            if (this.allWidgets[w1].projects == undefined) {
              this.allWidgets[w1].projects = [];
            }
            if (this.allWidgets[w1].campaigns == undefined) {
              this.allWidgets[w1].campaigns = [];
            }

            if (this.selectedWidgets[w].project != undefined) {
              this.allWidgets[w1].projects.push(this.selectedWidgets[w].project);
            }

            if (this.selectedWidgets[w].campaign != undefined) {
              this.allWidgets[w1].campaigns.push(this.selectedWidgets[w].campaign);
            }
            break;
          }
          /*For set sentiment score distribution widget end*/


          /*For set sentiment score widget start*/
          if (this.selectedWidgets[w].type == Constants.SENTIMENT_SCORE_CALLS) {
            if (this.allWidgets[w1].projects == undefined) {
              this.allWidgets[w1].projects = [];
            }
            if (this.allWidgets[w1].campaigns == undefined) {
              this.allWidgets[w1].campaigns = [];
            }

            if (this.selectedWidgets[w].project != undefined) {
              this.allWidgets[w1].projects.push(this.selectedWidgets[w].project);
            }

            if (this.selectedWidgets[w].campaign != undefined) {
              this.allWidgets[w1].campaigns.push(this.selectedWidgets[w].campaign);
            }
            break;
          }
          /*For set sentiment score widget end*/

          this.allWidgets[w1] = JSON.parse(JSON.stringify(this.selectedWidgets[w]));
          break;
        }
      }
    }
  }

  ngAfterViewInit(){
    $('#customizableDashboard.overlay .widget-for-selection').on('hide.bs.dropdown', function (e) {
      if (e.clickEvent) {
        e.preventDefault();
      }
    });
  }

  closeOverlay(id) {
    document.getElementById(id).style.width = "0%";
    $('body').removeClass('overlay-open');
  }

  onClickWidget(widget, index){
    
    let type = widget.type;

    /*Check if there any drop down open already*/
    if ($(".widget-for-selection").hasClass("show"))
      return;

    /*Widget not have a any dropdown*/
    if (type == Constants.ACTIVITY_TRACKER_WIDGET || type == Constants.CUSTOM_WIDGET) {
      widget.selected = !widget.selected;
      return;
    }

    // this.clickedWidget = type;
    $('#widgetForSelection' + index + ' a').dropdown("toggle");
    if (this.isWidgetSelected(type)) {
      // this.selectedWidgets = this.selectedWidgets.filter((obj)=>{
      //   return obj.type != type;
      // });
    }else{
      // $('#selectWidgetModal').modal('show');
      /*this.selectedWidgets.push({
        type: type
      });*/
    }
  }

  isWidgetSelected(type){
    let widget:any = this.allWidgets.filter(el => el.type === type)[0];
    if (widget != undefined
        && ((widget.projects != undefined && widget.projects.length > 0)
        || (widget.campaigns != undefined && widget.campaigns.length > 0)
        || (widget.calls != undefined && widget.calls.length > 0)
        || (widget.teams != undefined && widget.teams.length > 0)
        || (widget.agents != undefined && widget.agents.length > 0)
        || (widget.selected == true))) {
      return true;
    }
    return false;
  }

  getProjectById(id){
    return this.allProjects.filter((p)=>{
      return p.id == id
    });
  }

  getCampaignById(id){
    return this.allCampaigns.filter((c)=>{
      return c.id == id
    });
  }

  addToDashboardClick(){

    let allWidgets = JSON.parse(JSON.stringify(this.allWidgets));
    let removedType:any = [];
    for(let w = 0; w < this.allWidgets.length; w++){
      let widget = JSON.parse(JSON.stringify(this.allWidgets[w]));
      for(let i = 0; i < this.selectedWidgets.length; i++){
        if (this.selectedWidgets[i].type == this.allWidgets[w].type) {
          this.selectedWidgets[i] = widget;
          removedType.push(this.allWidgets[w].type);
          break;
        }
      }
    }

    for(let m = 0; m < removedType.length; m++){
      let removedValue = removedType[m];
      allWidgets = allWidgets.filter((item)=>{
        return item.type != removedValue;
      });
    }

    this.selectedWidgets = this.selectedWidgets.concat(allWidgets);
    this.selectedWidgets = this.selectedWidgets.filter((item)=>{
      return this.isWidgetSelected(item.type);
    });

    /*To distribute word cloud widget start */
    let wordCloudeWidget:any = this.selectedWidgets.filter((w)=>{
      return w.type == Constants.WORD_CLOUDE_WIDGET;
    })[0];

    this.selectedWidgets = this.selectedWidgets.filter((w)=>{
      return w.type != Constants.WORD_CLOUDE_WIDGET;
    });

    if (wordCloudeWidget != undefined) {

      /*For project*/
      if (wordCloudeWidget.projects != undefined && wordCloudeWidget.projects.length > 0) {
        for(let w = 0; w < wordCloudeWidget.projects.length; w++){

          let project = this.getProjectById(wordCloudeWidget.projects[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.WORD_CLOUDE_WIDGET == w.type;
          })[0];

          newWidget['project'] = wordCloudeWidget.projects[w];
          newWidget['displayName'] = project['name'] + ' project word cloud';
          /*let newWidget:any = {
            type: Constants.WORD_CLOUDE_WIDGET,
            name: 'Word cloud',
            image: '/assets/images/word-cloud-widget.png',
            project: wordCloudeWidget.projects[w],
            displayName: project['name'] + ' project word cloud'
          };*/

          this.selectedWidgets.push(newWidget);
        }
      }

      /*For campaign*/
      if (wordCloudeWidget.campaigns != undefined && wordCloudeWidget.campaigns.length > 0) {
        for(let w = 0; w < wordCloudeWidget.campaigns.length; w++){

          let campaign = this.getCampaignById(wordCloudeWidget.campaigns[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.WORD_CLOUDE_WIDGET == w.type;
          })[0];

          newWidget['campaign'] = wordCloudeWidget.campaigns[w];
          newWidget['displayName'] = campaign['name'] + ' campaign word';

          // let newWidget:any = {
          //   type: Constants.WORD_CLOUDE_WIDGET,
          //   name: 'Word cloud',
          //   image: '/assets/images/word-cloud-widget.png',
          //   campaign: wordCloudeWidget.campaigns[w],
          //   displayName: campaign['name'] + ' campaign word cloud'
          // };

          this.selectedWidgets.push(newWidget);
        }
      }

      /*For calls*/
      if (wordCloudeWidget.calls != undefined && wordCloudeWidget.calls.length > 0) {
        for(let w = 0; w < wordCloudeWidget.calls.length; w++){
          
          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.WORD_CLOUDE_WIDGET == w.type;
          })[0];

          newWidget['call'] = wordCloudeWidget.calls[w];

          this.selectedWidgets.push(newWidget);
        }
      }
    }
    /*To distribute word cloud widget end */

    /*To distribute quality score widget start */
    let qualityScoreWidget:any = this.selectedWidgets.filter((w)=>{
      return w.type == Constants.PERFORMANCE_WIDGET;
    })[0];

    this.selectedWidgets = this.selectedWidgets.filter((w)=>{
      return w.type != Constants.PERFORMANCE_WIDGET;
    });

    if (qualityScoreWidget != undefined) {

      /*For project*/
      if (qualityScoreWidget.projects != undefined && qualityScoreWidget.projects.length > 0) {
        for(let w = 0; w < qualityScoreWidget.projects.length; w++){

          let project = this.getProjectById(qualityScoreWidget.projects[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.PERFORMANCE_WIDGET == w.type;
          })[0];

          newWidget['project'] = qualityScoreWidget.projects[w];
          newWidget['displayName'] = project['name'] + ' project quality score';

          // let newWidget:any = {
          //   type: Constants.PERFORMANCE_WIDGET,
          //   name: 'Quality score widget',
          //   image: '/assets/images/graph-widget.jpg',
          //   project: qualityScoreWidget.projects[w],
          //   displayName: project['name'] + ' project quality score'
          // };

          this.selectedWidgets.push(newWidget);
        }
      }

      /*For campaign*/
      if (qualityScoreWidget.campaigns != undefined && qualityScoreWidget.campaigns.length > 0) {
        for(let w = 0; w < qualityScoreWidget.campaigns.length; w++){

          let campaign = this.getCampaignById(qualityScoreWidget.campaigns[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.PERFORMANCE_WIDGET == w.type;
          })[0];

          newWidget['campaign'] = qualityScoreWidget.campaigns[w];
          newWidget['displayName'] = campaign['name'] + ' campaign quality score';

          // let newWidget:any = {
          //   type: Constants.PERFORMANCE_WIDGET,
          //   name: 'Quality score widget',
          //   image: '/assets/images/graph-widget.jpg',
          //   campaign: qualityScoreWidget.campaigns[w],
          //   displayName: campaign['name'] + ' campaign quality score'
          // };

          this.selectedWidgets.push(newWidget);
        }
      }
    }
    /*To distribute quality score widget end */

    /*To distribute sentiment score distribution widget start */
    let sentimentScoreDistributionWidget:any = this.selectedWidgets.filter((w)=>{
      return w.type == Constants.SENTIMENT_SCORE_DISTRIBUTION;
    })[0];

    this.selectedWidgets = this.selectedWidgets.filter((w)=>{
      return w.type != Constants.SENTIMENT_SCORE_DISTRIBUTION;
    });

    if (sentimentScoreDistributionWidget != undefined) {

      /*For project*/
      if (sentimentScoreDistributionWidget.projects != undefined && sentimentScoreDistributionWidget.projects.length > 0) {
        for(let w = 0; w < sentimentScoreDistributionWidget.projects.length; w++){

          let project = this.getProjectById(sentimentScoreDistributionWidget.projects[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.SENTIMENT_SCORE_DISTRIBUTION == w.type;
          })[0];

          newWidget['project'] = sentimentScoreDistributionWidget.projects[w];
          newWidget['displayName'] = project['name'] + ' project sentiment score distribution';

          // let newWidget:any = {
          //   type: Constants.SENTIMENT_SCORE_DISTRIBUTION,
          //   name: 'Sentiment score distribution',
          //   image: '/assets/images/graph-widget.jpg',
          //   project: sentimentScoreDistributionWidget.projects[w],
          //   displayName: project['name'] + ' project sentiment score distribution'
          // };

          this.selectedWidgets.push(newWidget);
        }
      }

      /*For campaign*/
      if (sentimentScoreDistributionWidget.campaigns != undefined && sentimentScoreDistributionWidget.campaigns.length > 0) {
        for(let w = 0; w < sentimentScoreDistributionWidget.campaigns.length; w++){

          let campaign = this.getCampaignById(sentimentScoreDistributionWidget.campaigns[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.SENTIMENT_SCORE_DISTRIBUTION == w.type;
          })[0];

          newWidget['campaign'] = sentimentScoreDistributionWidget.campaigns[w];
          newWidget['displayName'] = campaign['name'] + ' campaign Sentiment score distribution';

          // let newWidget:any = {
          //   type: Constants.SENTIMENT_SCORE_DISTRIBUTION,
          //   name: 'Sentiment score distribution',
          //   image: '/assets/images/graph-widget.jpg',
          //   campaign: sentimentScoreDistributionWidget.campaigns[w],
          //   displayName: campaign['name'] + ' campaign Sentiment score distribution'
          // };

          this.selectedWidgets.push(newWidget);
        }
      }
    }
    /*To distribute sentiment score distribution widget end */


    /*To distribute sentiment score widget start */
    let sentimentScoreWidget:any = this.selectedWidgets.filter((w)=>{
      return w.type == Constants.SENTIMENT_SCORE_CALLS;
    })[0];

    this.selectedWidgets = this.selectedWidgets.filter((w)=>{
      return w.type != Constants.SENTIMENT_SCORE_CALLS;
    });

    if (sentimentScoreWidget != undefined) {

      /*For project*/
      if (sentimentScoreWidget.projects != undefined && sentimentScoreWidget.projects.length > 0) {
        for(let w = 0; w < sentimentScoreWidget.projects.length; w++){

          let project = this.getProjectById(sentimentScoreWidget.projects[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.SENTIMENT_SCORE_CALLS == w.type;
          })[0];

          newWidget['project'] = sentimentScoreWidget.projects[w];
          newWidget['displayName'] = project['name'] + ' project sentiment score';

          // let newWidget:any = {
          //   type: Constants.SENTIMENT_SCORE_CALLS,
          //   name: 'Sentiment score',
          //   image: '/assets/images/Group 808.jpg',
          //   project: sentimentScoreWidget.projects[w],
          //   displayName: project['name'] + ' project sentiment score'
          // };

          this.selectedWidgets.push(newWidget);
        }
      }

      /*For campaign*/
      if (sentimentScoreWidget.campaigns != undefined && sentimentScoreWidget.campaigns.length > 0) {
        for(let w = 0; w < sentimentScoreWidget.campaigns.length; w++){

          let campaign = this.getCampaignById(sentimentScoreWidget.campaigns[w])[0];

          let newWidget:any = JSON.parse(JSON.stringify(this.commonService.getAllWidgets())).filter((w)=>{
            return Constants.SENTIMENT_SCORE_CALLS == w.type;
          })[0];

          newWidget['campaign'] = sentimentScoreWidget.campaigns[w];
          newWidget['displayName'] = campaign['name'] + ' campaign Sentiment score';

          // let newWidget:any = {
          //   type: Constants.SENTIMENT_SCORE_CALLS,
          //   name: 'Sentiment score',
          //   image: '/assets/images/Group 808.jpg',
          //   campaign: sentimentScoreWidget.campaigns[w],
          //   displayName: campaign['name'] + ' campaign Sentiment score'
          // };

          this.selectedWidgets.push(newWidget);
        }
      }
    }
    /*To distribute sentiment score widget end */

    this.loadingService.apiStart();
    this.event.sendMessage({
      type: Constants.SENT_DASHBOARD_EVENT,
      data: this.selectedWidgets
    });
    this.loadingService.apiStop();
    this.closeOverlay('customizableDashboard');
  }

  closeSelectWidgetPopup(widget, index){
    this.event.sendMessage({
      type: Constants.OPEN_CONFIRMATION_OVERLAY,
      data: {
        heading: 'You will lose your selection. Proceed?',
        inline: false,
        callback: ()=>{
          $('#widgetForSelection' + index + ' a').dropdown("hide");
          let w = 0;
          for(w = 0; w < this.selectedWidgets.length; w++){
            if (this.selectedWidgets[w].type == widget.type) {
              let foundWidget = JSON.parse(JSON.stringify(this.selectedWidgets[w]));
              for(let m = 0; m < this.allWidgets.length; m++){
                let w1 = m;
                if (this.allWidgets[m].type == widget.type){

                  /*For set word cloud widget start*/
                  if (this.selectedWidgets[w].type == Constants.WORD_CLOUDE_WIDGET) {
                    if (this.allWidgets[m].projects == undefined) {
                      this.allWidgets[m].projects = [];
                    }
                    if (this.allWidgets[m].campaigns == undefined) {
                      this.allWidgets[m].campaigns = [];
                    }
                    if (this.allWidgets[m].calls == undefined) {
                      this.allWidgets[m].calls = [];
                    }

                    if (this.selectedWidgets[w].project != undefined) {
                      this.allWidgets[m].projects.push(this.selectedWidgets[w].project);
                    }

                    if (this.selectedWidgets[w].campaign != undefined) {
                      this.allWidgets[m].campaigns.push(this.selectedWidgets[w].campaign);
                    }

                    if (this.selectedWidgets[w].call != undefined) {
                      this.allWidgets[m].calls.push(this.selectedWidgets[w].call);
                    }
                    break;
                  }
                  /*For set word cloud widget end*/

                  /*For set quality score widget start*/
                  if (this.selectedWidgets[w].type == Constants.PERFORMANCE_WIDGET) {
                    if (this.allWidgets[w1].projects == undefined) {
                      this.allWidgets[w1].projects = [];
                    }
                    if (this.allWidgets[w1].campaigns == undefined) {
                      this.allWidgets[w1].campaigns = [];
                    }

                    if (this.selectedWidgets[w].project != undefined) {
                      this.allWidgets[w1].projects.push(this.selectedWidgets[w].project);
                    }

                    if (this.selectedWidgets[w].campaign != undefined) {
                      this.allWidgets[w1].campaigns.push(this.selectedWidgets[w].campaign);
                    }
                    break;
                  }
                  /*For set quality score widget end*/


                  /*For set sentiment score distribution widget start*/
                  if (this.selectedWidgets[w].type == Constants.SENTIMENT_SCORE_DISTRIBUTION) {
                    if (this.allWidgets[w1].projects == undefined) {
                      this.allWidgets[w1].projects = [];
                    }
                    if (this.allWidgets[w1].campaigns == undefined) {
                      this.allWidgets[w1].campaigns = [];
                    }

                    if (this.selectedWidgets[w].project != undefined) {
                      this.allWidgets[w1].projects.push(this.selectedWidgets[w].project);
                    }

                    if (this.selectedWidgets[w].campaign != undefined) {
                      this.allWidgets[w1].campaigns.push(this.selectedWidgets[w].campaign);
                    }
                    break;
                  }
                  /*For set sentiment score distribution widget end*/


                  /*For set sentiment score widget start*/
                  if (this.selectedWidgets[w].type == Constants.SENTIMENT_SCORE_CALLS) {
                    if (this.allWidgets[w1].projects == undefined) {
                      this.allWidgets[w1].projects = [];
                    }
                    if (this.allWidgets[w1].campaigns == undefined) {
                      this.allWidgets[w1].campaigns = [];
                    }

                    if (this.selectedWidgets[w].project != undefined) {
                      this.allWidgets[w1].projects.push(this.selectedWidgets[w].project);
                    }

                    if (this.selectedWidgets[w].campaign != undefined) {
                      this.allWidgets[w1].campaigns.push(this.selectedWidgets[w].campaign);
                    }
                    break;
                  }
                  /*For set sentiment score widget end*/

                  this.allWidgets[m].projects = foundWidget.projects;
                  this.allWidgets[m].campaigns = foundWidget.campaigns;
                  this.allWidgets[m].calls = foundWidget.calls;
                }
              }
              break;
            }
          }

          if (w == this.selectedWidgets.length) {
            widget.projects = [];
            widget.campaigns = [];
            widget.calls = [];
          }
        }
      }
    });
  }

  onClickedSaveWidgetPopup(widget, index){
    $('#widgetForSelection' + index + ' a').dropdown("hide");
  }

  getProjects = () => {
    this.dashboardService
    .getProjects(moment("2020-09-01"), moment())
    .toPromise()
    .then((data: any) => {
      this.allProjects = data['projects'];
    })
    .catch((err) => {
    });
  }

  getCampaigns = () => {
    this.commonService
    .getCampaigns(moment("2020-09-01"), moment())
    .toPromise()
    .then((data: any) => {
      this.allCampaigns = data['campaigns'];
    })
    .catch((err) => {
    });
  }

  getCalls = () =>{

    this.commonService.getCalls("", moment("2020-09-01"), moment())
    .toPromise()
    .then((data: any) => {
      this.allCalls = data.calls;
    })
    .catch((err) => {
    })
  } 

  getTeams() {
    this.commonService.getTeamList(moment("2020-09-01"), moment())
      .toPromise()
      .then((data: any) => {
        this.allTeams = data.teams;
      })
      .catch((err) => {
      });
  }

  getAgents() {
    this.dashboardService.getAgents()
      .toPromise()
      .then((data: any) => {
        data.agents.forEach(agent=>{
          agent['agentname'] = agent.first_name + ' ' + agent.last_name;
        });
        this.allAgents = data.agents;
      })
      .catch((err) => {
      });
  }
}
