<section class="drawer" id="vulnerabilityDrawer">
  <div class="heading">Vulnerability index dictionary</div>
  <p class="info">Target phrases used in the past.</p>

  <!-- Add phrase start -->
  <div class="section-began">
    <div class="sub-heading">
      ADD NEW PHRASE
    </div>
    <div class="phrase-stamp">
      <div class="row">
        <div class="col-sm-8">
          <input type="text" name="" placeholder="Enter Phrase here" >
        </div>
        <div class="col-sm-4">
          <input type="text" name="" placeholder="5" class="weight-box" >
          <!-- <a href="javascript:void(0)" class="remove" >remove</a> -->
          <a href="javascript:void(0)" class="done">
              <i class="fa fa-check"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Add phrase end -->

  <!-- phrase list start -->
  <div class="section-began">
    <div class="row">
      <div class="col-sm-8">
        <div class="sub-heading">
          PAST PHRASE
        </div>
      </div>
      <div class="col-sm-4">
        <div class="sub-heading">
          WT
        </div>
      </div>
    </div>

    <div class="phrase-list slim-scrollbar">
      <div class="row">
        <div class="col-sm-12" *ngFor="let phrase of [0,1,2,4,5,6,7,8,9]" >
          <div class="phrase-stamp">
            <div class="row">
              <div class="col-sm-8">
                <input type="text" name="" placeholder="Enter Phrase here" >
              </div>
              <div class="col-sm-4">
                <input type="text" name="" placeholder="5" class="weight-box" >
                <a href="javascript:void(0)" class="remove" >remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- phrase list end -->

  <!-- footer start -->
  <div class="section-began footer-section">
    <div class="row">
      <div class="col-sm-12">
        <button class="cancel-btn" (click)="closeDrawer()" >
          CANCEL
        </button>
        <button class="save-btn" (click)="closeDrawer()">
          SAVE DETAILS
        </button>
      </div>
    </div>
  </div>
  <!-- footer end -->

</section>