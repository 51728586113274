<div class="row no-margin top-space">
    <div class="main-content">
        <div class="product-container">
            <div class="keywords-container">
                <div class="title">
                    Focus Keywords
                </div>
                <div class="date-picker">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18 20">
                            <path id="ic_event_busy_24px" d="M19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z" transform="translate(-3 -1)" fill="#909090"/>
                        </svg>                          
                    </div>
                    <div class="label">
                        Select display period
                    </div>
                </div>
                
                <div class="cards-container slim-scrollbar">
                    <div *ngFor="let word of productManagerKeywords" class="card">
                        <div class="keyword">
                            {{word.label}}
                        </div>
                        <div class="graph-container row no-margin">
                            <div class="graph">
                                <ngx-charts-pie-chart
                                [view]="[80, 80]"
                                [scheme]="{domain: ['#909090', '#58C16B']}"
                                [results]="word.series"
                                [margins]="[0,0,0,0]"
                                [doughnut]="true"
                                [legendPosition] = "'center'"
                                [legendTitle]="''"
                                [labelFormatting]="pieChartLabel.bind(this, series)"
                                [animations]="false" (select)="onGraphFocusKeywordSelect(word.label)">
                              </ngx-charts-pie-chart>
                              <div class="label">
                                    <div class="value">
                                        {{((word.call_count / totalCalls) * 100).toFixed(0)}}%
                                    </div>
                                    <div class="key">
                                        Mentions
                                    </div>
                              </div>
                            </div>
                            <div class="stats">
                                <div class="value">
                                    {{word.call_count}}
                                </div>
                                <div class="key">
                                    out of {{totalCalls}} calls
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-part row no-margin">
                <div class="segment">
                    <div class="title">
                        Sentiment Distribution
                        <div class="date-picker">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 18 20">
                                    <path id="ic_event_busy_24px" d="M19,3H18V1H16V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V8H19Z" transform="translate(-3 -1)" fill="#909090"/>
                                </svg>                          
                            </div>
                            <div class="label">
                                Select display period
                            </div>
                        </div>
                        <div class="graph" *ngIf="results[0].series != undefined && results[0].series.length > 0" >
                            <!-- [view]="view" -->
                            <ngx-charts-area-chart-stacked
                            [scheme]="colorScheme"
                            [results]="results"
                            [gradient]="true"
                            
                            [showGridLines]="false"
                            [xAxis]="true"
                            [yAxis]="true"
                            [legend]="false"
                            [showXAxisLabel]="false"
                            [showYAxisLabel]="false"
                            [curve]="curve"
                            (select)="onGraphCallsSelect($event)"
                            
                            >
                          </ngx-charts-area-chart-stacked>
                        </div>

                        <div class="last-row row no-margin">
                            <div class="main-container">
                                <div class="row">
                                    <div class="col-xs-6 col-sm-5" >
                                        <div class="switch-container">
                                            <div style="cursor: pointer;" class="tab active" [ngClass]="{'active' : positiveNegativeTab == 'positive'}" (click)="positiveNegativeTab='positive';getPositiveNegativeKeywords()" >
                                                Positive context
                                            </div>
                                            <div style="cursor: pointer;" class="tab" [ngClass]="{'active' : positiveNegativeTab == 'negative'}" (click)="positiveNegativeTab='negative';getPositiveNegativeKeywords()" >
                                                Negative context
                                            </div>
                                        </div>
                                        <div class="table-container">
                                            <div class="table slim-scrollbar">
                                                <table>
                                                    <tr>
                                                        <th>KEYWORDS</th>
                                                        <th>MENTIONS</th>
                                                        <th>CALLS</th>
                                                    </tr>
                                                    <tr *ngFor="let keyword of positiveNegativeKeywords">
                                                        <td>
                                                            {{keyword.keyword}}
                                                        </td>
                                                        <td>
                                                            {{keyword.mentions}}
                                                        </td>
                                                        <td>
                                                            {{keyword.calls}}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4" >
                                        <div class="switch-container">
                                            <div class="tab">
                                                Mention context
                                            </div>
                                        </div>
                                        <div class="table-container">
                                            <div class="table slim-scrollbar">
                                                <table>
                                                    <tr>
                                                        <th>Keyword</th>
                                                        <th>Count</th>
                                                    </tr>
                                                    <tr *ngFor="let keyword of mentionKeywords">
                                                        <td>
                                                            {{keyword.label}}
                                                        </td>
                                                        <td>
                                                            {{keyword.count}}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Mention keywords end -->

                                    <!-- Word cloud start -->
                                    <div class="col-xs-2 col-sm-3" >
                                        <div class="switch-container">
                                            <div class="tab">
                                                Word cloud of context
                                            </div>
                                        </div>

                                        <cxr-word-cloud *ngIf="wordData.length > 0" (onClick)="onClickWordCloud($event)" [data]="wordData" ></cxr-word-cloud>

                                    </div>
                                    <!-- Word cloud end -->
                                </div>
                            </div>
                            <!-- <div class="word-cloud">
                                <div class="title">
                                    Word cloud of context
                                </div>
                                <div class="graph">
                                    <angular-tag-cloud
                                        [data]="wordData"
                                        [width]="options.width"
                                        [height]="options.height"
                                        [overflow]="options.overflow"
                                        [strict]="true"
                                    >
                                    </angular-tag-cloud>
                                </div>
                            </div> -->
                            <!-- <div class="competitor">
                                <div class="title">
                                    Competitor Mention
                                </div>
                                <div class="graph">
                                    <ngx-charts-pie-chart
                                    [scheme]="colorSchemeCompetitor"
                                    [results]="dataCompetitor"
                                    [legend]="false"
                                    [explodeSlices]="false"
                                    [labels]="true"
                                    [doughnut]="false"
                                    [gradient]="true"
                                    [legendTitle] = "''"
                                    >
                                    </ngx-charts-pie-chart>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>