<div class="row no-margin top-space">
  <div class="main-content">

    <div class="welcome-section">
      <div class="row">
        <div class="col-sm-8">
          <div class="text">
            Welcome to CX Review. 
            <!-- You are viewing date for -->
            <input class="date-picker-input" placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
            [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
            [owlDateTime]="daterange">
            <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
          </div>
        </div>
        <div class="col-sm-4 right-part d-none">
          <button [routerLink]="'/onboarding/projectdetails'" class="stnd-bg-btn">ADD NEW PROJECTS</button>
        </div>
      </div>
    </div>

    <div class="project-overview" *ngIf="false" >
      <div class="title">
        PROJECT OVERVIEW
      </div>
      <!-- <div class="date-picker">
        <span style="margin-right: 10px"> Select Date Range</span>
        <input placeholder="Select Dates" [owlDateTimeTrigger]="daterange" [max]="currentDate"
          [(ngModel)]="dateRange" [selectMode]="'range'" (ngModelChange)="onDateChange($event)"
          [owlDateTime]="daterange">
        <owl-date-time [pickerType]="'calendar'" #daterange></owl-date-time>
      </div> -->
      <div class="main-content" *ngIf="isCxrOverviewDataLoading">
        <cxr-loading></cxr-loading>
      </div>
      <div *ngIf="!isCxrOverviewDataLoading" >
        <cxr-overview [data]="cxrOverviewData"></cxr-overview>
      </div>
    </div>


    <div class="campaigns">
      <div class="title">
        ALL PROJECTS
      </div>
      <div class="">

        <div class="main-content" *ngIf="isProjectLoading">
          <cxr-loading></cxr-loading>
        </div>
        <div *ngIf="!isProjectLoading" >

          <div class="text-muted text-center" *ngIf="projects.length == 0">No Projects found</div>

          <div class="row">
            <div class="col-sm-3" [ngClass]="{' col-sm-4': manageProject}" *ngFor="let project of projects; let i= index">
              <!-- oldcode -->
              <!-- <cxr-campaign [data]="project" (click)="openCampaign(project.id)">
              </cxr-campaign> -->
              <cxr-campaign [data]="project" (getAllProjects)="getAllProjects()"></cxr-campaign>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="project-overview" *ngIf="false">
      <div class="metrics">
        <div class="metric-card" *ngFor="let overview of overviewData">
          <div class="top">
            <div class="metric-title">
              {{ overview.checkname | titlecase }}
            </div>
            <div class="icon green">
              <i class="fa fa-arrow-up"></i>
            </div>
          </div>

          <div class="bottom">
            <div class="attribute">
              <div class="value">
                {{ overview.pass }}
              </div>
              <div class="key">
                Pass
              </div>
            </div>
            <div class="attribute">
              <div class="value">
                {{ overview.fail }}
              </div>
              <div class="key">
                Fail
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>