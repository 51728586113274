import { Component, OnInit,  Input, Output, EventEmitter } from '@angular/core';

declare var $:any;

@Component({
  selector: 'cxr-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss']
})
export class WordCloudComponent implements OnInit {

  constructor() { }

  @Input() data: any = [];
  @Output() onClick = new EventEmitter<any>();

  contWidth:any;
  contHeight:any;

  ngOnInit(): void {
    this.contWidth = $('#wordCloudCont').width();
    this.contHeight = this.contWidth;
    $('#wordCloudCont').height(this.contHeight);
  }

  setTop(index){
    switch (index) {
      case 0:
        return this.contHeight*0.30;
      case 1:
        return this.contHeight*0.20;
      case 2:
        return this.contHeight*0.20;
      case 3:
        return this.contHeight*0.52;
      case 4:
        return this.contHeight*0.75;
      case 5:
        return this.contHeight*0.73;
      case 6:
        return this.contHeight*0.05;
      case 7:
        return this.contHeight*0.55;
      case 8:
        return this.contHeight*0.1;
    }
  }

  setLeft(index){
    switch (index) {
      case 0:
        return this.contHeight*0.30;
      case 2:
        return this.contHeight*0.75;
      case 3:
        return this.contHeight*0.75;
      case 4:
        return this.contHeight*0.55;
      case 5:
        return this.contHeight*0.30;
      case 6:
        return this.contHeight*0.30;
      case 7:
        return this.contHeight*0.10;
      case 8:
        return this.contHeight*0.58;
    }
  }

  setSize(index){
    switch (index) {
      case 0:
        return this.contHeight*0.40;
      case 1:
        return this.contHeight*0.28;
      case 2:
        return this.contHeight*0.25;
      case 3:
        return this.contHeight*0.22;
      case 4:
        return this.contHeight*0.20;
      case 5:
        return this.contHeight*0.19;
      case 6:
        return this.contHeight*0.18;
      case 7:
        return this.contHeight*0.15;
      case 8:
        return this.contHeight*0.10;
    }
  }

  onItemClick(item){
    this.onClick.emit(item);
  }
}
