import { Component, OnInit } from '@angular/core';
declare var $: any;
import { DashboardService } from '../../services/dashboard.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

interface ProjectsOverview {
  "sno": number,
  "greetingcheckpass": number,
  "greetingcheckfail": number,
  "nametakencheckpass": number,
  "nametakencheckfail": number,
  "verificationcheckpass": number,
  "verficationcheckfail": number,
  "confirmationcheckpass": number,
  "confirmationcheckfail": number,
  "offerdirectconnectioncheckpass": number,
  "offerdirectconnectioncheckfail": number,
  "endingcheckpass": number,
  "endingcheckfail": number,
  "duration": number
}
@Component({
  selector: 'cxr-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  constructor(
    private dashBoardService: DashboardService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  start_date:any;
  end_date:any;
  manageProject: any = false;

  ngOnInit(): void {
    this.setDate('today', '24 Hours');
    this.toggleDate();
    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];

    this.getAllProjects();
    this.getProjectOverview();
    this.getOverview();

    this.route.queryParams.subscribe(
      params => {
        if (params['edit'] == 'manageProject') {
          this.manageProject = true;
        }
        else {
          // this.router.navigateByUrl('/project/' + id);
        }
      }
    )
  }

  dateRange = [];
  cxrOverviewData = {};
  isCxrOverviewDataLoading = true;
  isProjectLoading = true;

  samplename = "Sample Project #1";
  samplename1 = "Sample Project #2";
  samplename2 = "Sample Project #3";
  samplename3 = "Sample Project #4";
  dateDisplay = false;
  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period"
  projects = [];
  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  overviewData = null;

  toggleDate = () => {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }
  }

  setDate = (dateSelected, dateSelectedDisplay) => {
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    this.toggleDate();
  }

  getProjectOverview = () => {
    this.commonService.getAllProjectOverview(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.overviewData = data.checks;
      })
      .catch((err) => {

      });

  }

  onDateChange(dates) {
    this.start_date = dates[0];
    this.end_date = dates[1];
    this.getAllProjects();
    this.getProjectOverview();
    this.getOverview();
  }

  getAllProjects = () => {

    this.isProjectLoading = true;

    this.dashBoardService.getProjects(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        // console.log(data.projects);
        data.projects.forEach((project)=>{
          project['isProject'] = true;
        });
        this.projects = data.projects;
        this.isProjectLoading = false;
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });

  }

  getOverview = () => {

    this.isCxrOverviewDataLoading = true;

    this.commonService.getCallStats(this.start_date, this.end_date).toPromise().then((data: any) => {
      this.cxrOverviewData = data;
      this.isCxrOverviewDataLoading = false;
    }).catch((err) => {
      this.isCxrOverviewDataLoading = false;
    });
  }

  openCampaign(id) {
    if (this.manageProject == true) {
      this.router.navigateByUrl('/admin/project/details/' + id);
    }
    else {
      this.router.navigateByUrl('/project/' + id);
    }

  }



}
