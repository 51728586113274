import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[cxrAutoSize]'
})
export class AutoSizeDirective {

  constructor(private el: ElementRef) {
    setTimeout(()=>{
      this.el.nativeElement.style.minHeight = "44px";
      this.el.nativeElement.style.height = "0px";
      this.el.nativeElement.style.height = (this.el.nativeElement.scrollHeight)+"px";
    },1000);
  }

  @HostListener('keyup', ['$event']) public onKeyup(event): void {
    this.el.nativeElement.style.height = "0px";
    this.el.nativeElement.style.height = (this.el.nativeElement.scrollHeight)+"px";
  }

}
