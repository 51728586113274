import { environment } from '../../environments/environment';

export class ServerConstants {
  
  public static SIGNUP = environment.endpoint + 'signup';
  public static LOGIN = environment.endpoint + 'login';
  public static GET_CURRENT = environment.endpoint + 'current';
  public static GET_CALLS = environment.endpoint + 'calls';
  public static GET_SINGLE_CALLS = environment.endpoint + 'calls';
  public static GET_CALLS_COUNT = environment.endpoint + 'callcount';
  public static GET_ANALYTICS = environment.endpoint + 'analytics';
  public static GET_CURRENT_USER = environment.endpoint + 'current_user';
  public static GET_PROJECTS = environment.endpoint + 'projects';
  public static GET_CAMPAIGNS = environment.endpoint + 'campaigns';

  // vioce verify 
  public static VOICE_VERIFY = environment.PY_URL + 'voice-verify';
  // public static STATUS = environment.BASE_URL + 'api/v2/status';
  public static STATUS = environment.endpoint + 'api/v2/status';
  // public static CREATE_STREAM = environment.BASE_URL + 'api/v2/stream/http';
  public static CREATE_STREAM = environment.endpoint + 'api/v2/stream/http';
  public static SEND_DATA_TO_STREAM = environment.PY_URL + 'add-data-stream';
  public static ENROLL = environment.PY_URL + 'enroll';
  public static TEST = environment.VO_URL + 'login';
  // public static TEST = environment.endpoint + 'phonexiaLogin';
}
