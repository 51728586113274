import { Component, OnInit } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../../services/loading.service';
import * as moment from 'moment';

declare var $:any;

@Component({
  selector: 'cxr-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss']
})
export class KeywordsComponent implements OnInit {

  campaigns:any = [];
  keywordsData:any = {
    campaign: '',
    keywords: [{}]
  };
  campaignData:any = {};
  totalWeight = 0;

  selectedCampaign;

  constructor(private commonService: CommonService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.getCampaigns();
  }

  openDrawer(){
    // $('#vulnerabilityDrawer').addClass('open');
    // $('body').addClass('modal-open');
    if (this.keywordsData.keywords.length < 6) {
      this.keywordsData.keywords.push({});
    }else{
      window.alert("You can not add more than more than 6 phrase");
    }
  }


  getCampaigns() {
    this.loadingService.apiStart();
    this.commonService.getCampaigns(moment("2000-01-01"), moment())
    .toPromise()
    .then((data: any) => {
      this.loadingService.apiStop();
      this.campaigns = data.campaigns;
    }).catch((err) => {
      this.loadingService.apiStop();
    });
  }


  onSelectCampaign(campaign){
    this.getQualityKeywords(campaign);
  }

  getQualityKeywords(id) {
    this.loadingService.apiStart();
    this.selectedCampaign = id;
    this.commonService.getQualityKeywords(id)
    .toPromise()
    .then((data: any) => {
      this.loadingService.apiStop();
      this.keywordsData.keywords = JSON.parse(JSON.stringify(data.keywords));
      this.keywordsData.quality_threshold = JSON.parse(JSON.stringify(data.quality_threshold));
      this.keywordsData.keywords.forEach(function(item){
        item.count = Math.round(item.count/item.weight);
      });

      if (this.keywordsData.keywords.length == 0) {
        this.keywordsData.keywords.push({});
      }
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }

  saveKeywords(){

    this.loadingService.apiStart();
    this.keywordsData.keywords.forEach((item)=>{
      item.campaignid = this.selectedCampaign;
    });

    let payload = {
      keywords: this.keywordsData.keywords,
      campaignid: this.selectedCampaign,
      quality_threshold: this.keywordsData.quality_threshold
    };

    this.commonService.addKeyWords(payload)
    .toPromise()
    .then((data: any) => {
      this.loadingService.apiStop();
      if (data.status == "success") {
        window.alert("Added successfully");
      }
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }

  onChangeWeight(){
    this.totalWeight = 0;
    for(let i = 0; i < this.keywordsData.keywords.length; i++){
      let keyword = this.keywordsData.keywords[i];
      if(keyword.score != undefined && keyword.score != null && !isNaN(parseInt(keyword.score))){
        this.totalWeight = this.totalWeight + parseInt(keyword.score);
      }
    }
  }

  removeKeyword(index){
    this.keywordsData.keywords.splice(index,1);
    this.onChangeWeight();
  }
}
