import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(private router: Router) { }

  setUserData(data){
    localStorage.setItem("CXRUser", JSON.stringify(data));
  }

  getUserData(){
    return JSON.parse(localStorage.getItem("CXRUser")) || {};
  }

  setOnbordingData(data){
    localStorage.setItem("CXROnbording", JSON.stringify(data));
  }

  getOnbordingData(){
    return JSON.parse(localStorage.getItem("CXROnbording")) || {};
  }

  isUserLoggedin(){
    let user = this.getUserData();
    return (user && user.token);
  }

  logout(){
    localStorage.setItem("CXRUser", null);
    localStorage.setItem("CXROnbording", null);
    this.router.navigate(['/login']);
  }

  setQualityData(data){
    localStorage.setItem("CXQuality", JSON.stringify(data));
  }

  getQualityData(){
    return JSON.parse(localStorage.getItem("CXQuality")) || {};
  }
}
