import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from '../services/user-data.service';
import { Constants } from '../constants/constants';
import { ProjectComponent } from '../modules/project/project/project.component';
import { CommonService } from '../services/common.service';
import { EventService } from './../services/event.service';
import { LoadingService } from './../services/loading.service';
import { ToastrService } from 'ngx-toastr';

let userRole: any = {};

export interface CanComponentDeactivate {
  confirm(): boolean;
}

@Injectable({
  providedIn: 'root'
})
	


export class AuthGuard implements CanActivate {

  urlRedirectMap = {
    /* Key says page URL*/
    /*Array contains index
      0 for ROOT
      1 for ADMIN
      2 for TEAM_LEADER
      3 for QUALITY MANAGER
      4 for AGENT
      5 for CC MANAGER
    */

    '/admin': [ 
      true, true, false, true, false, true
    ],
    '/vulnerabilityindex': [
      true, true, false, true, false, true
    ],
    '/keywords': [
      true, true, false, true, false, true
    ],
    '/product-manager':  [
      true, true, false, true, false, true
    ]
  };

  constructor(public userService: UserDataService, private router: Router,private commonService: CommonService,
    public event: EventService,
    private loadingService: LoadingService,
    private toastr: ToastrService,) {

    userRole[Constants.ROOT] = 0;
    userRole[Constants.ADMIN] = 1;
    userRole[Constants.TEAM_LEAD] = 2;
    userRole[Constants.QUALITY_MANAGER] = 3;
    userRole[Constants.AGENT] = 4;
    userRole[Constants.CALL_CENTER_MANAGER] = 5;

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let url: string = state.url;
    let userData = this.userService.getUserData();

    // return true;
    /*Manage authentication user logged in or not start*/
    switch(url){
      /*If user logged in then redirecting login page URL to the dashboard*/
      case '/':
      case '/login':
        if (this.userService.isUserLoggedin()) {
          this.router.navigate(['/dashboard']);
          return false;
        }
        break;
      /*Blocked all URLs if user not logged in and redirect it to on login page*/
      default:
        if (!this.userService.isUserLoggedin()) {
          this.router.navigate(['/login']);
          return false;
        }
    }
    /*Manage authentication user logged in or not end*/

    /*Manage role based access start*/
    /*If in urlRedirectMap the particular page has access only then the page will shown otherwise redirecting on dashboard*/
    if (this.urlRedirectMap[url] && (!this.urlRedirectMap[url][userRole[userData.role]])) {
      this.router.navigate(['/dashboard']);
        return false;
    }
    /*Manage role based access end*/

    return true;
  }

  canDeactivate(component: ProjectComponent): boolean {
    if (Object.keys(this.userService.getOnbordingData()).length === 0) {
      if (confirm('Are you sure you want to leave?')) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if (confirm('You will lose all your data. Are you sure you want to leave?')) {
        this.deleteProject();
        return true;
      } else {
        return false;
      }
    }
   
	}
	
	// delete project function
	deleteProject() {
		let deleteData: any = {};
		if (this.userService.getOnbordingData().projectId) {
      deleteData['projectid'] = this.userService.getOnbordingData().projectId;
		} 
		if (this.userService.getOnbordingData().campaingId) {
			deleteData['campid'] = this.userService.getOnbordingData().campaingId;
		}
		if (this.userService.getOnbordingData().teamId) {
			deleteData['teamid'] = this.userService.getOnbordingData().teamId;
		}
		let payload: any = {...deleteData };
		this.loadingService.apiStart();
		this.commonService.deleteApi(payload)
		.toPromise()
		.then((data: any) => {
			this.toastr.success("Created Data Delete successfully.");
			localStorage.setItem("CXROnbording", null);
			this.loadingService.apiStop();
		})
		.catch((err) => {
			this.loadingService.apiStop();
		});
	}
  
}

 