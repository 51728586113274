import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// const BASE_URL = environment.endpoint;
import * as moment from 'moment';
import { Constants } from '../constants/constants';

// const BASE_URL = environment.endpoint + 'api/';
const BASE_URL = environment.endpoint;

let days = 1;
// let days = 24;

@Injectable({
  providedIn: 'root'
})
export class CommonService {


  widgetList:any = [{
    type: Constants.PROJECT_WIDGET,
    name: 'Project Overview',
    image: '/assets/images/Project Overview.jpg',
    desc: 'Get an overview of all your projects'
  },{
    type: Constants.WORD_CLOUDE_WIDGET,
    name: 'Word Cloud',
    image: '/assets/images/Word Cloud.jpg',
    desc: 'Choose a Project to see an overview of the most spoken words.'
  },{
    type: Constants.CAMPAIGN_WIDGET,
    name: 'Campaign Overview',
    image: '/assets/images/Campaigns.jpg',
    'desc': 'Get an overview of all your campaigns.'
  },{
    type: Constants.PERFORMANCE_WIDGET,
    name: 'Quality Score',
    image: '/assets/images/Quality Score Widget.jpg',
    'desc': 'Analyze the performance of calls in chronological order'
  },{
    type: Constants.SENTIMENT_SCORE_CALLS,
    name: 'Sentiment Score',
    image: '/assets/images/Sentiment Score.jpg',
    'desc': 'See the Top 10 or Bottom 10 Sentiment Ranked Scores'
  },{
    type: Constants.SENTIMENT_SCORE_DISTRIBUTION,
    name: 'Sentiment Score Distribution',
    image: '/assets/images/Distribution.jpg',
    'desc': 'Analyze the distribution of sentiment scores over calls.'
  }/*,{
    type: Constants.VOICE_WIDGET,
    name: 'Comparative performance',
    image: '/assets/images/Group 813.jpg'
  },{
    type: Constants.PROJECT_OVERVIEW_WIDGET,
    name: 'Project Overview',
    image: '/assets/images/project-widget.jpg'
  },{
    type: Constants.TEAM_OVERVIEW_WIDGET,
    name: 'Team Overview',
    image: '/assets/images/graph-widget.jpg'
  }*/,{
    type: Constants.TEAM_WIDGET,
    name: 'Teams',
    image: '/assets/images/Teams.jpg',
    'desc': 'Analyze the best performing teams across your organization.'
  },{
    type: Constants.AGENT_WIDGET,
    name: 'Agents',
    image: '/assets/images/Agents.jpg',
    'desc': 'Analyze the best or worst performing teams to take quick action.'
  },{
    type: Constants.ACTIVITY_TRACKER_WIDGET,
    name: 'Activity Tracker',
    image: '/assets/images/Activity Tracker.jpg',
    'desc': 'Stay on top of feedback and comments on your calls with an update system.'
  },{
    type: Constants.LINE_UP_OF_CALL_WIDGET,
    name: 'LineUp Widget',
    image: '/assets/images/Line Up Widget.jpg',
    'desc': 'Monitor specific agents by checking their calls on a daily basis.'
  },{
    type: Constants.CUSTOM_WIDGET,
    name: 'Custom Widget',
    image: '/assets/images/Custom.jpg',
    'desc': 'Define your own Widget and choose your data points.'
  }];

  getAllWidgets(){
    return this.widgetList;
  }

  private endDate = moment();
  // private startDate = moment().subtract(days, "months");
  private startDate = moment("2020-09-01");
  constructor(
    private httpClient: HttpClient
  ) { }
  public toDaysConvertor(strToConvert) {
    switch (strToConvert) {
      case 'today': return 1;
      case 'last7days': return 7;
      case 'last30days': return 30;
      case 'last3months': return 90;
      case 'last6months': return 180;
    }
  }

  public getCalls(term, startDate, endDate) {
    return this.httpClient.get(BASE_URL + 'calls?start_date=' + moment(startDate).format('YYYY-MM-DD') + '&end_date=' + moment(endDate).format('YYYY-MM-DD') + term);
  }

  public getTeamOverview(start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'teams/overview?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }

  public getTeamList(start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'teams?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }
  
  public getSingleTeamList(id) {
    return this.httpClient.get(BASE_URL + 'singlecampaignsteams/' +id);
  }

  // public getTeam(pid = 1) {
  //   const dateObj = this.getStartEndDate();
  //   return this.httpClient.get(BASE_URL + 'teams/' + pid + '?start_date=' + moment(dateObj.startDate).format('YYYY-MM-DD') + '&end_date=' + moment(dateObj.endDate).format('YYYY-MM-DD'));
  // }
  public getTeam(pid) {
    const dateObj = this.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'teams/' + pid);
  }

  public getTeamCamp(pid = 1) {
    const dateObj = this.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'teamcamp/' + pid + '?start_date=' + moment(dateObj.startDate).format('YYYY-MM-DD') + '&end_date=' + moment(dateObj.endDate).format('YYYY-MM-DD'));
  }

  public getTeamLeader(pid = 1) {
    const dateObj = this.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'teamleader/' + pid + '?start_date=' + moment(dateObj.startDate).format('YYYY-MM-DD') + '&end_date=' + moment(dateObj.endDate).format('YYYY-MM-DD'));
  }

  public getAllProjectOverview(start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'projects/overview?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }
  public getProjectOverview(pid = 1, start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'projects/' + pid +'?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }
  public getProject(pid = 1) {
    const dateObj = this.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'projects/' + pid + '?start_date=' + moment(dateObj.startDate).format('YYYY-MM-DD') + '&end_date=' + moment(dateObj.endDate).format('YYYY-MM-DD'));
  }

  public getScoreStats() {
    const dateObj = this.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'analytics/scores?start_date=' + moment(dateObj.startDate).format('YYYY-MM-DD') + '&end_date=' + moment(dateObj.endDate).format('YYYY-MM-DD'));
  }

  // public getCallStats(start_date, end_date) {

  //   return this.httpClient.get(BASE_URL + 'analytics/calls?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  // }

  public getCallStats(start_date, end_date) {

    return this.httpClient.get(BASE_URL + 'analytics?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }

  public getCall(id) {
    const dateObj = this.getStartEndDate();
    return this.httpClient.get(BASE_URL + 'calls/' + id + '?start_date=' + moment(dateObj.startDate).format('YYYY-MM-DD') + '&end_date=' + moment(dateObj.endDate).format('YYYY-MM-DD'));
  }

  // public getCampaign(id, start_date, end_date) {
  //   return this.httpClient.get(BASE_URL + 'campaigns/' + id + '?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  // }

  public getCampaign(term, start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'calls/' + '?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD') + term);
  }
  
  public getQualityKeywords(id) {
    return this.httpClient.get(BASE_URL + 'qualitykeywords/' + id);
  }

  public getVulnerabilityKeywords(id) {
    return this.httpClient.get(BASE_URL + 'vulnerabilitykeywords/' + id);
  }

  public getAgentData(id, start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'agents/' + id);
  }

  public getCampaigns(start_date, end_date) {
    return this.httpClient.get(BASE_URL + 'campaigns?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }

  public postCamaignsData(payload) {
    return this.httpClient.post(BASE_URL + 'campaigns', payload);
  }

  public updateCamaignsData(payload) {
    return this.httpClient.put(BASE_URL + 'editcampaigns', payload);
  }

  public addKeyWords(payload) {
    return this.httpClient.post(BASE_URL + 'keywords', payload);
  }

  public addvulnerabilityKeyWords(payload) {
    return this.httpClient.post(BASE_URL + 'vulnerabilitykeywords', payload);
  }

  public addComment(payload) {
    return this.httpClient.post(BASE_URL + 'comments', payload);
  }

  public likeComment(payload) {
    return this.httpClient.post(BASE_URL + 'comment_like', payload);
  }

  public UpdateQuality(payload) {
    return this.httpClient.post(BASE_URL + 'qualityscoreup', payload);
  }

  public addCallsIdByQc(payload) {
    return this.httpClient.post(BASE_URL + 'quality', payload);
  }

  public getCallsByQc(term, startDate, endDate) {
    return this.httpClient.get(BASE_URL + 'quality?start_date=' + moment(startDate).format('YYYY-MM-DD') + '&end_date=' + moment(endDate).format('YYYY-MM-DD') + term);
  }

  public changeReviewStatus(payload) {
    return this.httpClient.post(BASE_URL + 'review', payload);
  }

  public Postsearchkeywords(payload) {
    return this.httpClient.post(BASE_URL + 'searchkeywords' , payload);
  }

  public getTranscriptJson(url) {
    return this.httpClient.get(url, {
      /* other options here */
      responseType: 'text'
    });
  }
  public getStartEndDate() {
    return {
      endDate: this.endDate,
      startDate: this.startDate
    }
  }

  public setStartEndDate(start, end) {
    // this.startDate = start;
    // this.endDate = end;
    return {};
  }

  public getRatingClass(score) {
    let rating = this.getRating(score);
    if (rating == "L0") {
      return "good";
    } else if (rating == "L1") {
      return "good";
    } else if (rating == "L2") {
      return "average";
    } else if (rating == "L3") {
      return "average";
    } else if (rating == "L4") {
      return "bad";
    } else if (rating == "L5") {
      return "bad";
    }
  }

  public getRating(score) {
    if (score > 90) {
      return "L0";
    } else if (score > 75) {
      return "L1";
    } else if (score > 60) {
      return "L2";
    } else if (score > 45) {
      return "L3";
    } else if (score > 0) {
      return "L4";
    } else {
      return "L5";
    }
  }

  /*Return string of time since*/
  timeSince(timestamp){

    if(timestamp == null || timestamp == undefined) {
      timestamp = new Date().getTime();
    }

    timestamp = new Date(timestamp).getTime();

    let seconds = Math.floor((new Date().getTime() - timestamp) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + "y";
    }

    interval = Math.floor(seconds / 2592000);

    if (interval >= 1) {
      return interval + "m";
    }

    interval = Math.floor(seconds / 86400);

    if (interval >= 1) {
      return interval + "d";
    }

    interval = Math.floor(seconds / 3600);

    if (interval >= 1) {
      return interval + "h";
    }

    interval = Math.floor(seconds / 60);

    if (interval >= 1) {
      return interval + "min";
    }

    return Math.floor(seconds) + "s";
  }

  /*Returning date 1/12/2020 this formate*/
  getFormatedDateDDMMYYYY(date){
    if(date == undefined || date == null || date == '') {
      return '';
    }
    let d = new Date(date);
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
  }


  getDuration(duration) {
    return this.toHHMMSS(duration);
  }

  toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":")
  }

  public getPositiveKeywordsCheck() {
    return this.httpClient.get(BASE_URL + 'positivechecks');
  }

  public getNegativeKeywordsCheck() {
    return this.httpClient.get(BASE_URL + 'negativechecks');
  }

  public getProductManagerKeywords() {
    return this.httpClient.get(BASE_URL + 'productmanagerkeywords');
  }

  public getMentionKeywords() {
    return this.httpClient.get(BASE_URL + 'mentionkeywords');
  }

  public getWordCloud(term) {
    return this.httpClient.get(BASE_URL + 'word_cloud' + term);
  }

  public getLoggedInUser() {
    return this.httpClient.get(BASE_URL + 'current_user');
  }

  public changeTheme(payload) {
    return this.httpClient.post(BASE_URL  + 'change_theme', payload);
  }

  public updateDashboard(payload) {
    return this.httpClient.post(BASE_URL  + 'update_dashboard', payload);
  }

  public getSentimentScore(term) {
    return this.httpClient.get(BASE_URL  + 'analytics/sentiment_score' + term );
  }

  public getLineUpCalls(term) {
    return this.httpClient.get(BASE_URL  + 'line_up_calls' + term );
  }


  public getActivityTracker() {
    return this.httpClient.get(BASE_URL  + 'notifications');
  }

  public getSentimentScoreCalls(term) {
    return this.httpClient.get(BASE_URL  + 'sentiment_calls' + term );
  }

  public createProject(payload) {
    return this.httpClient.post(BASE_URL  + 'projects', payload);
  }

  public checkProject(term) {
    return this.httpClient.get(BASE_URL  + 'checkprojects?' + term);
  }

  public updateProject(payload) {
    return this.httpClient.put(BASE_URL  + 'editprojects', payload);
  }

  public createCampaign(payload) {
    return this.httpClient.post(BASE_URL  + 'campchecks', payload);
  }

  public checkCampaign(term) {
    return this.httpClient.get(BASE_URL  + 'checkcampaigns?' + term);
  }

  public updateCampaign(payload) {
    return this.httpClient.put(BASE_URL  + 'campchecks', payload);
  }

  public addCall(payload) {
    return this.httpClient.post(BASE_URL  + 'addCall', payload);
  }

  public findAgent(payload) {
    return this.httpClient.post(BASE_URL  + 'find_agent', payload);
  }

  public checkCall(payload) {
    return this.httpClient.post(BASE_URL  + 'check_call_audio_file', payload);
  }

  public getUsers(term) {
    return this.httpClient.get(BASE_URL  + 'list_users' + term);
  }

  public createTeam(payload) {
    // return this.httpClient.post(BASE_URL  + 'teams', payload);
    return this.httpClient.post(BASE_URL  + 'createteams', payload);
  }

  public checkTeam(term) {
    // return this.httpClient.post(BASE_URL  + 'teams', payload);
    return this.httpClient.get(BASE_URL  + 'checkteams?' + term);
  }

  public createTeamSelected(payload) {
    return this.httpClient.post(BASE_URL  + 'teamslist', payload);
  }

  public updateTeam(payload) {
    return this.httpClient.put(BASE_URL  + 'editteams', payload);
  }

  public createAgent(payload) {
    return this.httpClient.post(BASE_URL  + 'create_agent', payload);
  }

  public getLeader() {
    return this.httpClient.get(BASE_URL  + 'teamsleader' );
  }

  public getReview(id) {
    return this.httpClient.get(BASE_URL  + 'reviewdetails/' + id);
  }

  public getpharsesearch(id) {
    return this.httpClient.get(BASE_URL  + 'checkanalysis/' + id);
  }


  public deleteApi(payload) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers: headers,
      body: payload 
    };
    return this.httpClient.delete(BASE_URL  + 'deleteapi' ,options);
  }

  public deleteSingleProject(id) {
    return this.httpClient.delete(BASE_URL  + 'deleteproject/' + id);
  }

  public deleteSingleCamp(id) {
    return this.httpClient.delete(BASE_URL  + 'deletecamp/' + id);
  }

  public deleteSingleTeam(id) {
    return this.httpClient.delete(BASE_URL  + 'deleteteam/' + id);
  }

// testing API
  public upload(payload,id ) {
    return this.httpClient.post(BASE_URL  + 'upload/' + id, payload,{
      reportProgress: true,
      observe: 'events'
    });
  }

}

