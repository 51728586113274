<section [ngClass]="projectTheme" >
    <cxr-top *ngIf="!hideTop" ></cxr-top>

    <div *ngIf="!hideTop && false" class="sidebar slim-scrollbar">
        <cxr-side></cxr-side>
    </div>

    <div class="main-content" [ngClass]="{'is-top': !hideTop}" id="projectMainContent">
        <router-outlet></router-outlet>
    </div>
</section>

<!-- Confirmation overlay start -->
<div class="confirmation-overlay" *ngIf="isConfirmationOverlayOpen">
  <div *ngIf="confirmationOverlayData.inline" class="inline">
    <h2>{{confirmationOverlayData.heading}}</h2>
    <button class="btn-hover" (click)="confirmation(true)">Yes</button>
    <button class="btn-hover" (click)="confirmation(false)" >No</button>
  </div>
  <div *ngIf="!confirmationOverlayData.inline" class="block">
    <h2>{{confirmationOverlayData.heading}}</h2>
    <button class="btn-hover" (click)="confirmation(true)">Yes</button>
    <button class="btn-hover" (click)="confirmation(false)" >No</button>
  </div>
</div>
<!-- Confirmation overlay end -->
