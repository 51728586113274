import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'cxr-transcriptsearch',
  templateUrl: './transcriptsearch.component.html',
  styleUrls: ['./transcriptsearch.component.scss']
})
export class TranscriptsearchComponent implements OnInit {
  

  transcriptCheck: any =  {newCheck : [{ checkName : '',keyword : [{value : ''}]}] };

    
  constructor(private fb: FormBuilder,
    private commonService: CommonService,
    private loadingService: LoadingService,

  ) { 
  }

  ngOnInit(): void {
  }
  
  addItem(index) {
    this.transcriptCheck.newCheck[index].keyword.push( {value : ''});
  }
  addform() {
    this.transcriptCheck.newCheck.push( { checkName : '',keyword : [{value : ''}]});
  }

  onChecksChange(value) {
    
  }

  submit() {
    let payload = {...this.transcriptCheck}
    this.loadingService.apiStart();
    this.commonService.Postsearchkeywords(payload)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        // console.log(this.transcriptCheck);
      })
      .catch((err) => {
        this.loadingService.apiStop();
        console.log(err);
      });
  }

}
