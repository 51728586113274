<section class="addchecks">
  <div class="header">
    <span>Add New Checks</span>
  </div>
  <form #myForm="ngForm">
    <div class="row" *ngFor="let item of transcriptCheck.newCheck; let j = index">
      <div class="col-sm-4">
        <div class="inputfild " >
          <div>
            <label class="lable">Select Check Name</label>
          </div>
          <div class="form-group">
            <input type="text" [id]="'item' + j" class="form-control reason" [name]="'checkName' + j" #checkName="ngModel" [(ngModel)]="item.checkName" autocomplete="off" required placeholder="Enter check name"/>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="inputfild">
          <label class="lable">Keyword Name</label>
          <div class="form-group" *ngFor="let item of transcriptCheck.newCheck[j].keyword; let i = index">
            <input [id]="'item'+ j + i" class="form-control reason" type="text" [name]="'item' + j + i" [(ngModel)]="item.value" #item="ngModel" required>
          </div>
          <button type="button" [for]="'item' + i"  class="btn btn2" (click)="addItem(j)">Add new keyword</button>
        </div>
      </div>
    </div>
    <div class="dropdown" style="text-align: right;">
      <button type="button" class="btn btn1" (click)="submit()">SUBMIT</button>
      <button type="button" class="btn btn2" (click)="addform()">Add Next Check</button>
    </div>
  </form>
</section>
