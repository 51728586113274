<div class="agent-card clickable" (click)="openAgent(data.id)">
    <div class="infoline_profile">
        <div class="profile row no-margin">
            <!-- <div class="image">
                <img src="../../../assets/images/profile.png" alt="">
            </div> -->
            <div class="image" [title]="data.first_name + ' ' + data.last_name" [ngClass]="'circle-' + data.id%4" >
                <span>
                    {{(data.first_name != undefined
                    && data.first_name != null
                    && data.first_name != '')
                    ? data.first_name.charAt(0) : ''}}

                    {{(data.last_name != undefined
                    && data.last_name != null
                    && data.last_name != '')
                    ? data.last_name.charAt(0) : ''}}
                </span>
            </div>
            <div class="details">
                <div class="name" [title]="data.first_name + ' ' + data.last_name" >
                    {{ data.first_name }} {{ data.last_name }}
                </div>
                <div class="role d-none" [title]="data.role + ' #' + data.id" >
                    {{data.role | titlecase}} #{{data.id}}
                </div>
                <div class="role team">{{data.name}}</div>
            </div>
        </div>
    </div>
    <div class="infoline_1">
        <div class="row">
            <div class="attribute col-8">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.635" height="14.635" viewBox="0 0 14.635 14.635">
                        <path id="ic_call_24px"
                            d="M5.943,9.334A12.317,12.317,0,0,0,11.3,14.692L13.09,12.9a.808.808,0,0,1,.829-.2,9.275,9.275,0,0,0,2.9.463.815.815,0,0,1,.813.813v2.838a.815.815,0,0,1-.813.813A13.821,13.821,0,0,1,3,3.813.815.815,0,0,1,3.813,3H6.659a.815.815,0,0,1,.813.813,9.237,9.237,0,0,0,.463,2.9.816.816,0,0,1-.2.829Z"
                            transform="translate(-3 -3)" fill="#909090" />
                    </svg>
                </div>
                <div class="label" *ngIf="data.calls.length > 0">
                    {{data.calls[0].calls_taken}} Calls Taken
                </div>
            </div>
            <div class="attribute col-4 text-right" *ngIf="data.calls.length > 0">
                <div class="">
                    <div [ngClass]="commonService.getRatingClass(data.calls[0].calls_pass / data.calls[0].calls_taken * 100)" class="rating-class" >
                        {{commonService.getRating(data.calls[0].calls_pass / data.calls[0].calls_taken * 100)}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="infoline_2">
        <div class="performance">
            RISK SCORE
        </div>
        <div class="row">
            <div class="attribute col-4">
                <div class="key-value good"  >
                    <div class="value" *ngIf="data.calls.length > 0">
                        {{ data.calls[0].calls_pass }}
                    </div>
                    <div class="key">
                        GOOD
                    </div>
                </div>
            </div>
            <div class="attribute col-4">
                <div class="key-value average" >
                    <div class="value" *ngIf="data.calls.length > 0">
                        {{ data.calls[0].calls_avg }}
                    </div>
                    <div class="key">
                        AVERAGE
                    </div>
                </div>
            </div>
            <div class="attribute col-4">
                <div class="key-value bad" >
                    <div class="value" *ngIf="data.calls.length > 0">
                        {{ data.calls[0].calls_fail}}
                    </div>
                    <div class="key">
                        BAD
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>