<div class="project-overview">
    <div class="performance-graph">
        <!-- style="height:240px" -->
        <!-- Graph starts here -->

        <!-- TODO if not working add this [view]="view" -->
        <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="multi" [gradient]="gradient"
            [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
            [animations]="animations" [barPadding]="15" [showGridLines]="false" [roundDomains]="true" (select)="onGraphCallsSelect($event)" >
            <!-- Tool tip start -->
            <!-- <ng-template #tooltipTemplate let-model="model">
                <div>
                    <div>{{model.series}} . {{model.label}}</div>
                    <div>{{model.value}}</div>
                </div>
            </ng-template> -->
            <!-- Tool tip end -->
        </ngx-charts-bar-vertical-stacked>
        <!-- Graph ends here -->
    </div>
</div>