import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'cxr-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent implements OnInit {
  @Output("getTeamList") getTeamList: EventEmitter<any> = new EventEmitter();

  @Input()
  data: any;
  manageTeam: boolean=false;
  path: string;
  constructor(
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private toastr: ToastrService,

    ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
    // console.log(this.route.snapshot.url[0].path)
      this.path=this.route.snapshot.url[0].path
        if (params['edit'] == 'manageTeam') {
          this.manageTeam = true;
        }
      }
    )
  }

  deleteData: any = {};
  deleteTeam(id, campid, projectid) {
    // teamslist  delete team
    if (this.path == 'teamslist') {
      this.deleteData['teamid'] = id;
      this.deleteData['campid'] = campid;
      this.deleteData['projectid'] = projectid;
    }
    this.loadingService.apiStart();
    this.commonService.deleteSingleTeam( this.deleteData.teamid)
    .toPromise()
      .then((data: any) => {
        this.getTeamList.emit();
        this.toastr.success("Team Delete successfully.");
      this.loadingService.apiStop();
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }

  openTeam(id) {
    // this.router.navigateByUrl('/team/' + id);
    if (this.manageTeam == true && this.path == 'teamslist') {
      this.router.navigateByUrl('/admin/project/newteam/' + id);
    }
    else {
    // this.router.navigateByUrl('/team/' + id);
    this.router.navigateByUrl('/agents/' + id);
  }
  }
  
}
