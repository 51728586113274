<div class="profile">
    <div class="image" [title]="currentUser.first_name + ' ' + currentUser.last_name" [ngClass]="'circle-' + currentUser.id%4" >
        <span>
            {{(currentUser.first_name != undefined
            && currentUser.first_name != null
            && currentUser.first_name != '')
            ? currentUser.first_name.charAt(0) : ''}}

            {{(currentUser.last_name != undefined
            && currentUser.last_name != null
            && currentUser.last_name != '')
            ? currentUser.last_name.charAt(0) : ''}}
        </span>
    </div>
    <div class="details">
        <div class="name" [title]="currentUser.first_name + ' ' + currentUser.last_name" >
            {{currentUser.first_name}} {{currentUser.last_name}}
        </div>
        <div class="position" [title]="currentUser.role" >
            <!-- {{currentUser.role}} -->
        </div>
    </div>
</div>

<a [routerLink]="['/dashboard']">
    <div class="navigations" id="dashboard">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                <path id="ic_home_24px" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" transform="translate(-2 -3)"
                    fill="#909090" />
            </svg>
        </div>
        <div class="title">
            Dashboard
        </div>
    </div>
</a>
<!-- <div class="navigations" id="analytics">
    <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
             <path id="ic_insert_chart_24px" d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM9,17H7V10H9Zm4,0H11V7h2Zm4,0H15V13h2Z" transform="translate(-3 -3)" fill="#909090"/>
        </svg>                        
    </div>
    <div class="title">
        Analytics
    </div>
</div> -->
<a [routerLink]="['/projects']">
    <div class="navigations" id="projects">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17">
                <g id="ic_golf_course_24px" transform="translate(-4 -2)">
                    <circle id="Ellipse_26" data-name="Ellipse 26" cx="1" cy="1" r="1" transform="translate(16 16)"
                        fill="#909090" />
                    <path id="Path_105" data-name="Path 105"
                        d="M15.05,5.332,8.25,2V17.3H6.55V15.83c-1.522.3-2.55.841-2.55,1.47C4,18.235,6.286,19,9.1,19s5.1-.765,5.1-1.7c0-.841-1.836-1.538-4.25-1.674V7.933l5.1-2.6Z"
                        fill="#909090" />
                </g>
            </svg>

        </div>
        <div class="title">
            Projects
        </div>
    </div>
</a>

<a [routerLink]="['/campaigns']">
    <div class="navigations" id="campaigns">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_insert_chart_24px"
                    d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM9,17H7V10H9Zm4,0H11V7h2Zm4,0H15V13h2Z"
                    transform="translate(-3 -3)" fill="#909090" />
            </svg>
        </div>
        <div class="title">
            Campaigns
        </div>
    </div>
</a>

<a [routerLink]="['/teams']">
    <div class="navigations" id="teams">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                <path id="ic_group_24px"
                    d="M16,11a3,3,0,1,0-3-3A2.987,2.987,0,0,0,16,11ZM8,11A3,3,0,1,0,5,8,2.987,2.987,0,0,0,8,11Zm0,2c-2.33,0-7,1.17-7,3.5V19H15V16.5C15,14.17,10.33,13,8,13Zm8,0c-.29,0-.62.02-.97.05A4.22,4.22,0,0,1,17,16.5V19h6V16.5C23,14.17,18.33,13,16,13Z"
                    transform="translate(-1 -5)" fill="#909090" />
            </svg>

        </div>
        <div class="title">
            Teams
        </div>
    </div>
</a>

<a [routerLink]="['/agents']">
    <div class="navigations" id="agent">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path id="ic_person_24px"
                    d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0,2c-2.67,0-8,1.34-8,4v2H20V18C20,15.34,14.67,14,12,14Z"
                    transform="translate(-4 -4)" fill="#909090" />
            </svg>
        </div>
        <div class="title">
            Agents
        </div>
    </div>
</a>

<a [routerLink]="['/calls']">
    <div class="navigations" id="calls">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.635" height="14.635" viewBox="0 0 14.635 14.635">
                <path id="ic_call_24px"
                    d="M5.943,9.334A12.317,12.317,0,0,0,11.3,14.692L13.09,12.9a.808.808,0,0,1,.829-.2,9.275,9.275,0,0,0,2.9.463.815.815,0,0,1,.813.813v2.838a.815.815,0,0,1-.813.813A13.821,13.821,0,0,1,3,3.813.815.815,0,0,1,3.813,3H6.659a.815.815,0,0,1,.813.813,9.237,9.237,0,0,0,.463,2.9.816.816,0,0,1-.2.829Z"
                    transform="translate(-3 -3)" fill="#909090" />
            </svg>
        </div>
        <div class="title">
            Calls
        </div>
    </div>
</a>

<a href="javascript:void(0)" *ngIf="currentUser.role != constants.AGENT && currentUser.role != constants.TEAM_LEAD" >
    <div class="navigations">
        <div class="title" style="font-size: 14px;" >
            ADMIN PANEL
        </div>
    </div>
</a>
<a [routerLink]="['/admin']" *ngIf="currentUser.role != constants.AGENT && currentUser.role != constants.TEAM_LEAD && currentUser.role != constants.QUALITY_MANAGER" >
    <div class="navigations" id="organisation">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                <path id="ic_home_24px" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" transform="translate(-2 -3)"
                    fill="#909090" />
            </svg>
        </div>
        <div class="title">
            Organisation
        </div>
    </div>
</a>
<a [routerLink]="['/vulnerabilityindex']" *ngIf="currentUser.role != constants.AGENT && currentUser.role != constants.TEAM_LEAD && currentUser.role != constants.QUALITY_MANAGER">
    <div class="navigations" id="vulnerabilityindex">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                <path id="ic_home_24px" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" transform="translate(-2 -3)" fill="#909090"/>
            </svg>
        </div>
        <div class="title">
            Vulnerability
        </div>
    </div>
</a>
<a [routerLink]="['/keywords']" *ngIf="currentUser.role != constants.AGENT && currentUser.role != constants.TEAM_LEAD && currentUser.role != constants.QUALITY_MANAGER">
    <div class="navigations" id="keywords">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                <path id="ic_home_24px" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" transform="translate(-2 -3)" fill="#909090"/>
            </svg>
        </div>
        <div class="title">
            Keywords
        </div>
    </div>
</a>

<a [routerLink]="['/product-manager']" *ngIf="currentUser.role != constants.AGENT && currentUser.role != constants.TEAM_LEAD">
    <div class="navigations" id="productManager">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                <path id="ic_home_24px" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" transform="translate(-2 -3)" fill="#909090"/>
            </svg>
        </div>
        <div class="title">
            PM
        </div>
    </div>
</a>
