import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CallsComponent } from './components/calls/calls.component';
import { CalldetailsComponent } from './components/calldetails/calldetails.component';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { CampaigndetailsComponent } from './components/campaigndetails/campaigndetails.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TeamdetailsComponent } from './components/teamdetails/teamdetails.component';
import { AgentsComponent } from './components/agents/agents.component';
import { AgentdetailsComponent } from './components/agentdetails/agentdetails.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { AdminComponent } from './components/admin/admin.component';
// import {DashboardComponentSample} from './components/samplewhite/dashboard/dashboard.component'
import { ProductManagerComponent  } from './components/product-manager/product-manager.component';
import { VulnerabilityIndexComponent } from './components/vulnerability-index/vulnerability-index.component';
import { KeywordsComponent } from './components/keywords/keywords.component';

import { AuthGuard } from './guards/auth.guard';

import { from } from 'rxjs';
import { CallCopyComponent } from './components/call-copy/call-copy.component';
import { QualitynewuiComponent } from './components/qualitynewui/qualitynewui.component';
import { QcmanagerComponent } from './components/qcmanager/qcmanager.component';
import { TranscriptsearchComponent } from './components/transcriptsearch/transcriptsearch.component';
import { TreestructureComponent } from './components/treestructure/treestructure.component';
import {ForgotpassComponent} from './components/forgotpass/forgotpass.component'
const routes: Routes = [
  { path: '', component: LoginComponent ,
    canActivate: [AuthGuard],
    data: {
      hideTop: true,
      hideSide: true
    }
  },
  { path: 'login', component: LoginComponent,
    canActivate: [AuthGuard],
    data: {
      hideTop: true,
      hideSide: true
    }
  },
  { path: 'resetpassword', component: ForgotpassComponent,
    // canActivate: [AuthGuard],
    data: {
      hideTop: true,
      hideSide: true
    }
  },
  { path : 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  }, // replacing DashboardComponent with DashboardComponentSample for demo
  // { path : 'dashboardwhite' , component: DashboardComponentSample},
  // old admin UI
  // {
  //   path: 'admin',
  //   component: AdminComponent,
  //   canActivate: [AuthGuard]
  // },
 
    
  // { path : 'calls' , component: CallsComponent,
  //   canActivate: [AuthGuard]
  // },
  { path : 'calls' , component: CallCopyComponent,
    canActivate: [AuthGuard]},
  { path : 'call/:id' , component: CalldetailsComponent,
    canActivate: [AuthGuard]},
  // { path : 'product-manager' , component: ProductManagerComponent,
  //   canActivate: [AuthGuard]
  // },
  { path : 'product-manager' , component: QualitynewuiComponent,
    canActivate: [AuthGuard]},
  { path : 'projects' , component: ProjectsComponent,
    canActivate: [AuthGuard]},
  { path : 'project/:id' , component: CampaignsComponent,
    canActivate: [AuthGuard]},
  { path : 'campaigns' , component: CampaignsComponent,
    canActivate: [AuthGuard]},
  { path : 'campaign/:id' , component: CampaigndetailsComponent,
    canActivate: [AuthGuard]},
  { path : 'teams' , component: TeamsComponent,
    canActivate: [AuthGuard]
  },
  { path : 'teams/:id' , component: TeamsComponent,
    canActivate: [AuthGuard]},
  { path : 'team/:id' , component: TeamdetailsComponent,
    canActivate: [AuthGuard]},
  { path : 'agents' , component: AgentsComponent,
    canActivate: [AuthGuard],
  },
  { path : 'agents/:id' , component: AgentsComponent,
    canActivate: [AuthGuard]},
  { path : 'agent/:id' , component: AgentdetailsComponent,
    canActivate: [AuthGuard]},
  { path : 'vulnerabilityindex' , component: VulnerabilityIndexComponent,
    canActivate: [AuthGuard]},
  { path : 'keywords' , component: KeywordsComponent,
    canActivate: [AuthGuard]},
  { path : 'qcmanager/:id' , component: QcmanagerComponent,
    canActivate: [AuthGuard]
  },
   { path : 'transcript' , component: TranscriptsearchComponent,
    canActivate: [AuthGuard]},
  { path : 'tree' , component: TreestructureComponent,
    canActivate: [AuthGuard]},
  {
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
  },{
    path: 'admin/project',
    loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule),
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  }
  ,{
    path: 'Voiceverification',
    loadChildren: () => import('./modules/voice-verification/voice-verification.module').then(m => m.VoiceVerificationModule),
    canActivate: [AuthGuard],
  }
  // onbording
  // , {
  //   path: 'details',
  //   loadChildren: () => import('./modules/project/projectdetails/projectdetails.module').then(m => m.ProjectdetailsModule)
  // },{
  //   path: 'list',
  //   loadChildren: () => import('./modules/project/projectlist/projectlist.module').then(m => m.ProjectlistModule)
  // } 
  // , {
  //   path: 'testing',
  //   loadChildren : () => import('./modules/project/testing/testing.module').then(m => m.TestingModule)
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
