import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { LoadingService } from '../../services/loading.service';
import * as shape from 'd3-shape';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'cxr-teamdetails',
  templateUrl: './teamdetails.component.html',
  styleUrls: ['./teamdetails.component.scss']
})
export class TeamdetailsComponent implements OnInit {

  teamId = null;
  team = null;
  overview = null;
  checks = [];
  agents = [];
  leader = null;
  campaigns = [];
  ratings = { "L0": 0, "L1": 0, "L2": 0, "L3": 0, "L4": 0 };
  dateDisplay = true;
  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period"
  curve = shape.curveCardinal;

  colorScheme = {
    domain: ['#54BFA8', '#FDE04C', '#A84748', '#53CCF9', '#59BF30', '#F2923D']
  };

  colorSchemeDonut = {
    domain: ['#333', '#1cc069']
  };

  verificationAgentSeries = [
    {
      "name": "Verified",
      "value": 0,
      "label": "Verified"
    },
    {
      "name": "Not Verified",
      "value": 0,
      "label": "Not Verified"
    }
  ];

  performance:any = [
    {
      "name": "Agent Performance",
      "series": [
        // {
        //   "name": "50",
        //   "value": 700
        // },
        // {
        //   "name": "125",
        //   "value": 400
        // }
      ]
    }
  ];


  wordData:any = [];

  pieChartLabel(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

  dateRange = [];

  start_date:any;
  end_date:any;
  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

  verifiedAgents = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.toggleDate();

    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];

    const id = this.route.snapshot.paramMap.get('id');
    this.teamId = parseInt(id);
    this.setDate('today', '24 Hours');
  }

  setDate = (dateSelected, dateSelectedDisplay) => {
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    this.toggleDate();
    // this.getTeam(this.commonService.toDaysConvertor(this.selectedPeriod));
    //
    //
    // NOTE:
    // NOTE: This needs to be updated to accomodate start date and end date here
    // NOTE:
    //
    //
    this.getTeam();
  }

  toggleDate = () => {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }
  }

  // data = [
  //   {
  //     "name": "Greeting",
  //     "value": 6
  //   },
  //   {
  //     "name": "DPA",
  //     "value": 7
  //   },
  //   {
  //     "name": "Branding",
  //     "value": 9
  //   },
  //   {
  //     "name": "Investigation",
  //     "value": 3.5
  //   },
  //   {
  //     "name": "Resolution",
  //     "value": 6
  //   },
  //   {
  //     "name": "Ending",
  //     "value": 2
  //   }
  // ];

  showInnerTable = (id) => {
    const selector = '#' + id + '-table';
    var display = $(selector).css("display");
    if (display == 'none') {
      $(selector).css('display', 'unset');
      $(selector).css('height', '100%');
      $('#' + id).addClass('active');
    } else {
      $(selector).css('display', 'none');
      $(selector).css('height', '0%');
      $('#' + id).removeClass('active');
    }
  }

  getTeam = () => {
    this.loadingService.apiStart();
    this.commonService.getTeam(this.teamId)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        // this.team = data.teams;
        this.agents = data.teams;
        // this.overview = data.overview;
        this.checks = data.checks;

        /*Verification agent series start*/
        this.verifiedAgents = data.verifiedAgents;
        this.verificationAgentSeries = [
          {
            "name": "Not Verified",
            "value": parseFloat((100 - this.verifiedAgents).toFixed(1)),
            "label": "Not Verified"
          },
          {
            "name": "Verified",
            "value": parseFloat((this.verifiedAgents.toFixed(1))),
            "label": "Verified"
          }
        ];
        /*Verification agent series end*/

        /*Word cloud data start*/
        let wordData = [];
        data.keywords.forEach((element)=>{
          wordData.push({
            weight: element.count,
            text: element.keyword
          });
        });
        this.wordData = wordData;
        /*Word cloud data end*/

        let performanceResult = [];
        data.performance.forEach(performance=>{
          let item = {
            name: performance.name,
            value: performance.quality_score
          };
          performanceResult.push(item);
        });

        this.performance[0].series = performanceResult;

        this.checks = data.checks.map(element => {
          const currentObj = {
            "name": this.capitalizeFirstLetter(element.checkname),
            "value": element.pass
          }
          return currentObj;
        });
        this.checks = [...this.checks];

        for (let agent of this.agents) {
          this.ratings[this.commonService.getRating(agent['quality_score'])]++;
        }

      })
      .catch((err) => {
        this.loadingService.apiStop()
      });
    
      this.commonService.getTeamCamp(this.teamId)
      .toPromise()
        .then((data: any) => {
          this.campaigns = data.teams;
          this.loadingService.apiStop()
       })
      .catch((err) => {
        this.loadingService.apiStop()
      });
    
      this.commonService.getTeamLeader(this.teamId)
      .toPromise()
        .then((data: any) => {
          this.leader = data.teams;
          this.loadingService.apiStop()
       })
      .catch((err) => {
        this.loadingService.apiStop()
      });
  }

  back = () => {
    window.history.back();
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  onClickWordCloud(data){
    if (data != undefined && data.text) {
      this.router.navigate(['/calls'], { queryParams: {word: data.text}});
    }
  }
}
