import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'cxr-vulnerability-drawer',
  templateUrl: './vulnerability-drawer.component.html',
  styleUrls: ['./vulnerability-drawer.component.scss']
})
export class VulnerabilityDrawerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeDrawer(){
    $('#vulnerabilityDrawer').removeClass('open');
    $('body').removeClass('modal-open');
  }
}
