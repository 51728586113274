import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Constants } from './constants/constants';
import { CommonService } from './services/common.service';
import { LoadingService } from './services/loading.service';

import { map, filter, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

 import { EventService } from './services/event.service';

@Component({
  selector: 'cxr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'frontend';

  constructor( private router:Router, private route:ActivatedRoute, private event: EventService, private commonService: CommonService, private loadingService: LoadingService) {
  }

  hideTop = true;
  hideSide = true;
  projectTheme = '';

  isConfirmationOverlayOpen = false;
  confirmationOverlayData:any;
  currentUser:any = {};

  ngOnInit() {
    this.getLoggedInUser();

    this.router.events.pipe(
    filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let routeData = this.route.root.firstChild.snapshot.data;
      this.hideTop = routeData.hideTop;
      this.hideSide = routeData.hideSide;
      // scroll to top
      document.getElementById('projectMainContent').scrollTop = 0;

    });


    /*Capturing custom data events*/
    this.event.getMessage().subscribe(data => {

      /*To open modal of send intent message*/
      if(Constants.SENT_APP_THEME == data.type) {

        let theme = this.projectTheme == 'white-theme' ? '' : 'white-theme';
        this.loadingService.apiStart();
        this.commonService
        .changeTheme({
          user_theme: theme
        })
        .toPromise()
        .then((data: any) => {
          this.loadingService.apiStop();
          this.projectTheme = theme;
        })
        .catch((err) => {
          this.loadingService.apiStop();
        });
      }

      /*To open confirmation overlay*/
      if(Constants.OPEN_CONFIRMATION_OVERLAY == data.type) {
        this.confirmationOverlayData = data.data;
        this.isConfirmationOverlayOpen = true;
      }
    });
  }

  
  /*Confirmation overlay actions*/
  confirmation(status){
    if(status) {
      /*Callback if action yes*/
      this.confirmationOverlayData.callback();
    }
    /*Close confirmation overlay*/
    this.isConfirmationOverlayOpen = false;
  }

  getLoggedInUser() {
    this.commonService.getLoggedInUser()
      .toPromise()
      .then((data: any) => {
        this.currentUser = data.user;
        this.projectTheme = this.currentUser.user_theme;
      })
      .catch((err) => {
        console.error(err);
      })
  }
}


