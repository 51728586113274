import { Injectable, Inject } from '@angular/core';

import { ServerConstants } from '../services/serverconstants.service';
import { RestClient } from '../services/restclient.service';

import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})




export class BackendService {

  private endDate = moment();
  private startDate = moment("2020-09-01");

  constructor(private restClient: RestClient) {}

  signup(payload){
    return this.restClient.post(ServerConstants.SIGNUP, payload);
  }

  login(payload){
    return this.restClient.post(ServerConstants.LOGIN, payload);
  }

  getCurrent(){
    return this.restClient.get(ServerConstants.GET_CURRENT);
  }

  getCallsList(term, start_date, end_date){
    return this.restClient.get(ServerConstants.GET_CALLS + '?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD') + term);
  }

  getSingleCallsById(id){
    return this.restClient.get(ServerConstants.GET_SINGLE_CALLS + '/' + id);
  }

  getCallsCount(){
    return this.restClient.get(ServerConstants.GET_CALLS_COUNT);
  }

  getAnalytics( start_date, end_date){
    return this.restClient.get(ServerConstants.GET_ANALYTICS + '?start_date=' + moment(start_date).format('YYYY-MM-DD') + '&end_date=' + moment(end_date).format('YYYY-MM-DD'));
  }

  getCurrentUser(){
    return this.restClient.get(ServerConstants.GET_CURRENT_USER);
  }

  // voice verify 

  status(){
    return this.restClient.get(ServerConstants.STATUS);
  }

  createStream(){
    return this.restClient.post(ServerConstants.CREATE_STREAM, {});
  }
  
  sendDataToStream(uuid:any, data:any){
    return this.restClient.post(ServerConstants.SEND_DATA_TO_STREAM, {uuid: uuid, data: data});
  }

  enrole(uuid:any, id:any){
    return this.restClient.post(ServerConstants.ENROLL, {uuid: uuid, id: id});
  }

  voiceverify(uuid:any, id:any){
    return this.restClient.post(ServerConstants.VOICE_VERIFY, {uuid: uuid, id: id});
  }


  getTest() {
    let payload ={
      email:"admin",
      password : "phonexia"
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic YWRtaW46cGhvbmV4aWE=',
      })
    };
    return this.restClient.post(ServerConstants.TEST,{httpOptions});
  }

}
