<div class="row no-margin top-space">
  <div class="main-content">
    <div class="agents">
      <div class="title">
        All Agents
      </div>
      <div class="container" *ngIf="!agentsLoading" >
        <div class="row">
          <div class="col-sm-3" *ngFor="let agent of agents" >
            <cxr-agent-card [data]="agent"></cxr-agent-card>
          </div>
        </div>                    
        <div class="text-muted text-center" *ngIf="agents.length == 0">No agents found</div>
      </div>
      <div class="main-content" *ngIf="agentsLoading">
        <cxr-loading></cxr-loading>
      </div>
    </div>
  </div>
</div>