import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../services/loading.service';
import { UserDataService } from '../../services/user-data.service';
import { BackendService } from '../../services/backendservice.service';

@Component({
  selector: 'cxr-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.scss']
})
export class ForgotpassComponent implements OnInit {

  email : string;

  constructor(
    private authenticationService : AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private userService: UserDataService,
    private backendService: BackendService
  ) { }

  ngOnInit() {
   
  }

  submitPass() {
    if (!this.email) {
      return;
    }
    this.loadingService.apiStart();
    let payload = {
      email: this.email,
    }
    this.authenticationService.PostSetPassword(payload)
    .toPromise()
      .then((data: any) => {
      if (data.status == 'success') {
        this.router.navigate(['/login']);
      }
      this.loadingService.apiStop();
    })
    .catch((err) => {
      console.error(err);
      this.toastr.error(err.error.error);
      this.loadingService.apiStop();
    })
  }

}
