import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, key: any, searchValue): any {
    if (searchValue == '' || searchValue == null || searchValue == undefined) {
      return value;
    }

    value = value.filter(item=>{
      return item[key] == searchValue;
    });

    return value;
  }

}
