import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'cxr-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openEditModal = () => {
    $('#editmodal').modal('show');
  }

  openAddModal = () => {
    $('#addmodal').modal('show');
  }

  addAgentModal = () => {
    $('#addagent').modal('show');
  }

}
