<div class="row no-margin top-space">
  <div class="main-content">
    <form autocomplete="off" name="keywordForm" #keywordForm="ngForm" (ngSubmit)="keywordForm.form.valid && saveKeywords()" novalidate>
      <div class="data-mgmt">
        <div class="title">
          DATA MANAGEMENT
        </div>

        <!-- Choose campaign section start -->
        <div class="choose-campaign-section">
          <div class="row">
            <div class="col-sm-4">
              <div>
                <label>CHOOSE CAMPAIGN</label>
                <select (change)="onSelectCampaign($event.target.value)" class="form-field" [(ngModel)]="keywordsData.campaign" #campaign="ngModel" required="" name="campaign" >
                  <option value="" selected>--Select--</option>
                  <option *ngFor="let campaign of campaigns" value="{{campaign.id}}" >
                    {{campaign.name}}
                  </option>
                </select>

                <div *ngIf="keywordForm.submitted && campaign.invalid" class="error">
                  <div *ngIf="campaign.errors.required" >
                    Please choose campaign
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- Choose campaign section end -->

        <!-- Slide select section start -->
        <div class="select-slider-section">
          <div class="row">

            <div class="col-sm-4">
              <div>
                <label>FINANCIAL DATA</label>
                <div class="slider-stamp">
                  <div class="left-side">
                    <div class="info">Redact Data from Transcript</div>
                    <div class="note">MANDATORY</div>
                  </div>
                  <div class="right-side">
                    <label class="switch">
                      <input type="checkbox" checked>
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div>
                <label>CUSTOMER PERSONAL DATA</label>
                <div class="slider-stamp">
                  <div class="left-side">
                    <div class="info">Redact Data from Transcript</div>
                    <div class="note">OPTIONAL</div>
                  </div>
                  <div class="right-side switch-text">
                    <label class="switch">
                      <input type="checkbox" checked>
                      <span class="slider round"></span>
                      <span class="no">No</span>
                      <span class="yes">Yes</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- Slide select section end -->

        <!-- Phrases section start -->
        <div class="phrases-section">
          <div class="row">
            <div class="col-sm-8">
              <div class="sub-heading">
                Vulnernability Index
              </div>
              <div class="sub-sub-heading">
                Enter the target phrases and their corresponding weightages
              </div>
            </div>

            <div class="col-sm-3 text-right">
              <a href="javascript:void(0)" class="view-all" >View Dictionary</a>
            </div>
          </div>

          <!-- phrases listing start -->
          <div class="phrases-list">
            <div class="row">

              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="sub-heading">
                      phrases
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="sub-heading">
                      weight
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="sub-heading">
                      phrases
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="sub-heading">
                      weight
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6" *ngFor="let phrase of keywordsData.keywords; let index=index">
                <div class="phrase-stamp">
                  <div class="row">
                    <div class="col-sm-8">
                      <input [(ngModel)]="phrase.keyword" #keywordm="ngModel" required type="text" [name]="'keyword'+index" placeholder="Enter Phrase here" >
                      <div *ngIf="keywordForm.submitted && keywordm.invalid" class="error">
                        <div *ngIf="keywordm.errors.required" >
                          Please enter phrase
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <input [(ngModel)]="phrase.score" #count="ngModel" required type="text" (change)="onChangeWeight()" [name]="'count'+index" placeholder="5" class="weight-box" >
                      <div *ngIf="keywordForm.submitted && count.invalid" class="error">
                        <div *ngIf="count.errors.required" >
                          Required
                        </div>
                      </div>
                      <a (click)="removeKeyword(index)" href="javascript:void(0)" class="remove" >remove</a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add button start -->
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-8">
                    <button type="button" (click)="openDrawer()" class="bdr-btn">ADD NEW PHRASE</button>
                  </div>
                </div>
              </div>
              <!-- Add button end -->

              <!-- Divider start -->
              <div class="col-sm-12">
                <div class="devider"></div>
              </div>
              <!-- Divider end -->

              <!-- Progress start -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-8">
                    <div class="sub-heading">TOTAL SCORE</div>
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" aria-valuenow="70"
                      aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': totalWeight+'%'}">
                        {{totalWeight}}%
                      </div>
                      <span class="min">0</span>
                      <span class="max">100</span>
                    </div>
                  </div>
                  <div class="col-sm-4 text-right">
                    <button type="submit" class="stnd-bg-btn">SAVE</button>
                  </div>
                </div>
              </div>
              <!-- Progress end -->

              <!-- Threshold section start -->
              <div class="col-sm-12">
                <div class="threshold">
                  <div class="sub-heading">THRESHOLD VALUES</div>

                  <!-- Red threshold start -->
                  <div class="stamp">
                    <div class="heading red">
                      RED THRESHOLD
                    </div>
                    <input value="40" type="text" name="" class="from" placeholder="FROM">
                    <input type="text" value="100" class="to" name="" placeholder="TO">
                  </div>
                  <!-- Red threshold end -->

                  <!-- green threshold start -->
                  <div class="stamp">
                    <div class="heading green">
                      GREEN THRESHOLD
                    </div>
                    <input value="0" type="text" name="" class="from" placeholder="FROM">
                    <input type="text" value="25" class="to" name="" placeholder="TO">
                  </div>
                  <!-- green threshold end -->

                  <!-- Red threshold start -->
                  <div class="stamp">
                    <div class="heading amber">
                      AMBER THRESHOLD
                    </div>
                    <input type="text" name="" value="25" class="from" placeholder="FROM">
                    <input type="text" class="to" name="" value="40" placeholder="TO">
                  </div>
                  <!-- Red threshold end -->

                </div>
              </div>
              <!-- Threshold section end -->

            </div>
          </div>
          <!-- phrases listing end -->
        </div>
        <!-- Phrases section end -->
      </div>
    </form>
    <!-- cxr-vulnerability-drawer start -->
    <cxr-vulnerability-drawer></cxr-vulnerability-drawer>
    <!-- cxr-vulnerability-drawer end -->

  </div>
</div>
