import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment';
import { LoadingService } from '../../services/loading.service';
import * as shape from 'd3-shape';

@Component({
  selector: 'cxr-agentdetails',
  templateUrl: './agentdetails.component.html',
  styleUrls: ['./agentdetails.component.scss']
})
export class AgentdetailsComponent implements OnInit {

  callData = [];
  comments = [];
  checks = [];
  pass = 0;
  fail = 0;
  overview = null;
  agentName;
  agentId;
  dateRange = [];
  currentDate;
  callBreakups:any = {
    good: 0,
    average: 0,
    bad: 0
  };
  agentData:any = {};

  performance:any = [
    {
      "name": "Agent Performance",
      "series": [
        /*{
          "name": "50",
          "value": 700
        },
        {
          "name": "125",
          "value": 400
        }*/
      ]
    }
  ];

  curve = shape.curveCardinal;

  verifiedCalls = 0;

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    private loadingService: LoadingService,
    private router: Router
  ) { }
  colorScheme = {
    domain: ['#54BFA8', '#FDE04C', '#A84748', '#53CCF9', '#59BF30', '#F2923D']
  };
  data = [];

  series = [
    {
      "name": "Unanswered",
      "value": this.fail,
      "label": "Fail"
    },
    {
      "name": "Answered",
      "value": this.pass,
      "label": "Pass"
    }
  ];

  verificationCallsSeries = [
    {
      "name": "Verified",
      "value": 0,
      "label": "Verified"
    },
    {
      "name": "Not Verified",
      "value": 0,
      "label": "Not Verified"
    }
  ];
  start_date:any;
  end_date:any;

  colorSchemeDonut = {
    domain: ['#333', '#1cc069']
  };

  wordData:any = [];

  ngOnInit(): void {
    this.agentId = this.route.snapshot.paramMap.get('id');
    
    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];

    this.getAgentData();
  }

  onGraphCallsSelect(event) {
    this.router.navigate(['/calls'], { queryParams: {date: event.name}});
  }

  // get
  getAgentData() {
    this.loadingService.apiStart();
    this.commonService.getAgentData(this.agentId, this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.callData = data.calls;
        this.comments = data.comments;
        this.overview = data.overview;
        this.agentData = data.user;

        /*Verification calls series*/
        this.verifiedCalls = data.verifiedCalls;
        this.verificationCallsSeries = [
          {
            "name": "Not Verified",
            "value": parseFloat((100 - this.verifiedCalls).toFixed(1)),
            "label": "Not Verified"
          },
          {
            "name": "Verified",
            "value": parseFloat((this.verifiedCalls).toFixed(1)),
            "label": "Verified"
          }
        ];


        /*Word cloud data start*/
        let wordData = [];
        data.keywords.forEach((element)=>{
          wordData.push({
            weight: element.count,
            text: element.keyword
          });
        });
        this.wordData = wordData;
        /*Word cloud data end*/

        let performanceResult = [];
        data.performance.forEach(performance=>{
          let item = {
            name: this.commonService.getFormatedDateDDMMYYYY(performance.timestamp),
            value: performance.quality_score
          };
          performanceResult.push(item);
        });

        this.performance[0].series = performanceResult;

        if (this.callData[0]) {
          this.agentName = this.callData[0].agent_name;
        }

        this.checks = data.checks;

        this.checks = data.checks.map(element => {
          const currentObj = {
            "name": this.capitalizeFirstLetter(element.checkname),
            "value": element.pass
          }
          this.pass += element.pass;
          this.fail += element.fail;
          return currentObj;
        });
        this.checks = [...this.checks];

        this.series = [
          {
            "name": "Unanswered",
            "value": this.fail,
            "label": "Fail"
          },
          {
            "name": "Answered",
            "value": this.pass,
            "label": "Pass"
          }
        ];

        // this.callData.forEach(item =>{
        //   if (item.quality_score >70) {
        //     this.callBreakups.good += 1;
        //   }
        //   else if (item.quality_score >=50) {
        //     this.callBreakups.average += 1;
        //   }
        //   else{
        //     this.callBreakups.bad += 1;
        //   }
        // });
      })
      .catch((err) => {
        this.loadingService.apiStop();
      });
  }

  pieChartLabel(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;

  }
  toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":")
  }

  back = () => {
    window.history.back();
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  onDateChange(dates){
    this.start_date = dates[0];
    this.end_date = dates[1];
    this.getAgentData();
  }
}
