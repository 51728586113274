import { Component, OnInit } from '@angular/core';
import * as shape from 'd3-shape';
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { DashboardService } from '../../services/dashboard.service';
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'cxr-product-manager',
  templateUrl: './product-manager.component.html',
  styleUrls: ['./product-manager.component.scss']
})
export class ProductManagerComponent implements OnInit {

  options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
    width: 350,
    // if height is between 0 and 1 it will be set to the height of the upper element multiplied by the value
    height: 300,
    overflow: false,
  };  
  wordData: CloudData[] = [];
  series = [
    
    {
      "name": "Answered",
      "value": 25,
      "label": "25%"
    },
    {
      "name": "Unanswered",
      "value": 75,
      "label": "75%"
    },
  ];

  pieChartLabel(series: any[], name: string): string {
      const item = series.filter(data => data.name === name);
      if (item.length > 0) {
          return item[0].label;
      }
      return name;
  }
  view: any[] = [1000, 280];
  results:any = [
    {
      "name": "Sentiment Analysis",
      "series": []
    }
  ];




  colorScheme = {
    domain: ['#53BFA8']
  };
  curve = shape.curveCardinal;


  // Competitor data
  dataCompetitor = [
    {
      "name": "PES Gas service",
      "value": "15"
    },
    {
      "name": "Imperial Gas",
      "value": "10"
    },
    {
      "name": "Co Flame Gas",
      "value": "25"
    },
    {
      "name": "SSGC",
      "value": "50"
    }
  ];
  colorSchemeCompetitor = {
    domain : ["#53BFA8","#C0792F","#FDE04C","#C12C28"]
  }

  positiveNegativeKeywords:any = [];
  productManagerKeywords:any = [];
  mentionKeywords:any = [];

  totalCalls = 0;

  positiveNegativeTab = 'positive';

  constructor(
    private dashboardService : DashboardService,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getPositiveNegativeKeywords();
    this.getProductManagerKeywords();
    this.getMentionKeywords();
    this.getWordCloud();
    this.getSentimentScore();
  }

  getPositiveNegativeKeywords(){
    this.positiveNegativeKeywords = [];
    this.positiveNegativeTab == 'negative' ? this.getNegativeKeywordsCheck() : this.getPositiveKeywordsCheck();
  }

  getPositiveKeywordsCheck() {
    this.loadingService.apiStart();
    this.commonService.getPositiveKeywordsCheck()
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.positiveNegativeKeywords = data.keywords;
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.apiStop();
      });
  }

  getNegativeKeywordsCheck() {
    this.loadingService.apiStart();
    this.commonService.getNegativeKeywordsCheck()
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.positiveNegativeKeywords = data.keywords;
      })
      .catch((err) => {
        this.loadingService.apiStop();
        console.log(err);
      });
  }

  getMentionKeywords() {
    this.loadingService.apiStart();
    this.commonService.getMentionKeywords()
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.mentionKeywords = data.keywords;
      })
      .catch((err) => {
        this.loadingService.apiStop();
        console.log(err);
      });
  }

  getProductManagerKeywords() {
    this.loadingService.apiStart();
    this.commonService.getProductManagerKeywords()
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.productManagerKeywords = data.keywords;
        this.totalCalls = data.callsCount;
        this.productManagerKeywords.forEach(word =>{
          word['series'] = [
            {
              "name": "Unfocused",
              "value": 100 - parseFloat(((word.call_count / this.totalCalls) * 100).toFixed())
            },
            {
              "name": "Focused",
              "value": ((word.call_count / this.totalCalls) * 100).toFixed(0)
            }
          ]

        });
      })
      .catch((err) => {
        console.log(err);
        this.loadingService.apiStop();
      });
  }

  getWordCloud() {
    this.loadingService.apiStart();
    this.commonService.getWordCloud('')
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        let finalData = [];
        data.result.forEach(element => {
          finalData.push({
            weight: element.count,
            text: element.keyword
          });
        });
        this.wordData = finalData;
      })
      .catch((err) => {
        this.loadingService.apiStop();
        console.log(err);
      });
  }

  onClickWordCloud(data){
    if (data != undefined && data.text) {
      this.router.navigate(['/calls'], { queryParams: {
        word: data.text
      }});
    }
  }

  onGraphCallsSelect(event) {
    this.router.navigate(['/calls'], { queryParams: {date: event.name}});
  }

  getSentimentScore() {
    let term = "";
    this.loadingService.apiStart();
    this.commonService.getSentimentScore(term)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        let series = [];
        for(let i = 0; i < data.sentiment_score.length; i++){
          let item = data.sentiment_score[i];
          let obj = {
            "name": this.commonService.getFormatedDateDDMMYYYY(item.timestamp),
            "value": item.sentiment_score
          }
          series.push(obj);
        }
        this.results[0].series = series;
      })
      .catch((err) => {
        this.loadingService.apiStop();
        console.log(err);
      });
  }

  onGraphFocusKeywordSelect(value){
    let params:any = {
      focusKeyword: value
    };

    this.router.navigate(['/calls'], { queryParams: params});
  }
}
