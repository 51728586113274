import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'cxr-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {

  agents = [];
  agentsLoading = true;
  teamId: any;
  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.teamId = this.route.snapshot.paramMap.get('id');

    this.getAgents();
  }

  getAgents() {
    this.agentsLoading = true;
    if (this.teamId) {
      // single team data
      this.dashboardService.getSingleAgents(this.teamId)
      .toPromise()
      .then((data : any) => {
          this.agentsLoading = false;
          this.agents = data.teams;
      })
      .catch((err)=> {
        this.agentsLoading = false;
      });
    }
    else {
      // all team data
      this.dashboardService.getAgents()
      .toPromise()
      .then((data : any) => {
          this.agentsLoading = false;
          this.agents = data.teams;
      })
      .catch((err)=> {
        this.agentsLoading = false;
      });
    }
  }

  openAddModal = () => {
    $('#addAgent').modal('show');
  }
}
