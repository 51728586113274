import { Component, OnInit } from '@angular/core';
declare var $: any;
import { CommonService } from '../../services/common.service';
import { LoadingService } from '../../services/loading.service';

import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DashboardService } from '../../services/dashboard.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserDataService } from '../../services/user-data.service'


@Component({
  selector: 'cxr-qualitynewui',
  templateUrl: './qualitynewui.component.html',
  styleUrls: ['./qualitynewui.component.scss']
})
export class QualitynewuiComponent implements OnInit {

  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period";
  dateDisplay = false;
  calls = [];
  campaigns = [];
  nonCompliant: any = [];

  today = new Date();
  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  dateRange = [];

  isCallLoading = true;

  campaignId;
  cloudText;
  status;
  projectId;
  focusKeyword;

  start_date:any;
  end_date:any;

  selectedFilter;
  total_agents : Set<number> = new Set();

  faildCalls = 0;
  worningCalls = 0;
  totalScore = 0;

  checksFilterModel = '';
  campaignsFilterModel = '';
  projectFilterModel = '';
  topChangeFilterModel = 'Opening';
  callSection: any = [];

  selectedId;     

  toggleDate = () => {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }
  }
  constructor(
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private dashBoardService: DashboardService,
    private userService: UserDataService,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.date != undefined && params.date != '') {
        let splittedDate = params.date.split('/');
        let formattedDate = splittedDate[1] + '/' + splittedDate[0]+ '/' + splittedDate[2];
        let nextDate = new Date(formattedDate);
        nextDate.setDate(nextDate.getDate()+1);
        this.start_date = formattedDate;
        this.end_date = nextDate;

        this.dateRange.push((moment(this.start_date)).format('YYYY-MM-DD'));
        this.dateRange.push(moment(this.end_date).format('YYYY-MM-DD'));
      }
      this.campaignId = params.campaignId;
      this.cloudText = params.word;
      this.status = params.status;
      this.projectId = params.projectId;
      this.focusKeyword = params.focusKeyword;

      // this.selectedId = params['id'];
    });

    this.toggleDate();

    if (this.dateRange.length == 0) {
      this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
      this.dateRange.push(moment().format('YYYY-MM-DD'));

      this.start_date = this.dateRange[0];
      this.end_date = this.dateRange[1];
    }

    this.setDate('today', '24 Hours');
    this.selectedId = this.userService.getQualityData();
  }

  display = false;
  setDate = (dateSelected, dateSelectedDisplay) => {
    this.toggleDate();
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    // this.refreshList(this.commonService.toDaysConvertor(this.selectedPeriod));
    this.refreshList();
    this.getAllProjects();
    this.getAllCampnaigns();
    this.getNonCompliantCall();
    this.getCallSection();

  }

  redirectInternal = (id) => {

    this.router.navigateByUrl('/qcmanager/' + id);
    // this.router.navigate( ['/qcmanager/' + id], { queryParams: { value: true}});
  }

  toggleDisplay = () => {
    if (!this.display) {
      $('.actions').css('display', 'unset');
      this.display = true;
    } else {
      $('.actions').css('display', 'none');
      this.display = false;
    }
  }

  onProjectChange(id){
    // this.campaignsFilterModel = '';
    this.projectId = id == '' ? null : id;
    this.refreshList();
  }

  onCampaignChange(id){
    this.campaignId = id == '' ? null : id;
    // this.projectFilterModel = '';
    this.refreshList();
  }

  onChecksChange(id){
    // this.projectFilterModel = '';
    this.refreshList();
  }

  refreshList() {
    let term = "";
    term = term + (this.campaignId != undefined ? ('&campId=' + this.campaignId) : '');
    term = term + ((this.cloudText != undefined && this.cloudText != '') ? ("&cloudText=" + this.cloudText) : "");
    term = term + (this.status != undefined ? ("&status=" + this.status) : "");
    term = term + (this.projectId != undefined ? ("&projectId=" + this.projectId) : "");
    term = term + (this.focusKeyword != undefined ? ("&focusKeyword=" + this.focusKeyword) : "");
    term = term + (this.selectedFilter != undefined ? ("&filter=" + this.selectedFilter) : "");
    term = term + ((this.checksFilterModel != undefined && this.checksFilterModel != '') ? ("&qualityCheck=" + this.checksFilterModel) : "");

    this.totalScore = 0;
    this.faildCalls = 0;
    this.worningCalls = 0;

    this.isCallLoading = true;
    this.loadingService.apiStart();
    this.commonService.getCallsByQc(term, this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.loadingService.apiStop();
        this.calls = data.calls;

        // filter code 
        // data.calls.forEach((ele) => {
        //   for (let i = 0; i < this.selectedId.length; i++) {
        //     if (this.selectedId[i] == ele.id) {
        //       this.calls.push(ele);
        //     }
        //   }
        // });

        this.calls.forEach(call => {
          if (this.campaigns.indexOf(call.campaign_name) < 0) {
            this.campaigns.push(call.campaign_name);
          }

          // Total agent
        this.total_agents.add(call.agent_id);

        // Total QCS
        this.totalScore = this.totalScore + parseInt(call.quality_score);
        console.log('totalScore',call.quality_score )
        if (call.quality_score < 0.25) {
        
          // failed calls
          this.faildCalls = this.faildCalls + 1;
        }else if (call.quality_score > 0.25 && call.quality_score < 0.37) {
        
          // WARNING calls
          this.worningCalls = this.worningCalls + 1;
        }else if (call.quality_score > 0.37) {
        
          // pass calls
          this.totalScore = this.totalScore + 1;
        }
        });
        this.isCallLoading = false;
      })
      .catch((err) => {
        this.isCallLoading = false;
        this.loadingService.apiStop();
      })
  }

  projects: any = [];
  campaing: any = [];
  isProjectLoading = true;
  campaignsFilter:any;
  projectsFilter:any;

  getAllProjects = () => {
    this.isProjectLoading = true;
    this.dashBoardService.getProjects(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.projects = data.projects;
        // console.log(this.projects)
        this.isProjectLoading = false;
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });

  }

  getAllCampnaigns = () => {
    this.commonService.getCampaigns(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.campaing = data.campaigns;
        // console.log(this.campaing)
      })
      .catch((err) => {
      });

  }
 

  filterCalls = (calls) => {
    let filter = document.getElementsByClassName('filter')[0]['value'];
    if(filter != "") {
      return this.calls.filter(x => x.campaign_name == filter);
    }
    return this.calls.filter(x => x);
  }

  onDateChange(dates) {
    this.start_date = dates[0];
    this.end_date = dates[1];
    this.refreshList();
    this.getAllProjects();
    this.getAllCampnaigns();
  }

  callSectionTopChange(name) {
    this.getNonCompliantCall();
  }

  getCallSection() {
    this.isProjectLoading = true;
    this.dashBoardService.getcallSection()
      .toPromise()
      .then((data: any) => {
        this.callSection = data.data;
        // console.log(this.callSection)
        this.isProjectLoading = false;
      })
      .catch((err) => {
        this.isProjectLoading = false;
      });

  }

  getNonCompliantCall() {
    let term = "";
    term = term + (this.topChangeFilterModel != undefined ? ('&callsection=' + this.topChangeFilterModel) : '');
    
    this.isProjectLoading = true;
    this.dashBoardService.getNonCompliantCall(term, this.start_date ,this.end_date)
    .toPromise()
    .then((data: any) => {
      this.nonCompliant = data;
      // console.log('nonCompliant',this.nonCompliant)
      this.isProjectLoading = false;
    })
    .catch((err) => {
      this.isProjectLoading = false;
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("CXQuality");

  }

  sortColumn: string = '';
  sortOrder: string = 'asc';

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }
    this.calls = this.calls.sort((a, b) => {
      const sortOrder = this.sortOrder === 'asc' ? 1 : -1;
      const aVal = a[column];
      const bVal = b[column];
      if (aVal < bVal) {
        return -1 * sortOrder;
      } else if (aVal > bVal) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }

}
