import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundUp'
})
export class KeysPipe implements PipeTransform {

  //   transform(value, args:string[]) : any {
  //     let keys = [];
  //     for (let key in value) {
  //       keys.push(key);
  //     }
  //     return keys;
  // }
  transform(value: number): number {
    return Math.ceil(value);
  }
}
