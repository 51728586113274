import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
import * as moment from 'moment';
@Component({
  selector: 'cxr-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  cxrOverviewData = {};
  dateDisplay = false;
  selectedPeriod = "Select display period"
  selectedPeriodDisplay = "Select display period"
  overviewData = null;
  teams = [];
  dateRange = [];
  today = new Date();
  manageTeam: any = false;

  currentDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

  isOverviewLoading = true;
  isTeamListLoading = true;

  start_date:any;
  end_date:any;

  campId: any;

  toggleDate = () => {
    if (this.dateDisplay) {
      $('#dateOptions').css('display', 'none');
      this.dateDisplay = false;
    } else {
      $('#dateOptions').css('display', 'block');
      this.dateDisplay = true;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.campId = this.route.snapshot.paramMap.get('id');

    this.dateRange.push((moment('2020-09-01')).format('YYYY-MM-DD'));
    this.dateRange.push(moment().format('YYYY-MM-DD'));

    this.start_date = this.dateRange[0];
    this.end_date = this.dateRange[1];

    this.setDate('today', '24 Hours');
    this.toggleDate();

    this.getOverview();
    this.getTeamOverview();
    this.getTeamList();

    this.route.queryParams.subscribe(
      params => {
        if (params['edit'] == 'manageTeam') {
          this.manageTeam = true;
        }
        else {
          // this.router.navigateByUrl('/project/' + id);
        }
      }
    )
  }

  setDate = (dateSelected, dateSelectedDisplay) => {
    this.selectedPeriod = dateSelected;
    this.selectedPeriodDisplay = dateSelectedDisplay;
    this.toggleDate();
    // this.getOverview();
    // this.getTeamList();
    // this.getOverview(30);
    // this.getTeamList(30);
  }

  getTeamOverview = () => {
    // this.isOverviewLoading = true;

    // this.commonService.getTeamOverview(this.start_date, this.end_date)
    //   .toPromise()
    //   .then((data: any) => {
    //     this.overviewData = data.checks;
    //     this.isOverviewLoading = false;
    //   })
    //   .catch((err) => {
    //     this.isOverviewLoading = false;
    //   });
  }

  openTeam(id) {
    // this.router.navigateByUrl('/team/' + id);
    if (this.manageTeam == true) {
      this.router.navigateByUrl('/admin/project/newteam/' + id);
    }
    else {
    // this.router.navigateByUrl('/team/' + id);
    this.router.navigateByUrl('/agents/' + id);
  }
  }

  getTeamList() {
    this.isTeamListLoading = true;

    if (this.campId) {
      // singele team
      this.commonService.getSingleTeamList(this.campId)
      .toPromise()
      .then((data: any) => {
        this.teams = data.teams;
        this.isTeamListLoading = false;
      })
      .catch((err) => {
        this.isTeamListLoading = false;
      });
    }
    else {
      // all teams
      this.commonService.getTeamList(this.start_date, this.end_date)
      .toPromise()
      .then((data: any) => {
        this.teams = data.teams;
        this.isTeamListLoading = false;
      })
      .catch((err) => {
        this.isTeamListLoading = false;
      });
    }
  }

  getOverview = () => {
    this.commonService.getCallStats(this.start_date, this.end_date).toPromise().then((data: any) => {
      this.cxrOverviewData = data;
    })
  }

  onDateChange(dates){    
    this.start_date = dates[0];
    this.end_date = dates[1];

    this.getOverview();
    this.getTeamOverview();
    this.getTeamList();
  }

}
