<div id="customizableDashboard" class="overlay assign-form-template">
  <div class="overlay-content">
    <a href="javascript:void(0)" class="close-btn" (click)="closeOverlay('customizableDashboard')">
      <div>
        <span class="close-icon">
          &times;<br>
        </span>
      </div>
    </a>
    <div class="overlay-body slim-scrollbar" >
      <div class="row">

        <!-- Heading info start -->
        <div class="col-sm-6">
          <h2>Select widgets</h2>
        </div>
        <div class="col-sm-6 text-right">
          <button (click)="addToDashboardClick()" class="stnd-bg-btn">
            ADD TO DASHBOARD
          </button>
        </div>
        <!-- Heading info end -->

        <!-- Start tabs -->
        <div class="col-sm-12">
          <!-- Nav pills -->
          <ul class="nav nav-pills">
            <!-- <li class="nav-item">
              <a class="nav-link active" data-toggle="pill" href="#regular">Regular</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="pill" href="#standard">Standard</a>
            </li> -->
          </ul>

          <!-- Tab panes -->
          <div class="tab-content">
            <div class="tab-pane container active" id="regular">
              <div class="row">

                <!-- campaign widget stamp -->
                <div *ngFor="let widget of allWidgets; ; let i = index" class="col-sm-4 dropdown dropright widget-for-selection" [id]="'widgetForSelection' + i" >
                  <a class="widget-stamp" href="javascript:void(0)" (click)="onClickWidget(widget, i)" [ngClass]="{'selected' : isWidgetSelected(widget.type)}" >
                    <!-- [ngClass]="{'selected' : clickedWidget == widget.type}" -->
                    <i class="fa fa-check-circle"></i>
                    <div class="widget-icon" >
                      <img [src]="widget.image">
                    </div>
                    <div class="widget-title">
                      {{widget.name}}
                    </div>
                    <div class="widget-desc" >
                      {{widget.desc}}
                    </div>
                  </a>
                  <ul class="dropdown-menu" >
                    <li>
                      <h4 class="title">
                        CONFIGURE WIDGET
                      </h4>
                      <div class="choose-from-lbl" >Choose from the following</div>

                      <!-- Word cloude start-->
                      <div *ngIf="constants.WORD_CLOUDE_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'wordCloudSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" checked >Project
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="wordCloudSelectionRadio" value="campaign" [(ngModel)]="widget.selectedType">Campaign
                          </label>
                        </div>
                        <!-- <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="wordCloudSelectionRadio" value="call" [(ngModel)]="widget.selectedType">Call
                          </label>
                        </div> -->

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'campaign'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.campaigns" [multiple]="true" [items]="allCampaigns" bindValue="id" bindLabel="name" placeholder="Select campaign" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'call'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.calls" [multiple]="true" bindValue="id" bindLabel="id" [items]="allCalls" placeholder="Select call" >
                          </ng-select>
                        </div>

                      </div>
                      <!-- Word cloude end-->


                      <!-- Sentiment score distribution start-->
                      <div *ngIf="constants.SENTIMENT_SCORE_DISTRIBUTION  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'sentimentDistributionSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" checked >Project
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="sentimentDistributionSelectionRadio" value="campaign" [(ngModel)]="widget.selectedType">Campaign
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'campaign'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.campaigns" [multiple]="true" [items]="allCampaigns" bindValue="id" bindLabel="name" placeholder="Select campaign" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'call'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.calls" [multiple]="true" bindValue="id" bindLabel="id" [items]="allCalls" placeholder="Select call" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Sentiment score distribution end-->


                      <!-- Sentiment score start-->
                      <div *ngIf="constants.SENTIMENT_SCORE_CALLS  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'sentimentSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" checked >Project
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="sentimentSelectionRadio" value="campaign" [(ngModel)]="widget.selectedType">Campaign
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'campaign'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.campaigns" [multiple]="true" [items]="allCampaigns" bindValue="id" bindLabel="name" placeholder="Select campaign" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'call'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.calls" [multiple]="true" bindValue="id" bindLabel="id" [items]="allCalls" placeholder="Select call" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Sentiment score end-->

                      <!-- Project start -->
                      <div *ngIf="constants.PROJECT_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'projectSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" >Project
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Project end -->

                      <!-- Project overview start -->
                      <div *ngIf="constants.PROJECT_OVERVIEW_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'projectOverviewSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" >Project
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Project overview end -->

                      <!-- Campaign overview card start -->
                      <div *ngIf="constants.CAMPAIGN_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'campaignSelectionRadio'" value="campaign" [(ngModel)]="widget.selectedType" >Campaign
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'campaign'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.campaigns" [multiple]="true" [items]="allCampaigns" bindValue="id" bindLabel="name" placeholder="Select campaign" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Campaign overview card end -->

                      <!-- Quality score widget start-->
                      <div *ngIf="constants.PERFORMANCE_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'qualityScoreSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" >Project
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="qualityScoreSelectionRadio" value="campaign" [(ngModel)]="widget.selectedType" >Campaign
                          </label>
                        </div>
                        <!-- <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="qualityScoreSelectionRadio" value="call" [(ngModel)]="widget.selectedType" >Call
                          </label>
                        </div> -->

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'campaign'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.campaigns" [multiple]="true" [items]="allCampaigns" bindValue="id" bindLabel="name" placeholder="Select campaign" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'call'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.calls" [multiple]="true" bindValue="id" bindLabel="id" [items]="allCalls" placeholder="Select call" >
                          </ng-select>
                        </div>

                      </div>
                      <!-- Quality score widget end-->

                      <!-- Team card start -->
                      <div *ngIf="constants.TEAM_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'teamSelectionRadio'" value="team" [(ngModel)]="widget.selectedType" >Team
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'team'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.teams" [items]="allTeams" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select team" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Team card end -->

                      <!-- Agent card start -->
                      <div *ngIf="constants.AGENT_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'teamSelectionRadio'" value="agent" [(ngModel)]="widget.selectedType" >Agent
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'agent'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.agents" [items]="allAgents" [multiple]="true" bindLabel="agentname" bindValue="id" placeholder="Select agent" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Agent card end -->

                      <!-- Team overview start -->
                      <div *ngIf="constants.TEAM_OVERVIEW_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'teamOverviewSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" >Team
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Team overview end -->

                      <!-- Agent overview start -->
                      <div *ngIf="constants.TEAM_OVERVIEW_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'agentSelectionRadio'" value="project" [(ngModel)]="widget.selectedType" >Agent
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Agent overview end -->

                      <!-- Voice performance start -->
                      <div *ngIf="constants.VOICE_WIDGET  == widget.type" >
                        <!-- <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'callPerformanceSelectionRadio'" value="call" [(ngModel)]="widget.selectedType" >Call
                          </label>
                        </div> -->

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'project'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.projects" [items]="allProjects" [multiple]="true" bindLabel="name" bindValue="id" placeholder="Select project" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'campaign'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.campaigns" [multiple]="true" [items]="allCampaigns" bindValue="id" bindLabel="name" placeholder="Select campaign" >
                          </ng-select>
                        </div>

                        <div *ngIf="widget.selectedType == 'call'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.calls" [multiple]="true" bindValue="id" bindLabel="id" [items]="allCalls" placeholder="Select call" >
                          </ng-select>
                        </div>

                      </div>
                      <!-- Voice performance end -->

                      <!-- Line up of calls start -->
                      <div *ngIf="constants.LINE_UP_OF_CALL_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'agentLineUpSelectionRadio'" value="agent" [(ngModel)]="widget.selectedType" >Agent
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'agent'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.agents" [items]="allAgents" [multiple]="true" bindLabel="agentname" bindValue="id" placeholder="Select agent" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Line up of calls end -->

                      <!-- Activity tracker start -->
                      <div *ngIf="constants.ACTIVITY_TRACKER_WIDGET  == widget.type" >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [name]="'agentLineUpSelectionRadio'" value="agent" [(ngModel)]="widget.selectedType" >Agent
                          </label>
                        </div>

                        <div class="choose-from-lbl" >Choose from the following</div>

                        <div *ngIf="widget.selectedType == 'agent'" style="margin-top: 10px;" >
                          <ng-select [(ngModel)]="widget.agents" [items]="allAgents" [multiple]="true" bindLabel="agentname" bindValue="id" placeholder="Select agent" >
                          </ng-select>
                        </div>
                      </div>
                      <!-- Activity tracker start end -->


                      <div class="row button-section">
                        <div class="col-12">
                          <button (click)="closeSelectWidgetPopup(widget, i)" class="cancel-btn" >Cancel</button>
                          <button (click)="onClickedSaveWidgetPopup(widget, i)" class="save-btn">Save</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- campaign overview stamp -->

              </div>
            </div>
            <div class="tab-pane container fade" id="standard">
              Standard
            </div>
          </div>
        </div>
        <!-- End tabs -->
      </div>
    </div>
  </div>
</div>

<!-- The Modal -->
<div class="modal select-widget-modal" id="selectWidgetModal" data-backdrop="static" data-keyboard="false" >
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
          <span *ngIf="clickedWidget == constants.PROJECT_WIDGET" >Select Project</span>
        </h4>
        <button type="button" class="close" (click)="closeSelectWidgetPopup(1, 1)" >&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">

        <!-- Project list start -->
        <div *ngIf="clickedWidget == constants.PROJECT_WIDGET">
          <mat-form-field class="example-chip-list">
            <mat-chip-list #chipList aria-label="Fruit selection">
              <mat-chip *ngFor="let project of projects" [selectable]="selectable"
                       [removable]="removable" (removed)="remove(fruit)">
                {{project}}
                <mat-icon matChipRemove *ngIf="removable">X</mat-icon>
              </mat-chip>
              <input placeholder="Choose project..."
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
          </mat-form-field>
        </div>
        <!-- Project list start -->
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closeSelectWidgetPopup(1, 1)">Close</button>
      </div>

    </div>
  </div>
</div>