import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { EventService } from './../../services/event.service';
import { LoadingService } from './../../services/loading.service';
import { ToastrService } from 'ngx-toastr';

interface Data {
  campaignname: string,
  name: string,
  agents: number,
  calls_taken : number,
  nps : number,
  csat : number,
  ces : number,
  good_calls : number,
  average_calls : number,
  bad_calls: number,
  oppass :number,
  optotal :number,
  bdpass :number,
  bdtotal :number,
  clopass :number,
  clototal :number,
  projectname: string,
  id:number
  project: {
    
  },
  projectid:number
}

const manageProject = false;


@Component({
  selector: 'cxr-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  @Output("getAllProjects") getAllProjects: EventEmitter<any> = new EventEmitter();
  @Output("getCampaigns") getCampaigns: EventEmitter<any> = new EventEmitter();

  @Input()
  data: Data;
  @Input()
  name: string;
  manageProject: boolean=false;
  manageCampaing: boolean = false
  path: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    public event: EventService,
    private loadingService: LoadingService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
    // console.log(this.route.snapshot.url[0].path)
      this.path=this.route.snapshot.url[0].path
        if (params['edit'] == 'manageProject') {
          this.manageProject = true;
        }
        else if (params['edit'] == 'manageCampaing') {
          this.manageCampaing = true;
        }
      }
    )
  }
  // delete project function
  deleteData: any = {};
  deleteProject(id, projectid) {
    if (this.path == 'projectslist') {
      this.deleteData['projectid'] = id;
      this.deleteSingleProject(this.deleteData.projectid);
    }
    else {
      this.deleteData['campid'] = id;
      this.deleteSingleCamp(this.deleteData.campid);
      // this.deleteData['projectid'] = projectid;

    }
    
  }

  deleteSingleProject(projectid) {
    this.loadingService.apiStart();
    this.commonService.deleteSingleProject(projectid)
    .toPromise()
    .then((data: any) => {
      this.getAllProjects.emit();
      this.toastr.success("Project Delete successfully.");
      this.loadingService.apiStop();
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }

  deleteSingleCamp(campid) {
    this.loadingService.apiStart();
    this.commonService.deleteSingleCamp(campid)
    .toPromise()
    .then((data: any) => {
      this.getCampaigns.emit();
      this.toastr.success("Campaign Delete successfully.");
      this.loadingService.apiStop();
    })
    .catch((err) => {
      this.loadingService.apiStop();
    });
  }
  
  openCampaign(id) {
    if (this.manageProject == true && this.path == 'projectslist') {
      this.router.navigateByUrl('/admin/project/details/' + id);
    }
    else {
      if(this.path == 'projects') {
        this.router.navigateByUrl('/project/' + id);
      }
      else {
        if(this.manageCampaing == true && this.path == 'campaignslist') {
          this.router.navigateByUrl('/admin/project/createcampaign/' + id);
        }
        else if(this.path == 'campaigns') {
          this.router.navigateByUrl('/teams/' + id);
        }
      } 
    }
  }
}
